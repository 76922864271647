import WasmController from "../../../../frameworks/WasmController";
import moment from "moment";
// APIs
import AnesthesiaDataList from "issara-sdk/apis/AnesthesiaDataList_apps_ANS";
import ANSFormPrint from "issara-sdk/apis/ANSFormPrint_apps_ANS";
import FormDataActionLogList from "issara-sdk/apis/FormDataActionLogList_apps_PTM";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import UserTokenizeLicenseView from "issara-sdk/apis/UserTokenizeLicenseView_users";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import TeamDetail from "issara-sdk/apis/TeamDetail_apps_ORM";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import { formatDate } from "react-lib/utils";
import UserEmployeeDetailAPIView from "issara-sdk/apis/UserEmployeeDetailAPIView_users";
import UserList from "issara-sdk/apis/UserList_users";
import PreAnestheticRecordByPatientDetail from "issara-sdk/apis/PreAnestheticRecordByPatientDetail_apps_ANS";
import { getListWithKey, removeListWithKey } from "../../ORM/sequence/ORRequest";
import VitalSignTypeList from "issara-sdk/apis/VitalSignTypeList_apps_PTM";

// Utils
import { formatUTCtoBEString } from "react-lib/utils/dateUtils";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  searchedItemListWithKey?: any;
  masterOptions?: any;
  // selectedEncounter?: any;

  // ORQueueI
  selectedOrOrder?: any;

  // sequence
  PACURecordSequence?: {
    sequenceIndex?: string | null;

    id?: number | null;
    data?: {
      // CardPACURecordFormUX
      DateRamrk?: string;
      ORRmark?: string;
      IsOPD?: boolean;
      IsWard?: boolean;
      IsWardRemark?: string;
      StartOpRemark?: string;
      EndAnesRemark?: string;
      PACUDurartionTime?: string;
      AnesthesiaTeamRemark?: string; // CardPACURecordAnesthesiaUX
      DiagnosisRemark?: string;
      AnesthesiaNurse?: string;
      OperationRemark?: string;
      // CardPACURecordAnesthesiaUX
      IsGA?: boolean;
      IsGARemark?: string;
      IsMAC?: boolean;
      IsRegionalAnesthesia?: boolean;
      IsRegionalAnesthesiaRemark?: string;
      AdmissionStatus?: string;
      Airway?: string;
      AirwayOther?: string;
      ASAPS?: string;
      Emergency?: boolean;
      AdverseEventsRemark?: string;
      Breathing?: string;
      IMF?: string;
      // CardPACURecordIntraoperativeUX
      Crystalloid?: string;
      BloodLoss?: string;
      Colloid?: string;
      Urine?: string;
      Blood?: string;
      Drain?: string;
      // CardPACURecordMonitorUX
      IsNIBP?: boolean;
      IsSpO2?: boolean;
      IsEKG?: boolean;
      IsUrine?: boolean;
      ObserveRRAndVS?: boolean;
      FallPrevention?: boolean;
      PainAssessment?: boolean;
      RespiratoryCare?: boolean;
      KeepWarm?: boolean;
      IsPositionManagement?: boolean;
      TympanicTemperature?: boolean;
      TympanicTemperatureRemark?: string;
      NursingCareOther?: boolean;
      NursingCareOtherRemark?: string;
      AttendingStaff?: string;
      AttendingStaffLicenseNumber?: string;
      AttendingStaffCode?: string;
      PACUNurse?: string;
      PACUNurseLicenseNumber?: string;
      PACUNurseCode?: string;
      // CardPACURecordMedicationUX
      nurseMedicationData?: any[];
      // CardPACURecordProblemsUX
      ansProblemData?: any[];
      // CardPACURecordReferralUX
      NotPACU?: boolean;
      BPRemark?: string;
      PulseRemark?: string;
      SpO2Remark?: string;
      TempRemark?: string;
      O2Transfer?: string;
      O2TransferRemark?: string;
      PatientControlledAnalgesia?: string;
      PatientControlledAnalgesiaRemark?: string;
      SpinalEpiduralMorphine?: string;
      SpinalEpiduralMorphineRemark?: string;
      ReceiverRemark?: string;
      SenderRemark?: string;
      LicenseNumberRemark?: string;
      IsTransferToWard?: boolean;
      TransferToWardRemark?: string;
      IsTransferToICU?: boolean;
      TransferToICURemark?: string;
      IsTrsnsferToDischarge?: boolean;
      TrsnsferToDischargeRemark?: string;
      IsLow?: boolean;
      IsHigh?: boolean;
      // CardPACURecordFluidUX
      ansFluidData?: any[];
      // PACUVitalSign
      ansVitalSignType?: any[];
      ansVitalSignData?: any[];
    };
    // PACUVitalSign
    anesPACUVitalSign?: {
      id?: any;
      items?: any[];
      type?: any[];
    };
    // CardPACURecordAnesthesiaUX
    anesPACUAnesthesia?: {
      typeAnesthesia?: any;
      AnesthesiaTeamRemark?: any;
      AnestheticAgentsRemark?: any;
      AdmissionStatus?: any;
      Airway?: any;
      AirwayOther?: any;
      ASAPS?: any;
      Emergency?: any;
      AdverseEventsRemark?: any;
      id?: any;
      SedationScore?: any;
      Breathing?: any;
      IMF?: any;
    };
    // CardPostAnestheticRecoveryScore
    anesPACUScore?: {
      time?: any[];
      activity?: any[];
      respiration?: any[];
      circulation?: any[];
      consciousness?: any[];
      spO2Colour?: any[];
      PACUScore?: any[];
      buttonPACUScore?: any[];
      id?: number;
    };
    anesPACUScoreChanged?: Record<string, any>;
    anesDischargeScoreChanged?: Record<string, any>;
    anesDischargeScore?: {
      vitalSign?: any[];
      ambulation?: any[];
      nauseaVomiting?: any[];
      pain?: any[];
      bleeding?: any[];
      dischargeScore?: any[];
      buttonDischargeScore?: any[];
      totalDischargeScore?: any;
      id?: number;
    };
    anesTransfer?: {
      transfer?: boolean;
      transferType?: any;
      wardSelect?: any;
      checkAppointment?: any;
      appointmentData?: any;
      checkHome?: any;
      homeData?: any;
      instructionsData?: any;
      id?: number;
      edit_user_name?: any;
      edited?: any;
    };
    userNurseOptions?: any;
    ansStartDatetime?: string;
    ansTableStart?: string;
    ansEndDatetime?: string;
    ansTableEnd?: string;
    modLogInfo?: { open: boolean; logList: any[] };
  } | null;
};

type PostAnestheticKey = "discharge" | "pacu" | "transfer";

export const StateInitial: State = {
  PACURecordSequence: {
    sequenceIndex: null,

    id: null,
    data: {
      // CardPACURecordFormUX
      DateRamrk: "",
      ORRmark: "",
      IsOPD: false,
      IsWard: false,
      IsWardRemark: "",
      StartOpRemark: "",
      EndAnesRemark: "",
      PACUDurartionTime: "",
      AnesthesiaTeamRemark: "", // CardPACURecordAnesthesiaUX
      DiagnosisRemark: "",
      AnesthesiaNurse: "",
      OperationRemark: "",
      // CardPACURecordAnesthesiaUX
      IsGA: false,
      IsGARemark: "",
      IsMAC: false,
      IsRegionalAnesthesia: false,
      IsRegionalAnesthesiaRemark: "",
      AdmissionStatus: "",
      Airway: "",
      AirwayOther: "",
      ASAPS: "",
      Emergency: false,
      AdverseEventsRemark: "",
      Breathing: "",
      IMF: "",
      // CardPACURecordIntraoperativeUX
      Crystalloid: "",
      BloodLoss: "",
      Colloid: "",
      Urine: "",
      Blood: "",
      Drain: "",
      // CardPACURecordMonitorUX
      IsNIBP: false,
      IsSpO2: false,
      IsEKG: false,
      IsUrine: false,
      ObserveRRAndVS: false,
      FallPrevention: false,
      PainAssessment: false,
      RespiratoryCare: false,
      KeepWarm: false,
      IsPositionManagement: false,
      TympanicTemperature: false,
      TympanicTemperatureRemark: "",
      NursingCareOther: false,
      NursingCareOtherRemark: "",
      AttendingStaff: "",
      AttendingStaffLicenseNumber: "",
      AttendingStaffCode: "",
      PACUNurse: "",
      PACUNurseLicenseNumber: "",
      PACUNurseCode: "",
      // CardPACURecordMedicationUX
      nurseMedicationData: [],
      // CardPACURecordProblemsUX
      ansProblemData: [],
      // CardPACURecordReferralUX
      NotPACU: false,
      BPRemark: "",
      PulseRemark: "",
      SpO2Remark: "",
      TempRemark: "",
      O2Transfer: "",
      O2TransferRemark: "",
      PatientControlledAnalgesia: "",
      PatientControlledAnalgesiaRemark: "",
      SpinalEpiduralMorphine: "",
      SpinalEpiduralMorphineRemark: "",
      ReceiverRemark: "",
      SenderRemark: "",
      LicenseNumberRemark: "",
      IsTransferToWard: false,
      TransferToWardRemark: "",
      IsTransferToICU: false,
      TransferToICURemark: "",
      IsTrsnsferToDischarge: false,
      TrsnsferToDischargeRemark: "",
      IsLow: false,
      IsHigh: false,
      ansFluidData: [],
      ansVitalSignType: [],
      ansVitalSignData: [],
    },
    // CardPostAnestheticRecoveryScore
    anesPACUScore: {
      time: Array(7).fill(""),
      activity: Array(7).fill(null),
      respiration: Array(7).fill(null),
      circulation: Array(7).fill(null),
      consciousness: Array(7).fill(null),
      spO2Colour: Array(7).fill(null),
      PACUScore: Array(7).fill(null),
      buttonPACUScore: Array(7).fill(null),
    },
    anesDischargeScore: {
      vitalSign: Array(7).fill(null),
      ambulation: Array(7).fill(null),
      nauseaVomiting: Array(7).fill(null),
      pain: Array(7).fill(null),
      bleeding: Array(7).fill(null),
      dischargeScore: Array(7).fill(null),
      buttonDischargeScore: Array(7).fill(null),
      totalDischargeScore: null,
    },
    anesTransfer: {
      transfer: false,
      transferType: "",
      wardSelect: "",
      checkAppointment: false,
      appointmentData: "",
      checkHome: false,
      homeData: "",
    },
    userNurseOptions: [],
    ansStartDatetime: "",
    ansTableStart: "",
    ansEndDatetime: "",
    ansTableEnd: "",
    modLogInfo: { open: false, logList: [] },
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} }
  | { message: "GetAnesVitalSign"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

export const SEARCH_KEYS = {
  USER_ANESTHETIST: "User_Anesthetist",
  DOCTOR_ANESTHESIOLOGIST: "Doctor_Anesthesiologist",
};

const SEARCH_INDEXES = {
  [SEARCH_KEYS.USER_ANESTHETIST]: "CI",
  [SEARCH_KEYS.DOCTOR_ANESTHESIOLOGIST]: "I",
} as const;

const POST_ANES_DEFAULT_LENGTH = 7;

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const GetMaster: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["drugName", {}],
        ["doctor", {}],
        ["divisionWard", {}],
        // CardPACURecordAnesthesiaUX
        ["pacu_airway", {}],
        ["anes_asa_ps", {}],
        ["sedation_score", {}],
        ["pacu_breathing", {}],
        ["pacu_imf", {}],
        // CardPACURecordReferralUX
        ["pacu_o2transfer", {}],
        ["pacu_ptctrlanal", {}],
        ["pacu_spiepimorph", {}],
        ["pacu_adm_status", {}],
      ],
    },
  });

  const [userNurseRes, userNurseErr, userNurseNet] = await UserList.list({
    apiToken: controller.apiToken,
    params: {
      role: "REGISTERED_NURSE",
    },
  });

  let userNurseOptions = userNurseRes?.items?.map((item: any) => {
    return {
      key: item?.id,
      value: item?.id,
      text: item?.full_name_code,
    };
  });

  controller.setState(
    {
      PACURecordSequence: {
        ...StateInitial.PACURecordSequence,
        sequenceIndex: "Action",
        userNurseOptions: userNurseOptions,
      },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH_PACU") {
    let state = controller.getState();

    const [
      [formLatestResp, formLatestErr, formLatestNet],
      [formAnesRecordResp, formAnesRecordErr, formAnesRecordNet],
      [formORRequestResp, formORRequestErr, formORRequestNet],
      [formAnesthesiaResp, formAnesthesiaErr, formAnesthesiaNet],
      [formAnesVitalSignResp, formAnesVitalSignErr, formAnesVitalSignNet],
    ] = await Promise.all([
      FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: params.formCode,
          form_version: params.formVersion,
        },
        extra: { division: controller.data.division },
      }),
      PreAnestheticRecordByPatientDetail.retrieve({
        apiToken: controller.apiToken,
        params: {
          patient_id: state.selectedOrOrder?.patient_id,
          form_code: "CardPreanestheticAnesthesiaRecord",
          form_version: params.formVersion,
        },
      }),
      FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardAnestheticAssessmentRecord",
          form_version: params.formVersion,
        },
      }),
      FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardPACURecordAnesthesia",
          form_version: "1.0",
        },
        extra: { division: controller.data.division },
      }),
      FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: state.selectedOrOrder?.encounter,
          form_code: "CardPACUVitalSign",
          form_version: "1.0",
        },
        extra: { division: controller.data.division },
      })
    ]);

    const [vitalSignTypeResp, vitalSignTypeErr] = await VitalSignTypeList.list({
      params: { encounter: state.selectedOrOrder?.encounter },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division }
    });

    const [operatingResp, operatingErr, operatingNet] = await OperatingOrderDetail.retrieve({
      apiToken: controller.apiToken,
      pk: state.selectedOrOrder.id,
    });

    const [
      [anesthesiaResp, anesthesiaErr, anesthesiaNet],
      [surgicalTeamResp, surgicalTeamErr, surgicalTeamNet],
      [teamDetailResp, teamDetailErr, teamDetailNet],
    ] = await Promise.all([
      DoctorDetail.retrieve({
        apiToken: controller.apiToken,
        pk: operatingResp?.anesthesia_teams[0]?.anesthesiologist,
      }),
      DoctorDetail.retrieve({
        apiToken: controller.apiToken,
        pk: operatingResp?.teams[0]?.chief_surgeon,
      }),
      TeamDetail.retrieve({
        apiToken: controller.apiToken,
        pk: operatingResp?.teams[0]?.id,
      }),
    ]);

    // set AnesthesiaNurse
    let anesthesiaNurse = "";

    const [operatingOrder] = await OperatingOrderDetail.retrieve({
      apiToken: controller.apiToken,
      pk: state.selectedOrOrder.id,
    });

    if (operatingOrder || formAnesRecordResp) {
      const mainIndex = operatingOrder?.teams?.findIndex((team: any) => team.is_main);

      let listWithKey = state.searchedItemListWithKey || {};

      removeListWithKey(listWithKey, SEARCH_INDEXES);

      // select anesthetists ORrequest or PreAnes
      operatingOrder.anesthesia_teams[mainIndex] = {
        anesthetists:
          formAnesRecordResp?.data?.AnesthesiaNurse?.length !== 0
            ? formAnesRecordResp?.data?.AnesthesiaNurse
            : (operatingOrder?.anesthesia_teams?.[mainIndex]?.anesthetists || null)?.filter(
                Boolean
              ),
      };

      const key = await getListWithKey(controller as any, {
        order: operatingOrder,
        listWithKey,
        keys: ["anesthetists"],
      });

      listWithKey = { ...listWithKey, ...key };

      let countUserAnesthetist = 1;

      Object.entries(listWithKey).map((item: any, index: number) => {
        if (item?.[0] === `User_Anesthetist_1_${countUserAnesthetist}`) {
          anesthesiaNurse =
            countUserAnesthetist === 1
              ? item?.[1]?.[0]?.full_name_code
              : `${anesthesiaNurse}, ${item?.[1]?.[0]?.full_name_code}`;
          countUserAnesthetist++;
        }
      });
    }

    if (formLatestErr || !formLatestResp?.data) {
      let state = controller.getState();
      let seq: any = {
        ...state.PACURecordSequence,
        sequenceIndex: state.PACURecordSequence?.sequenceIndex,
      };

      let toDate = moment();
      // let AnesthesiaNurse = "";
      let typeAnesthesia = handleSetTypeAnesthesia(formORRequestResp?.data);

      // (formAnesRecordResp?.data?.AnesthesiaNurse || []).map((item: any, index: number) => {
      //   if (index === 0) {
      //     AnesthesiaNurse = `${item?.full_name_code}`;
      //   } else {
      //     AnesthesiaNurse = `${AnesthesiaNurse}, ${item?.full_name_code}`;
      //   }
      // });

      const aneOrderId = state.selectedOrOrder?.other_orders?.find(
        (item: any) => item.model === "anesthesiaorder"
      )?.id;

      if (aneOrderId) {
        const [aneDataResp, aneDataErr, aneDataNet] = await AnesthesiaDataList.list({
          apiToken: controller.apiToken,
          params: {
            encounter: state.selectedOrOrder?.encounter,
            ans_order_id: aneOrderId,
          },
        });

        if (aneDataResp) {
          seq.data.DiagnosisRemark = aneDataResp?.post_diagnosis;
          seq.data.OperationRemark = aneDataResp?.post_operation;
        }

        seq.data.ORRmark = operatingResp?.operating_room_no;
        seq.data.AnesthesiaTeamRemark = formORRequestResp?.data?.AnesthesiaTeam || anesthesiaResp?.name_code; //* แสดง AnesTeam จาก Anes Record ก่อน (issue 72118)
        seq.data.AnesthesiaNurse = anesthesiaNurse;
        seq.data.DateRamrk = operatingResp?.start_date;
        seq.data.PatientLocation = formORRequestResp?.data?.PatientLocation;
        seq.data.IsWardRemark = formORRequestResp?.data?.IsWardRemark;
        seq.data.StartDate = formatDate(toDate);
        seq.data.EndDate = formatDate(toDate);
      }

      state = controller.getState();

      controller.setState({
        PACURecordSequence: {
          ...seq,
          ...state.PACURecordSequence,
          anesPACUAnesthesia: {
            ...formAnesthesiaResp?.data,
            typeAnesthesia: typeAnesthesia,
            editUser: formAnesthesiaResp?.edit_user_name,
            editDate: formAnesthesiaResp?.edited,
            id: formAnesthesiaResp?.id,
          },
          anesPACUVitalSign: {
            id: formAnesVitalSignResp ? formAnesVitalSignResp?.pk : null,
            items: formAnesVitalSignResp ? formAnesVitalSignResp?.data?.items : [],
            type: vitalSignTypeResp?.items,
            encounterId: state.selectedEncounter?.id,
          },
        },
      });
    } else {
      let seq: any = { sequenceIndex: state.PACURecordSequence?.sequenceIndex };
      seq.id = formLatestResp.id;
      seq.data = formLatestResp.data;
      seq.status = formLatestResp.status;

      let start: string = formLatestResp.data?.DateRamrk;
      start += formLatestResp.data?.StartOpRemark
        ? " " + formLatestResp.data?.StartOpRemark
        : " 00:00";
      const startMM: any = moment(start, "DD/MM/YYYY HH:mm");
      if (startMM.format("DD/MM/YYYY HH:mm") !== "Invalid date") {
        seq.ansStartDatetime = startMM.format("DD/MM/YYYY HH:mm");
        seq.ansTableStart = startMM.format("DD/MM/YYYY HH:mm");
      }

      let end: string = formLatestResp.data?.DateRamrk;
      end += formLatestResp.data?.EndAnesRemark
        ? " " + formLatestResp.data?.EndAnesRemark
        : " 00:00";
      const endMM: any = moment(end, "DD/MM/YYYY HH:mm");
      if (endMM.format("DD/MM/YYYY HH:mm") !== "Invalid date") {
        seq.ansEndDatetime = endMM.format("DD/MM/YYYY HH:mm");
        if (endMM - startMM > 14400000) {
          // 14400000 ms is 4 hours
          seq.ansTableEnd = startMM.add(3, "hours").format("DD/MM/YYYY HH:mm");
          seq.ansTableEnd = startMM.add(59, "minutes").format("DD/MM/YYYY HH:mm");
          seq.ansTableEnd = startMM.add(59, "seconds").format("DD/MM/YYYY HH:mm");
        } else {
          seq.ansTableEnd = endMM.format("DD/MM/YYYY HH:mm");
        }
      }
      seq.data.AnesthesiaNurse = anesthesiaNurse;

      state = controller.getState();

      controller.setState({
        PACURecordSequence: {
          ...state.PACURecordSequence,
          ...seq,
          anesPACUAnesthesia: {
            ...formAnesthesiaResp?.data,
            editUser: formAnesthesiaResp?.edit_user_name,
            editDate: formAnesthesiaResp?.edited,
            id: formAnesthesiaResp?.id,
          },
          anesPACUVitalSign: {
            id: formAnesVitalSignResp ? formAnesVitalSignResp?.pk : null,
            items: formAnesVitalSignResp ? formAnesVitalSignResp?.data?.items : [],
            type: vitalSignTypeResp?.items,
            encounterId: state.selectedEncounter?.id,
          }
        },
      });
    }
  } else if (params?.action === "SAVE" || params?.action === "CONFIRM") {

    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let saveResp = null;
    let saveErr = null;
    let saveNet = null;

    const data = {
      action: params.action, // SAVE
      data: state.PACURecordSequence?.data,
      encounter: state.selectedOrOrder?.encounter,
      form_code: params.formCode,
      form_name: params.formName,
      form_version: params.formVersion,
    };
    if (state.PACURecordSequence?.id) {
      [saveResp, saveErr, saveNet] = await FormDataDetail.update({
        apiToken: controller.apiToken,
        pk: state.PACURecordSequence?.id,
        data: data,
      });
    } else {
      [saveResp, saveErr, saveNet] = await FormDataList.create({
        apiToken: controller.apiToken,
        data: data,
      });
    }
    if (saveErr) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: saveErr },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params.cardKey]: `${params?.action} Success`,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
      });
      Action(controller, { ...params, action: "FETCH_PACU" });
    }
  } else if (params?.action === "PRINT") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.PACURecordSequence?.id) {
      // Todo
      // const pngData = await ansTableRef.current.getImageData();
      const [printResp, printErr, printNet] = await ANSFormPrint.post({
        apiToken: controller.apiToken,
        data: {
          form_id: state.PACURecordSequence?.id,
          pdf: true,
          // image: pngData,  // Todo
        },
      });
      if (printErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: printErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "บันทึก Form ก่อนทำรายการ",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "LOG") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    if (state.PACURecordSequence?.id) {
      const [logResp, logErr, logNet] = await FormDataActionLogList.list({
        apiToken: controller.apiToken,
        pk: state.PACURecordSequence?.id,
      });
      if (logErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: logErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
          PACURecordSequence: {
            ...state.PACURecordSequence,
            modLogInfo: { open: true, logList: logResp?.items || [] },
          },
        });
      }
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: "ไม่มี Form id",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params?.action === "GET_USER_TOKENIZE_LICENSE") {
    const state = controller.getState();

    const [userResp, userErr, userNet] = await UserTokenizeLicenseView.post({
      apiToken: controller.apiToken,
      data: {
        code: state.PACURecordSequence?.data?.[`${params.paramKey}Code`],
      },
    });

    let seq: any = { ...state.PACURecordSequence };

    if (userErr) {
      seq.data[params.paramKey] = "";
      seq.data[`${params.paramKey}LicenseNumber`] = "";
      seq.data[`${params.paramKey}Code`] = "";

      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: userErr },
      });
    } else {
      const encodedName = userResp.token.split(".")[1];
      seq.data[params.paramKey] = decodeURIComponent(atob(encodedName));
      seq.data[`${params.paramKey}LicenseNumber`] = userResp.license;
    }
    controller.setState({
      PACURecordSequence: seq,
      ...state.PACURecordSequence,
    });
  } else if (params?.action === "GET_LICENSE") {
    const state = controller.getState();

    // Get Employee Code
    const [employeeResp, employeeErr, employeeNet] = await UserEmployeeDetailAPIView.retrieve({
      pk: params.paramKey,
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    // Get License
    if (employeeResp?.code) {
      const [userResp, userErr, userNet] = await UserTokenizeLicenseView.post({
        apiToken: controller.apiToken,
        data: {
          code: employeeResp?.code,
        },
        extra: {
          division: controller.data.division,
        },
      });

      let data: any = { ...state.PACURecordSequence?.data };

      if (userErr) {
        data[`${params.dataKey}LicenseNumber`] = "";
        data[`${params.dataKey}Code`] = "";

        controller.setState({
          errorMessage: { ...state.errorMessage, [params.cardKey]: userErr },
          PACURecordSequence: {
            ...state.PACURecordSequence,
            data: data,
          },
        });
      } else {
        data[`${params.dataKey}LicenseNumber`] = userResp.license;
        data[`${params.dataKey}Code`] = employeeResp.code;

        controller.setState({
          errorMessage: { ...state.errorMessage, [params.cardKey]: userErr },
          PACURecordSequence: {
            ...state.PACURecordSequence,
            data: data,
          },
        });
      }
    }
  } else if (params?.action === "SaveAnesthesia") {
    const state = controller.getState();

    const data = {
      data: state.PACURecordSequence?.anesPACUAnesthesia,
      encounter: state.selectedOrOrder?.encounter,
      action: "SAVE",
      form_code: params.formCode,
      form_name: params.formName,
      form_version: params.formVersion,
    };

    if (state.PACURecordSequence?.anesPACUAnesthesia?.id) {
      const [formAnesthesiaResp, formAnesthesiaErr, formAnesthesiaNet] =
        await FormDataDetail.update({
          apiToken: controller.apiToken,
          pk: state.PACURecordSequence?.anesPACUAnesthesia?.id,
          data: data,
        });

      if (formAnesthesiaResp) {
        controller.setState({
          PACURecordSequence: {
            ...state.PACURecordSequence,
            anesPACUAnesthesia: {
              ...state.PACURecordSequence?.anesPACUAnesthesia,
              ...formAnesthesiaResp.data,
              editUser: formAnesthesiaResp?.edit_user_name,
              editDate: formAnesthesiaResp?.edited,
              id: formAnesthesiaResp?.id,
            },
          },
        });
      }
    } else {
      const [formAnesthesiaResp, formAnesthesiaErr, formAnesthesiaNet] = await FormDataList.create({
        apiToken: controller.apiToken,
        data: data,
      });

      if (formAnesthesiaResp) {
        controller.setState({
          PACURecordSequence: {
            ...state.PACURecordSequence,
            anesPACUAnesthesia: {
              ...state.PACURecordSequence?.anesPACUAnesthesia,
              ...formAnesthesiaResp.data,
              editUser: formAnesthesiaResp?.edit_user_name,
              editDate: formAnesthesiaResp?.edited,
              id: formAnesthesiaResp?.id,
            },
          },
        });
      }
    }
  } else if (params?.action === "getPostAnesthetic") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "LOADING" },
    });

    const formatPACUScore = (detail: any) => {
      if (!detail) {
        return {};
      }

      const defaultArray = <T>(length: number, value: T): T[] =>
        Array.from({ length }, () => value);

      const fields = [
        "time",
        "activity",
        "respiration",
        "circulation",
        "consciousness",
        "spO2Colour",
        "PACUScore",
        "buttonPACUScore",
      ] as const;

      const result: any = {};

      for (const field of fields) {
        result[field] = detail?.data?.[field] ?? defaultArray(POST_ANES_DEFAULT_LENGTH, null);
      }

      result.id = detail?.id;

      return result;
    };

    const formatDischargeScore = (detail: any) => {
      if (!detail) {
        return {};
      }

      const defaultArray = <T>(length: number, value: T): T[] =>
        Array.from({ length }, () => value);

      const fields = [
        "vitalSign",
        "ambulation",
        "nauseaVomiting",
        "pain",
        "bleeding",
        "dischargeScore",
        "buttonDischargeScore",
      ] as const;

      const result: any = {};

      for (const field of fields) {
        result[field] = detail?.data?.[field] ?? defaultArray(POST_ANES_DEFAULT_LENGTH, null);
      }

      result.id = detail?.id;
      result.totalDischargeScore = detail?.data?.totalDischargeScore ?? null;

      return result;
    };

    const formatTransferScore = (detail: any) => {
      if (!detail) {
        return {};
      }

      const fields = [
        "transfer",
        "transferType",
        "wardSelect",
        "checkAppointment",
        "appointmentData",
        "checkHome",
        "homeData",
      ] as const;

      const result: any = {};

      for (const field of fields) {
        result[field] = detail?.data?.[field];
      }

      result.id = detail.id;
      result.edit_user_name = detail.edit_user_name;
      result.edited = detail.edited;

      return result;
    };

    const dataHandlers = {
      discharge: {
        form: "PostAnestheticDischargeScore",
        formatter: formatDischargeScore,
        setter: (data: any) => ({
          anesDischargeScore: {
            ...state.PACURecordSequence?.anesDischargeScore,
            ...data,
          },
          anesDischargeScoreChanged: {},
        }),
      },
      pacu: {
        form: "PostAnestheticPACUScore",
        formatter: formatPACUScore,
        setter: (data: any) => ({
          anesPACUScore: {
            ...state.PACURecordSequence?.anesPACUScore,
            ...data,
          },
          anesPACUScoreChanged: {},
        }),
      },
      transfer: {
        form: "PostAnestheticTotalDischargeScore",
        formatter: formatTransferScore,
        setter: (data: any) => ({
          anesTransfer: {
            ...state.PACURecordSequence?.anesTransfer,
            ...data,
          },
        }),
      },
    };

    const requestedForms: PostAnestheticKey[] = params.requestForms?.length
      ? params.requestForms
      : ["pacu", "discharge", "transfer"];

    const responses: { key: PostAnestheticKey; response: any }[] = await Promise.all(
      requestedForms.map(async (key) => {
        const api: Promise<any> = FormDataLatest.retrieve({
          apiToken: controller.apiToken,
          params: {
            encounter: state.selectedOrOrder?.encounter,
            form_code: dataHandlers[key].form,
            form_version: "1.0",
          },
          extra: { division: controller.data.division },
        });

        return api.then(([res]: any) => ({ key, response: res }));
      })
    );

    const updatedStateEntries = responses.flatMap(({ key, response }) => {
      const handler = dataHandlers[key];
      const formattedData = handler.formatter(response);
      const newState = handler.setter(formattedData);

      return Object.entries(newState);
    });

    const updatedState = Object.fromEntries(updatedStateEntries);

    controller.setState({
      PACURecordSequence: {
        ...state.PACURecordSequence,
        ...updatedState,
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: null },
    });
  } else if (params?.action === "SavePACUScore") {
    const state = controller.getState();

    let anesPACUScore = state.PACURecordSequence?.anesPACUScore;

    let getMoment = moment();
    let splitDate = getMoment.format("DD/MM/YYYY").split("/");

    const data = {
      data: {
        ...anesPACUScore,
        buttonPACUScore: {
          ...anesPACUScore?.buttonPACUScore,
          [params.index]: {
            editUser: state.django.user.full_name,
            editDate: `${splitDate[0]}/${splitDate[1]}/${+splitDate[2] + 543} [${getMoment.format(
              "HH:mm"
            )}]`,
          },
        },
      },
      encounter: state.selectedOrOrder?.encounter,
      action: "SAVE",
      form_code: "PostAnestheticPACUScore",
      form_name: "PostAnestheticPACUScore",
      form_version: "1.0",
    };

    if (anesPACUScore?.id) {
      const [postAnestheticResp, postAnestheticErr, postAnestheticNet] =
        await FormDataDetail.update({
          apiToken: controller.apiToken,
          pk: anesPACUScore?.id,
          data: data,
        });

      if (postAnestheticResp) {
        controller.setState({
          PACURecordSequence: {
            ...state.PACURecordSequence,
            anesPACUScore: {
              ...anesPACUScore,
              ...postAnestheticResp.data,
            },
          },
        });

        Action(controller, { action: "getPostAnesthetic", requestForms: ["pacu"] });
      }
    } else {
      const [postAnestheticResp, postAnestheticErr, postAnestheticNet] = await FormDataList.create({
        apiToken: controller.apiToken,
        data: data,
      });

      if (postAnestheticResp) {
        controller.setState({
          PACURecordSequence: {
            ...state.PACURecordSequence,
            anesPACUScore: {
              ...anesPACUScore,
              ...postAnestheticResp.data,
              id: postAnestheticResp?.id,
            },
          },
        });

        Action(controller, { action: "getPostAnesthetic", requestForms: ["pacu"] });
      }
    }
  } else if (params?.action === "SaveDischargeScore") {
    const state = controller.getState();

    let anesDischargeScore = state.PACURecordSequence?.anesDischargeScore;
    let anesPACUScore = state.PACURecordSequence?.anesPACUScore;

    const [notPass, pass] = (anesDischargeScore?.dischargeScore || []).reduce(
      (result, value, index) => {
        if (value !== null) {
          result[value < 9 ? 0 : 1] = index;
        }

        return result;
      },
      [null, null]
    );

    const disChargeScoreIndex = pass !== null ? pass : notPass;

    let getMoment = moment();
    let splitDate = getMoment.format("DD/MM/YYYY").split("/");

    const data = {
      data: {
        ...anesDischargeScore,
        totalDischargeScore: {
          score: anesDischargeScore?.dischargeScore?.[disChargeScoreIndex || 0],
          time: anesPACUScore?.time?.[disChargeScoreIndex || 0],
        },
        buttonDischargeScore: {
          ...anesDischargeScore?.buttonDischargeScore,
          [params.index]: {
            editUser: state.django.user.full_name,
            editDate: `${splitDate[0]}/${splitDate[1]}/${+splitDate[2] + 543} [${getMoment.format(
              "HH:mm"
            )}]`,
          },
        },
      },
      encounter: state.selectedOrOrder?.encounter,
      action: "SAVE",
      form_code: "PostAnestheticDischargeScore",
      form_name: "PostAnestheticDischargeScore",
      form_version: "1.0",
    };

    if (anesDischargeScore?.id) {
      const [postAnestheticResp, postAnestheticErr, postAnestheticNet] =
        await FormDataDetail.update({
          apiToken: controller.apiToken,
          pk: anesDischargeScore?.id,
          data: data,
        });

      if (postAnestheticResp) {
        controller.setState({
          PACURecordSequence: {
            ...state.PACURecordSequence,
            anesDischargeScore: {
              ...anesDischargeScore,
              ...postAnestheticResp.data,
            },
          },
        });

        Action(controller, { action: "getPostAnesthetic", requestForms: ["discharge"] });
      }
    } else {
      const [postAnestheticResp, postAnestheticErr, postAnestheticNet] = await FormDataList.create({
        apiToken: controller.apiToken,
        data: data,
      });

      if (postAnestheticResp) {
        controller.setState({
          PACURecordSequence: {
            ...state.PACURecordSequence,
            anesDischargeScore: {
              ...anesDischargeScore,
              ...postAnestheticResp.data,
              id: postAnestheticResp?.id,
            },
          },
        });

        Action(controller, { action: "getPostAnesthetic", requestForms: ["discharge"] });
      }
    }
  } else if (params.action === "SaveAnesTransfer") {
    const state = controller.getState();

    let anesTransfer = state.PACURecordSequence?.anesTransfer;

    const data = {
      data: {
        ...anesTransfer,
      },
      encounter: state.selectedOrOrder?.encounter,
      action: "SAVE",
      form_code: "PostAnestheticTotalDischargeScore",
      form_name: "PostAnestheticTotalDischargeScore",
      form_version: "1.0",
    };

    if (anesTransfer?.id) {
      const [postAnestheticResp, postAnestheticErr, postAnestheticNet] =
        await FormDataDetail.update({
          apiToken: controller.apiToken,
          pk: anesTransfer?.id,
          data: data,
        });

      if (postAnestheticResp) {
        controller.setState({
          PACURecordSequence: {
            ...state.PACURecordSequence,
            anesTransfer: {
              ...anesTransfer,
              ...postAnestheticResp.data,
            },
          },
        });

        Action(controller, { action: "getPostAnesthetic", requestForms: ["transfer"] });
      }
    } else {
      const [postAnestheticResp, postAnestheticErr, postAnestheticNet] = await FormDataList.create({
        apiToken: controller.apiToken,
        data: data,
      });

      if (postAnestheticResp) {
        controller.setState({
          PACURecordSequence: {
            ...state.PACURecordSequence,
            anesTransfer: {
              ...anesTransfer,
              ...postAnestheticResp.data,
              id: postAnestheticResp?.id,
            },
          },
        });

        Action(controller, { action: "getPostAnesthetic", requestForms: ["transfer"] });
      }
    }
  } else if (params.action === "CreateMeasurement") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck, [`${"CardVitalSign_SAVE"}`]: "LOADING",
      }
    });

    let formPacuVitalSignResp = null;
    let formPacuVitalSignErr = null;
    let currentDateTimeUTC: any;
    const vitalSignItems: any[] = state.PACURecordSequence?.anesPACUVitalSign?.items || [];
    const vitalSignTypes: any[] = state.PACURecordSequence?.anesPACUVitalSign?.type || [];
    const vitalSigns = transformVitalSigns(params?.data?.vital_signs, vitalSignTypes);

    if (params?.data?.date && params?.data?.time) {
      currentDateTimeUTC = convertDateTimeToUtc(params.data.date, params.data.time);
    } else {
      currentDateTimeUTC = moment();
    }

    // Create Data
    const setData = {
      id: `anesvitalsign_${currentDateTimeUTC.format()}`,// id of items measurement
      encounter: state.selectedOrOrder?.encounter,
      date: params?.data?.date || formatUTCtoBEString(currentDateTimeUTC.format())[0], // "31/10/2567"
      date_time_utc: currentDateTimeUTC.format(),
      time: params?.data?.time || currentDateTimeUTC.format("HH:mm"), // "04:12"
      vital_signs: vitalSigns
    }

    vitalSignItems.push(setData);

    // Main Data
    const mainData = {
      items: vitalSignItems,
      next: null,
      previous: null,
      total: vitalSignItems.length,
      vitalsign_type: state.PACURecordSequence?.anesPACUVitalSign?.type || [],
    };

    const data = {
      data: mainData,
      encounter: state.selectedOrOrder?.encounter,
      action: "SAVE",
      form_code: "CardPACUVitalSign",
      form_name: "CardPACUVitalSign",
      form_version: "1.0",
    };

    if (state.PACURecordSequence?.anesPACUVitalSign?.id) {
      [formPacuVitalSignResp, formPacuVitalSignErr] = await FormDataDetail.update({
        pk: state.PACURecordSequence?.anesPACUVitalSign?.id,
        apiToken: controller.apiToken,
        data: data,
      });
    } else {
      [formPacuVitalSignResp, formPacuVitalSignErr] = await FormDataList.create({
        apiToken: controller.apiToken,
        data: data,
      });
    }

    if (formPacuVitalSignErr) {
      console.warn("[Create Measurement of PACUVitalsign]: Cannot create Measurement!!", formPacuVitalSignErr);

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck, [`${"CardVitalSign_SAVE"}`]: "ERROR",
        },
      })
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck, [`${"CardVitalSign_SAVE"}`]: "SUCCESS",
        },
        PACURecordSequence: {
          ...state.PACURecordSequence,
          anesPACUVitalSign: {
            ...state.PACURecordSequence?.anesPACUVitalSign,
            id: formPacuVitalSignResp?.pk,
            items: formPacuVitalSignResp?.data?.items,
          }
        }
      });
      Action(controller, { action: "FETCH_PACU" });
    }
  } else if (params.action === "DeleteMeasurement") {
    const state = controller.getState();

    //* Authen not working in this task

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck, [`${"CardVitalSign_SAVE"}`]: "LOADING",
      }
    });

    if (!params?.delMeasureId) {
      return "Error! Cannot Delete Measurement because have no id!!";
    }

    const itemsData = state.PACURecordSequence?.anesPACUVitalSign?.items || [];
    const updatedItems = itemsData.filter((item: any) => item.id !== params?.delMeasureId);

    const mainData = {
      items: updatedItems,
      next: null,
      previous: null,
      total: updatedItems?.length,
      vitalsign_type: state.PACURecordSequence?.anesPACUVitalSign?.type || [],
    };

    const data = {
      data: mainData,
      encounter: state.selectedOrOrder?.encounter,
      action: "SAVE",
      form_code: "CardPACUVitalSign",
      forn_name: "CardPACUVitalSign",
      form_version: "1.0",
    };

    if (!state?.PACURecordSequence?.anesPACUVitalSign?.id) {
      console.warn("[Delete Measurement of PACUVitalSign]: Cannot Delete because have no id!!");
    }

    const [delFormPacuVitalSignResp, delFormPacuVitalSignErr] = await FormDataDetail.update({
      pk: state.PACURecordSequence?.anesPACUVitalSign?.id,
      apiToken: controller.apiToken,
      data: data,
    });

    if (delFormPacuVitalSignErr) {
      console.warn("[Delete Measurement of PACUVitalSign]: Cannot Delete Measurement!!", delFormPacuVitalSignErr);

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck, [`${"CardVitalSign_SAVE"}`]: "ERROR",
        }
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck, [`${"CardVitalSign_SAVE"}`]: "SUCCESS",
        },
        PACURecordSequence: {
          ...state.PACURecordSequence,
          anesPACUVitalSign: {
            ...state.PACURecordSequence?.anesPACUVitalSign,
            items: delFormPacuVitalSignResp?.data?.items,
          }
        }
      });
      Action(controller, { action: "FETCH_PACU" });
    }
  } else if (params.action === "EditVitalSignValue") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck, [`${"CardVitalSign_SAVE"}`]: "LOADING",
      }
    });

    const updateItems = params?.data?.items || [];
    const dataItems = state.PACURecordSequence?.anesPACUVitalSign?.items || [];

    const vitalSignTypes: any[] = state.PACURecordSequence?.anesPACUVitalSign?.type || [];
    const vitalSigns = transformVitalSigns(
      updateItems.filter((item: any) => !item.id),
      vitalSignTypes
    );

    const updatedValueItems = dataItems.map((item: any) => {
      const measurementUpdates = updateItems.filter(
        (update: any) => update.measurement === item.id
      );

      if (measurementUpdates.length > 0) {
        const updatedVitalSigns = item.vital_signs.map((vs: any) => {
          const matchingUpdate = measurementUpdates.find(
            (update: any) => update.vitalsign_type === vs.vitalsign_type
          );

          if (matchingUpdate) {
            return {
              ...vs,
              formatted_string: matchingUpdate.result,
              result: matchingUpdate.result,
              level: calculateVitalSignLevel(matchingUpdate.result, vs.min_ref, vs.max_ref),
            };
          }
          return vs;
        });

        return {
          ...item,
          vital_signs: [...updatedVitalSigns, ...vitalSigns].sort(
            (a, b) => a.display_seq - b.display_seq
          ),
        };
      }

      return item;
    });

    const mainData = {
      items: updatedValueItems,
      next: null,
      previous: null,
      total: updatedValueItems.length,
      vitalsign_type: state.PACURecordSequence?.anesPACUVitalSign?.type || [],
    };

    const data = {
      data: mainData,
      encounter: state.selectedOrOrder?.encounter,
      action: "SAVE",
      form_code: "CardPACUVitalSign",
      form_name: "CardPACUVitalSign",
      form_version: "1.0",
    };

    const [formPacuVitalSignResp, formPacuVitalSignErr] = await FormDataDetail.update({
      pk: state.PACURecordSequence?.anesPACUVitalSign?.id,
      apiToken: controller.apiToken,
      data: data,
    });

    if (formPacuVitalSignErr) {
      console.warn("[Edit VitalSign Value of PACUVitalSign]: Cannot Edit Value!!", formPacuVitalSignErr);

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${"CardVitalSign_SAVE"}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${"CardVitalSign_SAVE"}`]: "SUCCESS",
        },
        PACURecordSequence: {
          ...state.PACURecordSequence,
          anesPACUVitalSign: {
            ...state.PACURecordSequence?.anesPACUVitalSign,
            items: formPacuVitalSignResp?.data?.items,
          }
        }
      });
      Action(controller, { action: "FETCH_PACU" });
    }
  }  else if (params?.action === "SEARCH_NURSE") {
    const [userNurseRes, userNurseErr, userNurseNet] = await UserList.list({
      apiToken: controller.apiToken,
      params: {
        role: "REGISTERED_NURSE",
        name_code: params.searchQuery,
      },
    });

    let userNurseOptions = userNurseRes?.items?.map((item: any) => {
      return {
        key: item?.id,
        value: item?.id,
        text: item?.full_name_code,
      };
    });

    controller.setState({
      PACURecordSequence: {
        ...StateInitial.PACURecordSequence,
        sequenceIndex: "Action",
        userNurseOptions: userNurseOptions,
      },
    });
  }
};

// convert B.E. Date (31/10/2567) and Local Time (14:30) to UTC Format
const convertDateTimeToUtc = (beDate: string, utcTime: string) => {
  const [day, month, beYear] = beDate.split("/");
  const christYear = parseInt(beYear) - 543;
  const [hrs, mins] = utcTime.split(":");

  const utcDateTime = moment()
    .year(christYear)
    .month(parseInt(month) - 1)
    .date(parseInt(day))
    .hour(parseInt(hrs))
    .minute(parseInt(mins))
    .second(0)
    .millisecond(0);

  return utcDateTime;
};

const transformVitalSigns = (data: any[], vitalSignTypes: any[] = []) => {
  const currentUtc = moment().format(); // utc date time
  const currentLocalDate = formatUTCtoBEString(currentUtc)[0]; // "31/10/2567"
  const currentLocalTime = formatUTCtoBEString(currentUtc)[1]; // "05:30" -> "12:30"

  const uniqeData = data.filter(
    (item: any, index: number, self: any) =>
      index === self.findIndex((type: any) => type.vitalsign_type === item.vitalsign_type)
  );

  const vitalSignsMapping = Object.fromEntries(
    vitalSignTypes.map((item) => [
      item.id,
      {
        key: item.code,
        name: item.name,
        unit: item.unit,
        display_seq: item.display_seq,
        min_ref: item.min_value || null,
        max_ref: item.max_value || null,
      },
    ])
  );

  return uniqeData.map((item: any) => {
    const metaData = vitalSignsMapping[item.vitalsign_type];
    const id = `${currentLocalDate}-${currentLocalTime}-${metaData.key}`;

    return {
      id,
      name: metaData.name,
      unit: metaData.unit,
      formatted_string: item.result,
      level: calculateVitalSignLevel(item.result, metaData.min_ref, metaData.max_ref),
      min_ref: metaData.min_ref,
      max_ref: metaData.max_ref,
      display_seq: metaData.display_seq,
      created: moment(currentUtc).utcOffset(7).format(),
      vitalsign_type: item.vitalsign_type,
      result: item.result,
    };
  });
};

const calculateVitalSignLevel = (result: string, min: string | null, max: string | null) => {
  if (min === null && max === null) {
    return "ปกติ";
  }

  const numResult = parseFloat(result);

  if (min !== null) {
    const numMin = parseFloat(min);
    if (numResult < numMin) {
      return "ต่ำ";
    }
  }

  if (max !== null) {
    const numMax = parseFloat(max);
    if (numResult > numMax) {
      return "สูง";
    }
  }

  return "ปกติ";
};

const handleSetTypeAnesthesia = (data: any) => {
  let typeAnesthesia = "";
  typeAnesthesiaData.map((item: any) => {
    if (data?.[item.key] && data?.[item.check]) {
      if (typeAnesthesia === "") {
        typeAnesthesia = item.text;
      } else {
        if (item.key === "IsOthers") {
          typeAnesthesia = `${typeAnesthesia} ,Others: ${data.IsOthersRemark}`;
        } else {
          typeAnesthesia = `${typeAnesthesia} ,${item.text}`;
        }
      }
    }
  });

  return typeAnesthesia;
};

const typeAnesthesiaData = [
  {
    key: "IsGeneralAnesthesia",
    text: "General anesthesia",
    check: "IsGeneralAnesthesia",
  },
  { key: "IsUnderMask", text: "Under mask", check: "IsGeneralAnesthesia" },
  { key: "IsLMA", text: "LMA", check: "IsGeneralAnesthesia" },
  {
    key: "IsEndotrachealTube",
    text: "Endotracheal tube",
    check: "IsGeneralAnesthesia",
  },
  {
    key: "IsTracheostomyTube",
    text: "Tracheostomy tube",
    check: "IsGeneralAnesthesia",
  },
  { key: "IsTIVA", text: "TIVA", check: "IsGeneralAnesthesia" },

  {
    key: "IsRegionalAnesthesia",
    text: "Regional anesthesia",
    check: "IsRegionalAnesthesia",
  },
  { key: "IsSpinalBlock", text: "Spinal block", check: "IsRegionalAnesthesia" },
  {
    key: "IsEpiduralBlock",
    text: "Epidural block",
    check: "IsRegionalAnesthesia",
  },
  {
    key: "IsCombinedSpinalAndEpiduralBlockBlock",
    text: "Combined spinal and epidural block",
    check: "IsRegionalAnesthesia",
  },
  { key: "IsCaudalblock", text: "Caudal block", check: "IsRegionalAnesthesia" },
  {
    key: "IsBrachialPlexusBlock",
    text: "Brachial plexus block",
    check: "IsRegionalAnesthesia",
  },
  { key: "IsOthers", text: "", check: "IsRegionalAnesthesia" },

  { key: "IsMAC", text: "MAC", check: "IsMAC" },
];