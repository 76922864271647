import WasmController from "react-lib/frameworks/WasmController";

import axios from "axios";
import moment from "moment";
import { PDFDocument } from "pdf-lib";
import { TDocumentDefinitions } from "pdfmake/interfaces";

import DrugOrderQueueList from "issara-sdk/apis/DrugOrderQueueList_apps_TPD";
import DoctorPendingTaskDrugOrderList from "issara-sdk/apis/DoctorPendingTaskDrugOrderList_apps_DPO";
import FormPrescriptionDelivery from "../FormPrescriptionDelivery";
import DrugOrderDetailForAction from "issara-sdk/apis/DrugOrderDetailForAction_apps_TPD";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
import DiagnosisMedicalRecordDetail from "issara-sdk/apis/DiagnosisMedicalRecordDetail_apps_DPO";
import AdverseReactionList from "issara-sdk/apis/AdverseReactionList_apps_ADR";
import ProductStockList from "issara-sdk/apis/ProductStockList_coreM";
import AdmitOrderList from "issara-sdk/apis/AdmitOrderList_apps_ADM";
import DrugOrderActionView from "issara-sdk/apis/DrugOrderActionView_apps_TPD";
import DrugOrderPrintPendingSendByCodeView from "issara-sdk/apis/DrugOrderPrintPendingSendByCodeView_apps_TPD";

// Interface
import { SORT_ADR_ORDER } from "./Allergy";

// Form
import FormMedicine from "../FormMedicine";
import FormAdverseReactionMore from "../FormAdverseReactionMore";
import FormMedicineMore from "../FormMedicineMore";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { base64toBlob, splitStringNewLine } from "../../common/CommonInterface";

// Config
import CONFIG from "config/config";

export type State = {
  // Common
  masterOptions?: any;
  selectedEncounter?: any;
  buttonLoadCheck?: any;
  errorMessage?: any;
  selectedDivision?: any;
  django?: any;
  // Seq
  SetDrugOrderQueueSequence?: {
    sequenceIndex: string | null;
    selectedPatientSearch?: any;
    selectedPatientSearchHN?: any;
    selectedDivision?: any;
    selectedDrugOrderType?: any;
    selectedShipper?: any;
    selectedDrugOrderStatus?: any;
    selectedStartDate?: any;
    selectedEndDate?: any;
    selectedLocation?: any;
    selectedApprove?: any;
    checkedOPD?: boolean | null;
    checkedIPD?: boolean | null;
    checkedContinue?: boolean | null;
    searching?: boolean | null;
    checkedList?: any[];
    selectedSort?: string;
    tempFilteredSearch?: Record<string, any>;
    verbalFilter?: {
      patient?: number | null,
      division?: number | null,
      doctor?: number | null,
      startDate?: string | null,
      endDate?: string | null
    }
  } | null;
  drugOrderQueue?: any[];
  verbalDrugOrderQueue?: any[];
};

export const StateInitial: State = {
  SetDrugOrderQueueSequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  user?: number;
  division?: number;
  masterData?: any;
  device?: any;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  let state = controller.getState();
  console.log("SetDrugOrderQueue Start: Before clear state: ", state);
  console.log("SetDrugOrderQueue Start: Before clear params: ", params);
  if (!state.SetDrugOrderQueueSequence) {
    return;
  }
  // Master data
  // แยก เพาะ division โหลดช้า
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["shippingCompany", {}],
        ["divisionTypeDrug", {}],
      ],
    },
  });

  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["drugOrderType", {}],
        ["drugOrderStatus", {}],
      ],
    },
  });

  state = controller.getState();
  // If Card is not mount do nothing
  controller.setState(
    {
      SetDrugOrderQueueSequence: {
        ...state.SetDrugOrderQueueSequence,
        sequenceIndex: "preparedMasterData",
        selectedApprove: "ALL",
        selectedSort: "desc",
      },
    },
    () => {
      preparedMasterData(controller, params);
    }
  );
};

export const preparedMasterData: Handler = async (controller, params) => {
  let state = controller.getState();

  const filter = JSON.parse(
    controller.cookies.get("filterDrugOrderQueue") || "{}"
  );

  console.log( "CLEAR preparedMasterData SelectEncounter !!! params: ", params);

  // console.log("SetDrugOrderQueue clear selectedEncounter: null ")
  let today = formatDate(moment());
  controller.setState(
    {
      SetDrugOrderQueueSequence: {
        ...state.SetDrugOrderQueueSequence,
        sequenceIndex: "SearchDrugOrder",
        checkedIPD: true,
        checkedOPD: true,
        selectedLocation: state.selectedDivision?.id,
        selectedStartDate: today,
        selectedEndDate: today,
        selectedSort: "desc",
        ...getDrugOrderStatusType(controller.getState()?.masterOptions),
        ...filter,
      },
      ...(params.isClearEncounter && { selectedEncounter: null }),
    },
    () =>
      controller.handleEvent({
        message: "RunSequence",
        params: { ...params, action: "search" },
      })
  );
  console.log("SetDrugOrderQueue Start: clear selectedEncounter !!!! ");
};

export const SearchDrugOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.SetDrugOrderQueueSequence) {
    console.warn("SetDrugOrderQueueSequence are null return");
    return;
  }

  if (params?.action === "search") {
    const btnKey = `${params.card}_${params.action}`;

    if (!params.isRefresh) {
      controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });
    }

    const drugOrder = state.SetDrugOrderQueueSequence || {};
    const type: any[] = drugOrder.selectedDrugOrderType || [];
    const status: any[] = drugOrder.selectedDrugOrderStatus || [];

    let urlParams: any = {
      is_opd: drugOrder.checkedOPD,
      is_ipd: drugOrder.checkedIPD,
      is_continue_day_dose: drugOrder.checkedContinue,
      order_div: drugOrder.selectedDivision || "",
      type: type,
      patient: drugOrder.selectedPatientSearch || "",
      is_shipping: true,
      status: status,
      shipping_company: drugOrder.selectedShipper || "",
      order_perform_div: drugOrder.selectedLocation || "",
      approve_status: drugOrder.selectedApprove || "ALL",
      start_date: drugOrder.selectedStartDate || "",
      end_date: drugOrder.selectedEndDate || "",
      exclude_verbal_null_item: true,
      sort_created: drugOrder?.selectedSort || "desc",
      ...(params.isRefresh && drugOrder.tempFilteredSearch),
    };

    // Case Drug Order
    // #58186 set cookies ทุกครั้งเมื่อมีการค้นหา
    delete drugOrder.checkedList;

    controller.cookies.set("filterDrugOrderQueue", JSON.stringify(drugOrder));

    const [drugRes, drugErr] = await DrugOrderQueueList.list({
      params: urlParams,
      apiToken: controller.apiToken,
    });

    if (drugErr) {
      console.log("Drug queue error fetching");
      controller.setState({
        SetDrugOrderQueueSequence: {
          ...state.SetDrugOrderQueueSequence,
          searching: false,
        },
        ...(!params.isRefresh && {
          buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "ERROR" },
        }),
      });
      return;
    }

    controller.setState({
      SetDrugOrderQueueSequence: {
        ...state.SetDrugOrderQueueSequence,
        searching: false,
      },
      drugOrderQueue: drugRes?.items || [],
    });

    // verbal order
    const [drugVerbalRes, drugVerbalErr] =
      await DoctorPendingTaskDrugOrderList.get({
        params: {
          order_specific_types: "DRUG_ORDER",
          patient: state.SetDrugOrderQueueSequence?.selectedPatientSearch || "",
        },
        apiToken: controller.apiToken,
      });

    controller.setState({
      SetDrugOrderQueueSequence: {
        ...state.SetDrugOrderQueueSequence,
        tempFilteredSearch: urlParams,
        searching: false,
      },
      verbalDrugOrderQueue: drugVerbalRes?.items,
      ...(!params.isRefresh && {
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
      }),
    });
  } else if (params.action === "print_prescription_form") {
    const btnKey = `${params.card}_${params.action}`;

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
    });

    await controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [["division", {}]],
      },
    });

    const checkedList = state.SetDrugOrderQueueSequence?.checkedList || [];
    const data = checkedList.map((id) =>
      state.drugOrderQueue?.find((acc) => id === acc.id)
    );

    const [result] = await DrugOrderPrintPendingSendByCodeView.post({
      apiToken: controller.apiToken,
      data: { code: data.map((item) => item.code) },
    });

    const ipdItems = (result?.ipd_result || []).flatMap(
      (item: any) => item.items
    );
    const opdItems = (result?.opd_result || []).flatMap(
      (item: any) => item.items
    );
    const allItems = [...ipdItems, ...opdItems];

    const sortedData = data.sort((a, b) => {
      // เปรียบเทียบประเภท OPD กับ IPD
      let typeComparison = a.encounter_type.localeCompare(b.encounter_type);
      if (typeComparison !== 0) {
        // ถ้าประเภทไม่เท่ากัน ให้เรียงตามประเภท OPD ก่อน
        return typeComparison;
      } else {
        // ถ้าประเภทเท่ากัน ให้เรียงตามวันที่
        return a.requested.localeCompare(b.requested);
      }
    });

    const groupData = sortedData.reduce((result, item) => {
      const findDrug = allItems.find((acc) => acc.code === item.code) || {};
      const data = { ...findDrug, ...item };

      const key = `${data.encounter_type}-${moment(data.requested).format(
        "YYYY-MM-DD"
      )}-${data.order_div_name}`;

      if (result[key]) {
        result[key].items.push(data);
      } else {
        const divisionType = controller.data.masterData?.division?.find(
          (acc: any) => acc.name === data.order_div_name
        )?.type_label;

        result[key] = {
          encounter_type: data.encounter_type,
          division_type: data.encounter_type === "OPD" ? "แผนก" : divisionType,
          requested: data.requested,
          order_div_name: data.order_div_name,
          items: [data],
        };
      }

      return result;
    }, {});

    // Create PDF: Add stock
    const pdfMake = await getPdfMake(true);

    const createPDFBase64 = async (data: any): Promise<string> => {
      const docDef: any = await FormPrescriptionDelivery(data);

      return new Promise((resolve) =>
        pdfMake.createPdf(docDef).getBase64((result: any) => resolve(result))
      );
    };

    const promiseArr = Object.values(groupData).map((data: any) => {
      return createPDFBase64(data);
    });

    const pdfBase64 = await Promise.all(promiseArr);

    const pdfDoc = await PDFDocument.create();

    for (const base64 of pdfBase64) {
      const doc = await PDFDocument.load(base64);
      const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

      for (const page of copiedPages) {
        pdfDoc.addPage(page);
      }
    }

    const base64Data = await pdfDoc.saveAsBase64();

    const blob = base64toBlob("data:application/pdf;base64," + base64Data);

    const bloburl = URL.createObjectURL(blob);

    controller.setState({
      SetDrugOrderQueueSequence: {
        ...state.SetDrugOrderQueueSequence,
        checkedList: [],
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
    });

    window.open(bloburl);
  } else if (params.action === "print_medicine_form") {
    const btnKey = `${params.card}_${params.action}`;

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
    });

    const checkedList = state.SetDrugOrderQueueSequence?.checkedList || [];

    const data = checkedList
    .map((id) => state.drugOrderQueue?.find((acc) => id === acc.id))
    .filter((drug) => drug.status === "REQUESTED");

    const drugs: any[] = await GetMedicineFormAndPrint(controller, { ...params, btnKey, data });

    if (drugs.length === 0) {
      return
    }

    controller.setState(
      {
        SetDrugOrderQueueSequence: {
          ...state.SetDrugOrderQueueSequence,
          checkedList: [],
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
      },
      () =>
        controller.handleEvent({
          message: "RunSequence",
          params: { ...params, action: "search" },
        })
    );

    HandleCreateMedicineForm({ drugs: drugs.filter((drug) => drug.arrayBufferPrint) });
  }
};

export const GetMedicineFormAndPrint: Handler = async (controller, params) => {
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["division", {}]],
    },
  });

  const divisions: any[] = controller.data.masterData?.division || [];
  const data = params.data as Record<string, any>[];

  console.log("print_medicine_form data:", data);
  const patientIds = [...new Set(data.map((item) => item.patient_id as number))];
  const emrIds = [...new Set(data.map((item) => item.emr as number))];
  const encounterIds = [
    ...new Set(
      data.flatMap((item) => (item.encounter_type === "IPD" ? [item.encounter as number] : []))
    ),
  ];
  const keyUser = controller.getState().django;
  const printIPAddress = await getIPAddressFormMedicine();

  const drugPromiseArr = data.map(
    (drug) =>
      DrugOrderDetailForAction.retrieve({
        apiToken: controller.apiToken,
        pk: drug.id,
      }).then((res: any) => ({
        ...drug,
        items: res[0]?.items || [],
        requested: res[0]?.requested,
      })) as Record<string, any>
  );

  const drugs = await Promise.all(drugPromiseArr);

  let drugPrints: any[] = [];

  if (!params.skipPrint) {
    drugPrints = await GetDrugPrintList(controller, { ...params, drugs });

    if (!drugPrints.length) {
      return [];
    }
  }

  console.log("print_medicine_form drugPrints: ", drugPrints);

  let productIds = drugs.flatMap((drug) =>
    drug.items.map(
      (item: any) =>
        `${item.product}|${
          divisions.find((div: any) => div.code === drug.division_code)?.storage || ""
        }`
    )
  );

  productIds = [...new Set(productIds)];

  const patientPromiseArr = patientIds.map((id) =>
    PatientDetailView.retrieve({
      pk: id,
      apiToken: controller.apiToken,
    }).then((res: any) => res[0])
  );

  const diagnosisPromiseArr = emrIds.map((id) =>
    DiagnosisMedicalRecordDetail.retrieve({
      pk: id,
      apiToken: controller.apiToken,
    }).then((res: any) => res[0])
  );

  const adrPromiseArr = patientIds.map((id) =>
    AdverseReactionList.list({
      apiToken: controller.apiToken,
      params: {
        patient: id,
        exclude_cancel: true,
        show_inactive: false,
      },
    }).then((res: any) => ({ patient: id, items: res[0]?.items || [] }))
  );

  const productPromiseArr = productIds.map((value) =>
    ProductStockList.list({
      pk: value.split("|")[0],
      apiToken: controller.apiToken,
      params: {
        storage: value.split("|")[1],
        active: true,
      },
    }).then((res: any) => res[0]?.items?.[0] || {})
  );

  const admitPromiseArr = encounterIds.map((encounter) =>
    AdmitOrderList.list({
      apiToken: controller.apiToken,
      params: { encounter },
    }).then((res: any) => res[0]?.items?.[0] || {})
  );

  const responses = await Promise.all([
    ...patientPromiseArr,
    ...diagnosisPromiseArr,
    ...adrPromiseArr,
    ...productPromiseArr,
    ...admitPromiseArr,
  ]);

  console.log("SearchDrugOrder responses: ", responses);
  const mods = [
    patientIds.length,
    emrIds.length,
    patientIds.length,
    productIds.length,
    encounterIds.length,
    drugs.length,
  ].reduce(
    (result, value, index, self) => {
      const prev = (self[index - 1] || 0) + result.prev;

      result.array.push([prev, value + prev]);
      result.prev = prev;

      return result;
    },
    { prev: 0, array: [] as any[] }
  );

  let [patients, diagnosises, adrs, products, admits] = mods.array.map((value) =>
    responses.slice(value[0], value[1])
  );

  return drugs.map((drug) => {
    const patient = patients.find((patient) => patient.id === Number(drug.patient_id)) || {};
    const principalDiag =
      diagnosises.find((diagnosis) => diagnosis.id === drug.emr)?.principal_diagnosis?.[0] || null;
    const adr = adrs.find((adr) => adr.patient === drug.patient_id) || {};

    const sortedAdr = (adr.items || []).sort(
      (a: any, b: any) => SORT_ADR_ORDER[a.type_name_name] - SORT_ADR_ORDER[b.type_name_name]
    );

    const adrText = sortedAdr
      .map((acc: any) => acc.adr_short_info)
      .filter(Boolean)
      .join(", ");

    const principalDiagnosisText = principalDiag
      ? `[${principalDiag.icd_code}] ${principalDiag.icd_term}`
      : "";

    const roomNo = admits.find((admit) => admit.encounter === drug.encounter)?.room_no || "";

    const divisionType = divisions.find((acc: any) => acc.name === drug.order_div_name)?.type_label;

    const arrayBufferPrint = drugPrints.find((print: any) => print?.id === drug.id)?.data || null;

    return {
      ...drug,
      barcode: `D01${drug.code}`,
      items: drug.items.map((item: any, index: number) => {
        const binLocation =
          products.find((product) => product.product?.id === item.product)?.bin_location || "";

        return {
          ...item,
          seq: index + 1,
          bin_location: binLocation,
        };
      }),
      adrLines: splitStringNewLine(`ADR: ${adrText}`, {
        width: 457.5,
        fontSize: 13,
        ellipse: "",
      }),
      dxLines: splitStringNewLine(`DX-: ${principalDiagnosisText}`, {
        width: 300,
        fontSize: 16,
        ellipse: "",
        max: 2,
      }),
      division_type: divisionType,
      birthdate: patient.birthdate,
      full_age: patient.full_age,
      principal_diagnosis_text: principalDiagnosisText,
      adr_text: adrText,
      room_no: roomNo,
      arrayBufferPrint,
      key_user: keyUser?.user?.full_name,
      ip_address: printIPAddress,
    };
  });
};

const GetDrugPrintList: Handler = async (controller, params) => {
  const state = controller.getState();
  const { drugs } = params;

  const drugPrintPromiseArr = drugs.map((drug) =>
    DrugOrderActionView.update({
      pk: drug.id,
      apiToken: controller.apiToken,
      data: {
        action: "PRINT",
        print_ids: drug.items.map((item: any) => item.id),
      } as any,
      extra: {
        pdf: false,
        division: controller.data.division,
        device: controller.data.device,
      },
    })
      .then((res: any) => {
        // Error ก็มา เคสนี้
        console.log("drugPrintPromiseArr res: ", res);
        if (res[1] && res[2]?.response?.status === 400) {
          if (Array.isArray(res[1])) {
            return { error: res[1] };
          } else {
            return { error: res[1] };
          }
        } else if (!res[0] && !res[1]) {
          return { error: res[2]?.code || "-" };
        } else {
          return { success: res[2]?.headers?.["x-local-print"] || "" };
        }
      })
      .then((local: any) => {
        console.log("drugPrintPromiseArr printString", local);

        if (local?.error) {
          return local;
        }
        if ("success" in local) {
          return local?.success
            ? axios
                .get(`${CONFIG.API_HOST}/users/apis/local-print/${local?.success}`, {
                  responseType: "arraybuffer",
                })
                .then((res) => ({ id: drug.id, data: res.data }))
            : null;
        }
      })
  );

  const drugPrints = await Promise.all(drugPrintPromiseArr);
  console.log("drugPrints: ", drugPrints);

  const haveOtherError = drugPrints?.filter((i: any) => i?.error && !Array.isArray(i.error));
  const haveMedRecError = drugPrints?.filter((i: any) => i?.error && Array.isArray(i.error));
  const haveSuccess = drugPrints?.filter((i: any) => !i?.error);
  const ablePrint = haveSuccess?.length > 0;
  console.log("haveMedRecError: ", haveMedRecError);
  console.log("haveOtherError: ", haveOtherError);

  if (
    (haveMedRecError?.length > 0 || haveOtherError?.length > 0) &&
    !params.bypass?.bypassCheckMedRec
  ) {
    controller.setState({
      SetDrugOrderQueueSequence: {
        ...state.SetDrugOrderQueueSequence,
        drugPendingMedReconcile: {
          open: true,
          ablePrint: ablePrint,
          medError: haveMedRecError?.map?.((i: any) => {
            return i.error;
          }),
          otherError: haveOtherError?.map?.((i: any) => {
            if (typeof i.error === "string") {
              return i.error;
            } else if (i?.error?.encounter) {
              return i.error?.encounter;
            } else if (i?.error?.message) {
              return i.error?.message;
            } else if (i?.error?.message) {
              console.warn(i.error);

              return "-";
            }
          }),
        },
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnKey]: null },
    });

    return [];
  }

  return haveSuccess;
};

export const HandleCreateMedicineForm = async (params: { drugs: any[] }) => {
  const createPDFBase64 = async (docDef: TDocumentDefinitions): Promise<string> =>
    new Promise((resolve) => {
      pdfMake.createPdf(docDef).getBase64((result: any) => {
        resolve(result);
      });
    });

  const pdfMake = await getPdfMake(true);
  const pdfDoc = await PDFDocument.create();

  const drugs: any[] = params.drugs

  for (const drug of drugs) {
    const isMoreAdr = drug.adrLines.length > 2;
    const allDrugItems = [...drug.items];
    const drugItems: any[] = drug.items;

    let moreDrugs: any[] = [];

    moreDrugs = drug.dxLines.length === 1 ? drugItems.splice(7) : drugItems.splice(6);

    const pageTotal = [moreDrugs.length, isMoreAdr].filter(Boolean).length + 1;

    const promiseArr = [
      createPDFBase64(await FormMedicine({ ...drug, page: `1/${pageTotal}` })),
    ];

    if (moreDrugs.length > 0) {
      promiseArr.push(
        createPDFBase64(FormMedicineMore({ ...drug, items: moreDrugs, page: `2/${pageTotal}` }))
      );
    }

    if (isMoreAdr) {
      promiseArr.push(
        createPDFBase64(FormAdverseReactionMore({ ...drug, page: `${pageTotal}/${pageTotal}` }))
      );
    }

    const pdfBase64 = await Promise.all(promiseArr);

    if (drug.arrayBufferPrint) {
      const docPrint = await PDFDocument.load(drug.arrayBufferPrint);

      const copiedPages = await pdfDoc.copyPages(docPrint, docPrint.getPageIndices());

      for (const [index] of allDrugItems.entries()) {
        pdfDoc.addPage(copiedPages[index]);
      }
    }

    for (const base64 of pdfBase64) {
      const doc = await PDFDocument.load(base64);
      const copiedPages = await pdfDoc.copyPages(doc, [0]);

      pdfDoc.addPage(copiedPages[0]);
    }
  }

  if (drugs.length === 0) {
    return;
  }

  const base64Data = await pdfDoc.saveAsBase64();
  const blob = base64toBlob(`data:application/pdf;base64,${base64Data}`);
  const bloburl = URL.createObjectURL(blob);

  window.open(bloburl);
};

// Utils
export const getDrugOrderStatusType = (
  masterOptions: Record<string, any[]>
) => {
  const statusKeys = ["REQUESTED", "PRINTED", "CHECKED"];
  const typeKeys = [
    "STAT",
    "ONE_DAY",
    "ONE_DOSE",
    "HOME_OPD",
    "CONTINUE_PLAN",
    "CONTINUE",
    "HOME_IPD",
    "OPERATING",
  ];

  const options = masterOptions || {};
  const type: any[] = options.drugOrderType || [];
  const status: any[] = options.drugOrderStatus || [];

  const assignValue = (lists: any[], keys: string[]) => {
    return lists.flatMap((item) =>
      keys.includes(item.text) ? [item.value] : []
    );
  };

  return {
    selectedDrugOrderStatus: assignValue(status, statusKeys),
    selectedDrugOrderType: assignValue(type, typeKeys),
  };
};

// User's Public IP Address
const getIPAddressFormMedicine = async () => {
  try {
    const resp = await axios.get("https://api.ipify.org?format=json");
    const ipAddress = resp.data.ip;
    return ipAddress;
  } catch (error) {
    console.error("Error fetching IP Address: ", error);
  }
};