import WasmController from "react-lib/frameworks/WasmController";

import moment from "moment";

// APIs
// CORE
import PackageList from "issara-sdk/apis/PackageList_core";
import PackageDetail from "issara-sdk/apis/PackageDetail_core";
import PackageOrderList from "issara-sdk/apis/PackageOrderList_core";
import PackageOrderDetail from "issara-sdk/apis/PackageOrderDetail_core";
// APP
import PackageUsageHistoryList from "issara-sdk/apis/PackageUsageHistoryList_apps_APP";
// BIL
import InvoiceItemList from "issara-sdk/apis/InvoiceItemList_apps_BIL";
import PackageOrderInformation from "issara-sdk/apis/PackageOrderInformation_core";

// Serializer
import PackageOrderSerializerI from "issara-sdk/types/PackageOrderSerializer_core";
import PackageOrderSerializerForDetailI from "issara-sdk/types/PackageOrderSerializerForDetail_core";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { PackageDetailType, UsageLimitType } from "./SettingPackage";
import { combinePdfFiles, SetErrorMessage } from "../../common/CommonInterface";

// Form
import FormPackageInformation from "../pdf/FormPackageInformation";

// Utils
import { beToAd } from "react-lib/utils/dateUtils";

export type State = Partial<{
  // sequence
  PackagePurchaseSequence: Partial<{
    sequenceIndex: SequenceIndexType | null;
    filterPackage: Partial<FilterPackageType>;
    filterPurchaseList: Partial<FilterPurchaseListType>;
    filterPurchaseHistory: Partial<FilterPurchaseHistoryType>;
    filterAppointmentHistory: Partial<FilterAppointmentHistoryType>;
    packageList: PackageDetailType[];
    purchaseList: PackageOrderSerializer[];
    purchaseHistoryList: PackageOrderSerializer[];
    appointmentHistoryList: AppointmentLogType[];
    packageInfo: PackageDetailType;
    selectedPackageOrder: PackageOrderSerializerForDetail;
    // Payment
    openCardPaying: boolean;
    invoiceItemByItems: any[];
  }> | null;
}>;

type Picked = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "django"
    | "searchedItemListWithKey"
    | "successMessage"
    | "selectedPatient"
    | "purchaseOrderList"
  >
>;

export type PackageOrderSerializerForDetail = {
  // extra เพิ่มมาจาก detail เพิ่ม ดู usage_limit
  usage_limit?: UsageLimitType;
} & Omit<
  PackageOrderSerializerForDetailI,
  "status" | "usage_status_label" | "usage_status"
> &
  Pick<
    PackageOrderSerializer,
    "status" | "usage_status_label" | "usage_status"
  >;

export type FilterPackageType = {
  isServiceType: boolean;
  serviceType: number | null;
  isPackageType: boolean;
  packageType: number | null;
  isProgramName: boolean;
  programId: number | null;
  isGender: boolean;
  gender: "M" | "F" | "";
  isAgeRange: boolean;
  ageStart: string | number;
  ageEnd: string | number;
  ageRangeError: boolean;
};

type FilterPurchaseListType = {
  product: number | null;
  serviceType: number | null;
  productType: number | null;
  productStatus: "ACTIVE" | "INACTIVE" | "";
};

type FilterPurchaseHistoryType = {
  product: number | null;
  payment: number | null;
  usage: string[] | null;
  division: number | null;
  serviceType: number | null;
  packageType: number | null;
};

type FilterAppointmentHistoryType = {
  package: number | null;
  serviceType: number | null;
  product: number | null;
  startDate: string;
  endDate: string;
  status: "APPOINTMENT" | "COMPLETED";
};

export type PackageOrderSerializer = {
  inactive: boolean;
  product_code: string;
  product_name: string;
  package_type: number;
  package_type_name: string;
  package_service_type: number;
  package_service_type_name: string;
  package_start_date: string;
  package_end_date: string;
  price_normal: string;
  price: string;
  usage_status: keyof typeof USAGE_STATUS;
  usage_status_label: "สมบูรณ์" | "ไม่สมบูรณ์";
  status: keyof typeof ORDER_PAYMENT_STATUS;
} & PackageOrderSerializerI;

export type AppointmentLogType = {
  dt: string;
  usage_datetime: string;
  status: "APPOINTMENT" | "COMPLETED";
  quantity: string;
  package_id: number;
  package_code: string;
  package_name: string;
  product_id: number;
  product_code: string;
  product_name: string;
  package_order_id: number;
  package_order_item_id: number;
  status_label: "นัดหมาย" | "รับบริการแล้ว";
};

export type StatusesFilterType = ("BILLED" | "PAID" | "CANCELED")[];

export type MasterOptionsType = Record<
  (typeof Masters)[number][0],
  OptionType[] | undefined
>;

export type MasterDataType = Record<
  (typeof Masters)[number][0],
  Record<string, any>[]
>;

type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
  relate?: string;
};

// Sequence
type SequenceIndexType =
  | "Start"
  | "SearchPackage"
  | "PurchaseList"
  | "PurchaseHistory";

type SeqState = {
  sequence: "PackagePurchase";
  restart?: boolean;
  clear?: boolean;
  nextIndex?: SequenceIndexType;
  card: string;
  divisionId?: number | null;
  statusesFilter?: StatusesFilterType;
};

// Handle Action
type ActionType =
  // Search
  | { action: "SEARCH"; card: string; statusesFilter?: StatusesFilterType }
  | { action: "SEARCH_USAGE_HISTORY"; card: string }
  // Action
  | { action: "PURCHASE"; card: string; packageId: number }
  | { action: "DESCRIPTION"; card: string; data: PackageDetailType }
  | {
      action: "SELECT_PACKAGE_ORDER";
      orderId: number;
      index: number;
      card: string;
      isFetchDetail?: boolean; // Get detail เพื่อดู usage_limit เพื่อซ่อนจำนวนนัดหมาย
    }
  | {
      action: "PRINT_PACKAGE_ORDER";
      orderId: number;
      index: number;
      card: string;
    }
  // Method
  | { action: "PAYMENT"; card: string; items: PackageOrderSerializer[] }
  | {
      action: "UPDATE" | "DELETE";
      card: string;
      packageId: number;
      data: Partial<PackageOrderSerializer>;
      orderId: number;
      onSuccess?: Function;
    }
  | {
      action: "CANCEL_PACKAGE";
      data: {
        reason: string;
        packageId: number;
        orderId: number;
        quantity: string;
      };
      statusesFilter?: StatusesFilterType;
      card: string;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type CustomExtract<T, U> = T extends T
  ? U extends Partial<T>
    ? T
    : never
  : never;

type Params<A extends ActionType["action"]> = CustomExtract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  PackagePurchaseSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

const Masters = [
  ["packageType", {}],
  ["packageServiceType", {}],
  ["division", {}],
  ["bilStation", {}],
  ["cancelPackageOrder", {}],
] as const;

export const BUTTON_ACTIONS = {
  search: "SEARCH",
  purchase: "PURCHASE",
  desc: "DESCRIPTION",
  delete: "DELETE",
  payment: "PAYMENT",
};

export const USAGE_STATUS = {
  COMPLETED: "COMPLETED",
  INCOMPLETED: "INCOMPLETED",
};

export const ORDER_PAYMENT_STATUS = {
  BILLED: "BILLED",
  PAID: "PAID",
};

export const PACKAGE_SEARCH_PH = "Package_PH";

export const PACKAGE_SEARCH_SP = "Package_SP";

export const PACKAGE_SEARCH_PL = "Package_PL";

export const PACKAGE_SEARCH_AUH = `Package_AUH`;

export const PRODUCT_SEARCH_AUH = `ProductAll_AUH`;

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  controller.setState(
    {
      PackagePurchaseSequence: {
        ...state.PackagePurchaseSequence,
        sequenceIndex: params.nextIndex,
        packageList: [],
        purchaseList: [],
        purchaseHistoryList: [],
        filterPurchaseHistory: {
          division: params.divisionId,
        },
        appointmentHistoryList: [],
      },
    },
    () => {
      const search = (
        {
          SearchPackage: SearchPackage,
          PurchaseList: PurchaseList,
          PurchaseHistory: PurchaseHistory,
        } as any
      )[params.nextIndex || ""];

      search?.(controller, { ...params, action: "SEARCH" });
    }
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const SearchPackage: Handler<ActionType> = async (
  controller,
  params
) => {
  if (params.action === "SEARCH") {
    HandleSearchPackage(controller, params);
  } else if (params.action === "PURCHASE") {
    HandleBuyPackage(controller, params);
  } else if (params.action === "DESCRIPTION") {
    HandleDescription(controller, params);
  }
};

export const PurchaseList: Handler<ActionType> = async (controller, params) => {
  if (params.action === "SEARCH") {
    HandleSearchPurchaseList(controller, params);
  } else if (params.action === "DELETE") {
    HandleDeletePurchaseList(controller, params);
  } else if (params.action === "PAYMENT") {
    HandlePayment(controller, params);
  } else if (params.action === "DESCRIPTION") {
    HandleDescription(controller, params);
  } else if (params.action === "UPDATE") {
    HandleUpdatePurchaseList(controller, params);
  }
};

export const PurchaseHistory: Handler<ActionType> = async (
  controller,
  params
) => {
  if (params.action === "SEARCH") {
    HandleSearchPurchaseHistory(controller, params);
  } else if (params.action === "SEARCH_USAGE_HISTORY") {
    HandleSearchUsageHistory(controller, params);
  } else if (params.action === "SELECT_PACKAGE_ORDER") {
    HandleSelectPackageOrder(controller, params);
  } else if (params.action === "PRINT_PACKAGE_ORDER") {
    HandlePrintPackageOrder(controller, params);
  } else if (params.action === "CANCEL_PACKAGE") {
    HandleCancelPackage(controller, params);
  }
};

/* ------------------------------------------------------ */

/*                  Handle SearchPackage                  */

/* ------------------------------------------------------ */

const HandleSearchPackage: Handler<Params<"SEARCH">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const list = await GetPackageList(controller, params);

  controller.setState({
    PackagePurchaseSequence: {
      ...state.PackagePurchaseSequence,
      packageList: list,
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
  });
};

const HandleBuyPackage: Handler<Params<"PURCHASE">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}_${params.packageId}`]: "LOADING",
    },
  });

  const orderList: any[] = await GetPackageOrderList(controller, {});

  const order = orderList.find((item) => item.product === params.packageId);

  const [_, error] = await CreateUpdatePackageOrder(controller, {
    ...params,
    data: { quantity: 1 },
    ...(order
      ? { orderId: order.id, data: { quantity: Number(order.quantity) + 1 } }
      : {}),
  });

  if (error) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}_${params.packageId}`]: null,
      },
      errorMessage: {
        ...state.successMessage,
        ...(error.__error_type__ === "BILLING_LOCKED"
          ? { ModLockExpense: [error] }
          : { [params.card]: error }),
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}_${params.packageId}`]: null,
      },
      successMessage: {
        ...state.successMessage,
        [params.card]: "เพิ่มรายการไปยัง Purchase list แล้ว",
      },
    });

    controller.handleEvent({
      message: "HandleGetPurchaseOrderList",
      params: {},
    } as any);
  }
};

const HandleDescription: Handler<Params<"DESCRIPTION">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const btnKey = `${params.card}_${params.action}_${params.data.id}`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const [result] = await PackageDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.data.id,
  });

  controller.setState({
    PackagePurchaseSequence: {
      ...state.PackagePurchaseSequence,
      packageInfo: result ? { ...result, price: params.data.price } : {},
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "" },
  });
};

/* ------------------------------------------------------ */

/*                   Handle PurchaseList                  */

/* ------------------------------------------------------ */

const HandleSearchPurchaseList: Handler<Params<"SEARCH">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const items: PackageOrderSerializer[] = await GetPackageOrderList(
    controller,
    {
      ...params,
      isFilter: true,
    }
  );

  const purchaseList = items.map((item) => {
    return {
      ...item,
      inactive:
        (beToAd(item.package_end_date)?.diff(moment(), "days") || 0) < 0,
    };
  });

  state = controller.getState();

  controller.setState({
    PackagePurchaseSequence: {
      ...state.PackagePurchaseSequence,
      purchaseList,
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
  });
};

const HandleDeletePurchaseList: Handler<Params<"DELETE">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [_, error] = await CreateUpdatePackageOrder(controller, {
    ...params,
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });
  } else {
    params.onSuccess?.();

    controller.setState(
      {
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: null,
        },
      },
      () => {
        HandleSearchPurchaseList(controller, { ...params, action: "SEARCH" });

        controller.handleEvent({
          message: "HandleGetPurchaseOrderList",
          params: {},
        } as any);
      }
    );
  }
};

const HandlePayment: Handler<Params<"PAYMENT">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [invoiceByItem] = await GetInvoiceItemByItem(controller, {});

  const invoiceDict = (invoiceByItem?.items || []).reduce(
    (r: any, o: any) => ({ ...r, [o.order_id]: o }),
    {}
  );

  const invoiceItems = params.items.map((item) => {
    const data = invoiceDict[item.id] || {};

    return {
      ...data,
      pay: Number(data.price || "0"),
    };
  });

  controller.setState({
    PackagePurchaseSequence: {
      ...state.PackagePurchaseSequence,
      openCardPaying: true,
      invoiceItemByItems: invoiceItems,
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
  });
};

const HandleUpdatePurchaseList: Handler<Params<"UPDATE">> = async (
  controller,
  params
) => {
  const [_, error] = await CreateUpdatePackageOrder(controller, {
    ...params,
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });
  } else {
    HandleSearchPurchaseList(controller, { ...params, action: "SEARCH" });
  }
};

/* ------------------------------------------------------ */

/*                 Handle PurchaseHistory                 */

/* ------------------------------------------------------ */

const HandleSearchPurchaseHistory: Handler<Params<"SEARCH">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const items: PackageOrderSerializer[] = await GetPurchaseHistoryList(
    controller,
    params
  );

  state = controller.getState();

  controller.setState({
    PackagePurchaseSequence: {
      ...state.PackagePurchaseSequence,
      purchaseHistoryList: items,
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
  });
};

const HandleSelectPackageOrder: Handler<
  Params<"SELECT_PACKAGE_ORDER">
> = async (controller, params) => {
  const state = controller.getState();

  const btnKey = `${params.card}_${params.action}_${params.index}`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const [result] = await PackageOrderDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.orderId,
  });

  let detail: any = [];

  if (params.isFetchDetail) {
    [detail] = await PackageDetail.retrieve({
      apiToken: controller.apiToken,
      pk: result?.product,
    });
  }

  controller.setState({
    PackagePurchaseSequence: {
      ...state.PackagePurchaseSequence,
      selectedPackageOrder: result
        ? { ...result, usage_limit: detail?.usage_limit }
        : {},
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
  });
};

const HandlePrintPackageOrder: Handler<Params<"PRINT_PACKAGE_ORDER">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const btnKey = `${params.card}_${params.action}_${params.index}`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const [result, error] = await PackageOrderInformation.get({
    apiToken: controller.apiToken,
    pk: params.orderId,
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });

    return;
  }

  const tables = groupItemsBySeq(result.fields || []);

  const docDefs = [];

  for (const [index, items] of tables.entries()) {
    docDefs.push(
      FormPackageInformation({
        currentPage: index + 1,
        items,
        ...result.params,
        pageCount: tables.length,
      })
    );
  }

  const blob = await combinePdfFiles(await Promise.all(docDefs));

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
  });

  window.open(blob);
};

const HandleSearchUsageHistory: Handler<
  Params<"SEARCH_USAGE_HISTORY">
> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const items: AppointmentLogType[] = await GetUsageHistoryList(controller, {});

  state = controller.getState();

  controller.setState({
    PackagePurchaseSequence: {
      ...state.PackagePurchaseSequence,
      appointmentHistoryList: items,
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
  });
};

const HandleCancelPackage: Handler<Params<"CANCEL_PACKAGE">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [_, error] = await PackageOrderDetail.update({
    apiToken: controller.apiToken,
    pk: params.data.orderId,
    data: {
      patient: state.selectedPatient?.id,
      product: params.data.packageId,
      status: "CANCELED",
      remark: params.data.reason,
      quantity: params.data.quantity,
    } as any,
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });

    HandleSearchPurchaseHistory(controller, {
      ...params,
      action: "SEARCH",
      statusesFilter: params.statusesFilter,
    });
  }
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const CreateUpdatePackageOrder: Handler<{
  packageId: number;
  data: Record<string, any>;
  orderId?: number;
}> = async (controller, params) => {
  const state = controller.getState();

  const api = params.orderId
    ? PackageOrderDetail.update
    : PackageOrderList.create;

  return api({
    apiToken: controller.apiToken,
    pk: params.orderId,
    data: {
      patient: state.selectedPatient?.id,
      product: params.packageId,
      ...params.data,
    },
    extra: { division: controller.data.division },
  });
};

const GetPackageOrderList: Handler<{ isFilter?: boolean }> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const filter = state.PackagePurchaseSequence?.filterPurchaseList || {};
  const packageList: any[] =
    state.searchedItemListWithKey?.[PACKAGE_SEARCH_PL] || [];
  const product = packageList.find((item) => item.id === filter.product);

  // ต้องระบุ patient id
  if (!state.selectedPatient?.id) {
    return [];
  }

  const code = product?.code;
  let canPurchase: boolean | undefined = undefined;

  if (filter.productStatus === "ACTIVE") {
    canPurchase = true;
  } else if (filter.productStatus === "INACTIVE") {
    canPurchase = false;
  }

  const result = await PackageOrderList.list({
    apiToken: controller.apiToken,
    params: {
      patient: state.selectedPatient?.id,
      statuses: "BILLED",
      active: true,
      ...(params.isFilter
        ? {
            can_purchase: canPurchase,
            package_service_type: filter.serviceType || undefined,
            package_type: filter.productType || undefined,
            keyword: code,
          }
        : {}),
    },
  });

  const items: any[] = (result?.[0]?.items || []).filter(
    (item: any) =>
      // active and ยังไม่ชำระ and code exact match
      item.active && !item.paid_at && (code ? item.product_code === code : true)
  );

  return items;
};

const GetPurchaseHistoryList: Handler<Params<"SEARCH">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const filter = state.PackagePurchaseSequence?.filterPurchaseHistory || {};
  const packageList: any[] =
    state.searchedItemListWithKey?.[PACKAGE_SEARCH_PH] || [];
  const product = packageList.find((item) => item.id === filter.product);
  const patientId = state.selectedPatient?.id;

  // ต้องระบุ patient id
  if (!patientId) {
    return [];
  }

  const code = product?.code;

  const result = await PackageOrderList.list({
    apiToken: controller.apiToken,
    params: {
      patient: patientId,
      statuses: params.statusesFilter || "PAID",
      active: true,
      package_service_type: filter.serviceType || undefined,
      package_type: filter.packageType || undefined,
      keyword: code,
      performed_division: filter.division,
      usage_status: filter.usage,
    },
  });

  let items: any[] = result?.[0]?.items || [];

  items = items.filter((item) => (code ? item.product_code === code : true));

  return items;
};

const GetUsageHistoryList: Handler = async (controller) => {
  const state = controller.getState();

  const filter = state.PackagePurchaseSequence?.filterAppointmentHistory || {};
  const packageList: any[] =
    state.searchedItemListWithKey?.[PACKAGE_SEARCH_AUH] || [];
  const productList: any[] =
    state.searchedItemListWithKey?.[PRODUCT_SEARCH_AUH] || [];

  const findPackage = packageList.find((item) => item.id === filter.package);
  const findProduct = productList.find((item) => item.id === filter.product);

  const patientId = state.selectedPatient?.id;

  // ต้องระบุ patient id
  if (!patientId) {
    return [];
  }

  const packageCode = findPackage?.code;
  const productCode = findProduct?.code;

  const result = await PackageUsageHistoryList.get({
    apiToken: controller.apiToken,
    params: {
      patient: patientId,
      // package : package
      package_search: packageCode,
      package_service_type: filter.serviceType || undefined,
      // - product = product_id
      product_search: productCode,
      status: filter.status,
      date_start: filter.startDate,
      date_end: filter.endDate,
    },
  });

  let items: any[] = result?.[0]?.items || [];

  items = items.filter(
    (item) =>
      (packageCode ? item.package_code === packageCode : true) &&
      (productCode ? item.product_code === productCode : true)
  );

  return items;
};

const GetPackageList: Handler<{}, Promise<PackageDetailType[]>> = async (
  controller
) => {
  const state = controller.getState();

  const packagePurchase = state.PackagePurchaseSequence || {};
  const filter = packagePurchase.filterPackage || {};
  const packageList: any[] =
    state.searchedItemListWithKey?.[PACKAGE_SEARCH_SP] || [];

  const formatParams = (
    isKey: keyof FilterPackageType,
    value: keyof FilterPackageType
  ) => {
    return (filter[isKey] || undefined) && filter[value];
  };

  const code = filter.isProgramName
    ? packageList.find((item) => item.id === filter.programId)?.code
    : undefined;

  const result = await PackageList.list({
    apiToken: controller.apiToken,
    params: {
      active: true,
      package_service_type: formatParams("isServiceType", "serviceType"),
      package_type: formatParams("isPackageType", "packageType"),
      keyword: code,
      gender: formatParams("isGender", "gender"),
      age_start: formatParams("isAgeRange", "ageStart"),
      end_start: formatParams("isAgeRange", "ageEnd"),
      patient: state.selectedPatient?.id,
    },
  });

  let items: any[] = result[0]?.items || [];

  items = items.filter((item) => {
    const start = moment(beToAd(item.start_date));
    const end = moment(beToAd(item.end_date));

    // target date/time to check
    const target = moment();

    // code exact match
    const isMatch = code ? item.code === code : true;

    // Check if the date/time is between the range
    return target.isBetween(start, end) && isMatch;
  });

  return items;
};

export const GetInvoiceItemByItem: Handler = async (controller, params) => {
  const state = controller.getState();

  return await InvoiceItemList.list({
    params: {
      patient: state.selectedPatient?.id,
      pending: true,
      strict_encounter_type: true,
    },
    extra: { device: controller.data.device },
    apiToken: controller.apiToken,
  });
};

// Formatted
const groupItemsBySeq = (fields: Record<string, any>[]) => {
  const flatItems = fields.flatMap((item) => {
    const header = { name: item.p_type_name, seq: item.items[0].seq, header: true };
    const items: Record<string, any>[] = item.items;

    return [header, ...items.map((acc) => ({ ...acc, p_type_name: item.p_type_name }))];
  }) as Record<string, any>[];

  const batchSize = 20;
  const batches: Record<string, any>[] = [];

  let prevIndex = 0;

  for (const item of flatItems) {
    const batchIndex = Math.floor((item.seq - 1) / batchSize);

    batches[batchIndex] ??= [];

    if (prevIndex !== batchIndex && !item.header) {
      const data = flatItems.find((acc) => acc.name === item.p_type_name);

      if (data) {
        batches[batchIndex].push(data);
      }
    }

    prevIndex = batchIndex;

    batches[batchIndex].push(item);
  }

  return batches;
};