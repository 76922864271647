import React, { CSSProperties, useCallback, useEffect, useMemo, useRef } from "react";

import { Input } from "semantic-ui-react";

import { useIntl } from "react-intl";
import { ComponentPropsGetterR, RowInfo } from "react-table-6";

// Framework
import { Table } from "react-lib/frameworks/Table";

// Types
export type DrugSearchBoxProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;
  // data
  disabled?: boolean;
  drugSearchLoading?: boolean;
  drugSearchResult?: Record<string, any>[];
  drugSearchText?: string;
  selectedDrug?: Record<string, any> | null;
  selectOnBlur?: boolean;
  trStyle?: CSSProperties;
  // CommonInterface
  errorMessage?: any;
  // callback
  onSearchChange?: () => void;
  onSelect?: (rowInfo: RowInfo) => any;
};

type Styles = Record<"table", CSSProperties>;

// Constants
const styles: Styles = {
  table: { height: "250px" },
};

const DrugSearchBox = (props: DrugSearchBoxProps) => {
  const intl = useIntl();
  const cardRef = useRef<HTMLDivElement | null>(null);
  const timeoutRef = useRef<any>(0);
  const abortController = useRef<AbortController>(null);
  const selectedDrugRef = useRef<any>(null);
  const isLoadingRef = useRef(false);
  const loadingTimerRef = useRef<any>(null);

  // Callback effect
  const handleClose = useCallback(async () => {
    props.setProp("drugSearchText", "");
    props.setProp("drugSearchResult", []);
    props.setProp("drugSearchLoading", false);

    document.removeEventListener("click", handleDocumentClick);

    handleClear();
  }, []);

  const handleDocumentClick = useCallback(
    (e: MouseEvent) => {
      console.log(" handleDocumentClick", e);

      const isOutsideCard = !(
        cardRef.current?.contains(e.target as Node) || selectedDrugRef.current
      );
      const isSelectOnBlurFalse = props.selectOnBlur === false;
      const hasRtTrGroup = (e.target as Element).closest(".rt-tr-group") !== null;

      if (isOutsideCard && (!isSelectOnBlurFalse || hasRtTrGroup)) {
        handleClose();
      }
    },
    [props.selectOnBlur]
  );

  // Effect
  useEffect(() => {
    selectedDrugRef.current = props.selectedDrug;
  }, [props.selectedDrug]);

  useEffect(
    () => () => {
      handleClose();
    },
    []
  );

  // Memo
  const showTable = useMemo(
    () =>
      props.selectedDrug === null &&
      (props.drugSearchText?.length || 0) >= 3 &&
      (props.drugSearchResult?.length || 0) > 0,
    [props.drugSearchResult, props.drugSearchText, props.selectedDrug]
  );

  useEffect(() => {
    if (showTable) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [handleDocumentClick, showTable]);

  const drugSearchResult = useMemo(
    () =>
      (props.drugSearchResult || []).map((item) => {
        const drugIngredients: Record<string, any>[] = item.drug_ingredients || [];

        return {
          ...item,
          drug_ingredients: drugIngredients.map((acc) => (
            <div key={`${acc.ingredient_name}-${acc.strength}`}>
              {acc.ingredient_name} {acc.strength}
            </div>
          )),
          drug_name_text: (
            <div>
              {item.drug_name_from_format}
              {item.individual_use && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {intl.formatMessage({ id: "(ยาสำหรับผู้ป่วยเฉพาะราย)" })}
                </div>
              )}
            </div>
          ),
          generic_name: <div dangerouslySetInnerHTML={{ __html: item.generic_name || "" }} />,
        };
      }),
    [props.drugSearchResult]
  );

  // Handler
  const handleClear = () => {
    // * หาก มี การ request อยู่แต่มีการแก้ไขคำค้นหาให้ Cancel request
    if (abortController.current) {
      abortController.current.abort();
    }

    clearTimeout(timeoutRef.current);

    clearTimeout(loadingTimerRef.current);
  };

  const handleChange = useCallback((e: any, data: any) => {
    props.onSearchChange?.();

    if (data.value?.length <= 2 && props.drugSearchLoading) {
      props.setProp("drugSearchLoading", false);
    }

    props.setProp("drugSearchText", data.value);

    handleClear();

    timeoutRef.current = setTimeout(() => {
      clearTimeout(timeoutRef.current);

      handleSearchDrug(data.value);
    }, 250);
  }, []);

  const handleSearchDrug = (value: string) => {
    props.onEvent({
      message: "DrugSearch",
      params: {
        abortController,
        keyword: value,
      },
    });
  };

  const handleDrugSearchProps: ComponentPropsGetterR = (state, rowInfo) => ({
    style: props.trStyle,
    onClick: () => {
      const handleSetTimeout = () => {
        loadingTimerRef.current = setTimeout(() => {
          isLoadingRef.current = false;
        }, 2000);
      };

      if (isLoadingRef.current) {
        if (loadingTimerRef.current) {
          clearTimeout(loadingTimerRef.current);
        }

        handleSetTimeout();

        return;
      }

      isLoadingRef.current = true;

      if (rowInfo) {
        props.onSelect?.(rowInfo);
      }

      handleSetTimeout();
    },
  });

  return (
    <div ref={cardRef} style={{ width: "100%" }}>
      <Input
        className={props.errorMessage && !props.drugSearchText ? "error" : ""}
        disabled={props.disabled}
        loading={props.drugSearchLoading}
        value={props.drugSearchText}
        fluid
        // callback
        onChange={handleChange}
        // onFocus={() => {
        //   document.removeEventListener("click", handleDocumentClick);
        //   document.addEventListener("click", handleDocumentClick);
        // }}
      />
      <div
        style={{
          display: showTable ? "block" : "none",
          height: "400px",
          position: "absolute",
          width: "80%",
          zIndex: 100,
        }}
      >
        <Table
          id="tb-medSearch"
          data={drugSearchResult}
          headers={"Code,Drug Name,Ingredient, Dosage form,หน่วยขาย"}
          keys={"code,drug_name_text,drug_ingredients,dosage_form_name,stock_unit_name"}
          minRows={8}
          showPagination={false}
          style={styles.table}
          getTrProps={handleDrugSearchProps}
        />
      </div>
    </div>
  );
};

DrugSearchBox.displayName = "DrugSearchBox";

export default React.memo(DrugSearchBox);
