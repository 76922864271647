import WasmController from "react-lib/frameworks/WasmController";

// APIs
// REG
import PatientList from "issara-sdk/apis/PatientList_apps_REG";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
import PatientQuickList from "issara-sdk/apis/PatientQuickList_apps_REG";
import VerifyProfile from "issara-sdk/apis/VerifyProfile_apps_REG";
import PatientOldNameList from "issara-sdk/apis/PatientOldNameList_apps_REG";
// PRX
import ProxyPatientHasDivisionChatDetail from "issara-sdk/apis/ProxyPatientHasDivisionChatDetail_apps_PRX";
import StaffDirectPatientInfoView from "issara-sdk/apis/StaffDirectPatientInfoView_apps_PRX";
// CORE
import SecretPatientList from "issara-sdk/apis/SecretPatientList_core";
import EncounterList from "issara-sdk/apis/EncounterList_core";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import ReceiptDetail from "issara-sdk/apis/ReceiptDetail_apps_BILM";
import InvoiceDetail from "issara-sdk/apis/InvoiceDetail_apps_BIL";
import ClinicalTerm from "issara-sdk/apis/ClinicalTermList_core";
import CostCenterIncomePerReceipt from "issara-sdk/apis/CostCenterIncomePerReceipt_apps_INF";

// Common
import * as CAgent from "react-lib/apps/common/CAgent";
import { CreateApiRequests } from "../../common/CardPatientListInterface";

import axios from "axios";

// Config
import CONFIG from "config/config";

export type State = {
  selectedPatient?: any;
  masterOptions?: any;
  SetPatientSequence?: Partial<{
    sequenceIndex: string | null;
    searchText: string | null;
    patientList: any[] | null;
    patient: any | null;
    checkCancelNickname: string | null;
    patientEmergency: any | null;
    patientEmergencyList: any | null;
    patientOldName: any;
    patientOldNameList: any[] | null;
    patientListTotal: number;
    page: number;
    limit: number;
    searchType: "auto" | "patient" | "encounterID" | "encounterOPD" | "receipt" | "invoice";
    searchScan: string;
    searchScanData: any;
    nicknameActiveOption?: any;
  }> | null;
  chatDetail?: any;
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any;
  CreateUpdateEncounterSequence?: any;
  selectedRecordViewIndex?: any;
  goToMenu?: any;
};

export const StateInitial: State = {
  SetPatientSequence: null,
  successMessage: null,
  errorMessage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any }
  | { message: "SelectEncounter"; params: any }
  | { message: "HandleSelect"; params: any }
  | { message: "HandleGetPatientOldName"; params: any };

export type Data = {
  division?: number;
  device?: number;
  masterData?: any;
};

export const DataInitial = {};

export const HOME_ADDRESS = {
  owner_name: "",
  relative: "",
  no: "",
  town: "",
  street: "",
  road: "",
  type: null,
  city: null,
  district: null,
  province: null,
  country: null,
  tel_home: "",
  tel_home_suffix: "",
  tel_office: "",
  tel_office_suffix: "",
  tel_mobile: "",
  email: "",
  channel: "",
  note: "",
  name: "",
};

const PRESENT_ADDRESS = {
  ...HOME_ADDRESS,
};

const RELATIVE_ADDRESS = {
  ...HOME_ADDRESS,
};

export const REG_SEARCH_LIMIT = 40;

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const GetMaster: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.SetPatientSequence) return;

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["doctor", {}],
        ["belief", {}],
        ["bloodType", {}],
        ["career", {}],
        ["city", {}],
        ["contact", {}],
        ["country", {}],
        ["district", {}],
        ["education", {}],
        ["gender", {}],
        ["homeType", {}],
        ["infoChannel", {}],
        ["nationality", {}],
        ["position", {}],
        ["province", {}],
        ["race", {}],
        ["religion", {}],
        ["nicknameActive", {}],
        ["nickname", {}],
        ["nicknamereason", {}],
        ["relative", {}],
      ],
    },
  });

  const [nicknameActiveRes, nicknameActiveErr, nicknameActiveNet] = await ClinicalTerm.list({
    params: { type: "g" },
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });

  controller.setState(
    {
      SetPatientSequence: {
        ...state.SetPatientSequence,
        searchType: "auto",
        sequenceIndex: "SearchOrNew",
        nicknameActiveOption: nicknameActiveRes?.items,
      },
    },
    () => {
      if (params.action) {
        SearchOrNew(controller, params);
      }
    }
  );
};

export const SearchOrNew: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.SetPatientSequence) return;
  // Reset selectedPatient

  if (params.action === "new") {
    HandleNew(controller, params);
  } else if (params.action === "search") {
    HandleSearch(controller, params);
  } else if (params.action === "smartCard") {
    HandleSmartCard(controller, params);
  } else if (
    params.action === "select" &&
    params.patient?.id &&
    Number.isInteger(params.patient.id)
  ) {
    HandleSelect(controller, params);
  } else if (params.action === "register_emergency") {
    HandleRegisterEmergency(controller, params);
  } else if (params.action === "patient_emergency") {
    HandlePatientEmergency(controller, params);
  } else if (params?.action === "clear_emergency") {
    HandleClearEmergency(controller, params);
  } else if (params.action === "oldname_list" && params.patient.id) {
    HandleGetPatientOldName(controller, params);
  } else if (params?.action === "searchScan") {
    HandleSearchScan(controller, params);
  }
};

export const EditPatientInfo: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.SetPatientSequence) return;

  if (params.action === "backwithKeptSearchText") {
    HandleBackwithKeptSearchText(controller, params);
  } else if (params.action === "save" && state.SetPatientSequence?.patient?.id === null) {
    HandleCreatePatient(controller, params);
  } else if (params?.action === "save" && state.SetPatientSequence?.patient?.id) {
    HandleUpdatePatient(controller, params);
  } else if (params.action === "clear") {
    HandleClear(controller, params);
  } else if (params.action === "secret") {
    HandleSecret(controller, params);
  } else if (params.action === "patient_emergency") {
    HandlePatientEmergencyEditPatient(controller, params);
  } else if (params.action === "clear_emergency") {
    HandleClearEmergency(controller, params);
  } else if (params.action === "verified") {
    HandleVerified(controller, params);
  }
};

/* ------------------------------------------------------ */

/*                      SearchOrNew;                      */

/* ------------------------------------------------------ */
const HandleNew: Handler = (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["prenameEn", {}],
        ["prenameTh", {}],
      ],
    },
  });

  controller.setState({
    SetPatientSequence: {
      ...state.SetPatientSequence,
      searchText: "",
      patientList: [],
      patient: {
        id: null,
        first_name: "",
        last_name: "",
        info_channel: "",
        pre_name_th: "",
        first_name_th: "",
        last_name_th: "",
        pre_name_en: "",
        first_name_en: "",
        last_name_en: "",
        middle_name_en: "",
        gender: "",
        blood_type: "",
        birthdate: "",
        nationality: "",
        father: "",
        mother: "",
        citizen_type: "",
        citizen_no: "",
        passport_no: "",
        alien_no: "",
        id_number: "",
        relative_address: {
          owner_name: "",
          tel_mobile: "",
          relative: "",
        },
        nickname_active: false,
        ...(params.patient || {}),
      },
      sequenceIndex: "EditPatientInfo",
    },
    selectedRecordViewIndex: 0,
  });
};

const HandleSearch: Handler = async (controller, params) => {
  const state = controller.getState();

  const btnKey = `${params.card}_${params.action}`;

  controller.setState({
    SetPatientSequence: {
      ...state.SetPatientSequence,
      patientList: null,
      patientListTotal: 0,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  let patientName: string = "";
  let patientHn: string = "";

  // #if (!isNaN(params?.searchText)) {
  if (/\d/.test(params.searchText)) {
    patientHn = params.searchText?.trim();
  } else {
    patientName = params?.searchText?.trim();
  }

  let page = params.page || 1;
  let limit = params.limit ? params?.limit : 40;
  let offset = (page - 1) * (params.limit ? params.limit : 40);

  const patient = await GetPatientList(controller, {
    ...(patientName && { name_search: patientName }),
    ...(patientHn && { hn: patientHn }),
    citizen_passport: params.citizenText || undefined,
    limit: limit,
    offset: offset,
  });

  controller.setState({
    SetPatientSequence: {
      ...state.SetPatientSequence,
      patientList: [...(patient[0]?.items || [])],
      patientListTotal: patient[0]?.total || 0,
      page: params.page,
      limit: params.limit,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
  });

  params.onSuccess?.();

  return patient;
};

const HandleSmartCard: Handler = async (controller, params) => {
  const state = controller.getState();

  const btnKey = `${params.card}_${params.action}`;

  const handleLoading = async () => {
    controller.setState({
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patientList: null,
        patientListTotal: 0,
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
    });

    await controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["prenameEn", {}],
          ["prenameTh", {}],
        ],
      },
    });
  };

  CAgent.smartcard(
    async (result: any) => {
      await handleLoading();

      HandleSetPatientSmartCard(controller, { ...result, btnKey });
    },
    async (error: any) => {
      // const res = await axios.get("/static/smartcard.json");

      // await handleLoading();

      // HandleSetPatientSmartCard(controller, { ...res.data, btnKey });

      controller.setState({
        errorMessage: { ...state.errorMessage, [btnKey]: { error } },
      });
    }
  );
};

const HandleSetPatientSmartCard: Handler = async (controller, params) => {
  const state = controller.getState();

  const [patient] = await HandleSearch(controller, {
    citizenText: params.citizen_id,
    page: 1,
    limit: REG_SEARCH_LIMIT,
  });

  if (!patient?.items?.length) {
    const prenameThOptions = state.masterOptions?.prenameTh || [];
    const prenameEnOptions = state.masterOptions?.prenameEn || [];

    const prenameTh =
      prenameThOptions.find((item: any) => item.text === params.th_prefix)?.value || null;
    const prenameEn =
      prenameEnOptions.find((item: any) => item.text === params.en_prefix)?.value || null;

    let gender = "";

    if (params["sex"] === "1") {
      gender = "M";
    } else {
      gender = "F";
    }

    const patient = {
      info_channel: "HOS",
      pre_name_th: prenameTh,
      first_name_th: params.th_firstname,
      last_name_th: params.th_lastname,
      pre_name_en: prenameEn,
      first_name_en: params.en_firstname,
      last_name_en: params.en_lastname,
      gender: gender,
      birthdate: params.birth_date,
      citizen_type: "T",
      citizen_no: params.citizen_id,
      profile_image: {
        image: params.picture_base64,
        image_type: "P",
      },
    };

    return controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.btnKey]: { error: "NOT_FOUND", data: patient },
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnKey]: null },
    });
  }

  controller.setState({
    SetPatientSequence: {
      ...state.SetPatientSequence,
      patientList: patient?.items || [],
      patientListTotal: patient?.total || 0,
      page: 1,
      limit: REG_SEARCH_LIMIT,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnKey]: null },
  });
};

export const HandleGetPatientOldName: Handler = async (controller, params) => {
  if (!params.patient.id) {
    return;
  }
  const state = controller.getState();
  const [data, error] = await PatientOldNameList.list({
    apiToken: controller.apiToken,
    patient_id: params.patient.id,
  });

  controller.setState({
    // selectedPatient: { ...state.selectedPatient, patientOldNameList: data?.items, },

    SetPatientSequence: {
      ...state.SetPatientSequence,
      patientOldNameList: data?.items,
      patient: {
        ...params.patient,
        patientOldNameList: data?.items,
      },
    },
  });
};

export const HandleSelect: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["prenameEn", {}],
        ["prenameTh", {}],
      ],
    },
  });

  if (!params.noClear) {
    controller.setState({
      selectedPatient: null,
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patient: null,
      },
    });
  }

  const selectPatientApis = {
    chatDetail: {
      api: ProxyPatientHasDivisionChatDetail.retrieve,
      payload: {
        apiToken: controller.apiToken,
        params: {
          force_create: true,
        },
        pk: params.patient.id,
        extra: { division: controller.data.division },
      },
    },
    patientDetail: {
      api: PatientDetailView.retrieve,
      payload: {
        apiToken: controller.apiToken,
        pk: params.patient.id,
      },
    },
  };

  const promises = CreateApiRequests(controller as any, {
    apis: selectPatientApis,
    key: "selectPatientApis",
  });

  const responses = await Promise.all(promises);

  const [chatDetail, [patientDetail]] = responses;

  // * มีการ Abort request
  const isAbort = responses.some((res) => res[1] === "canceled");

  if (isAbort) {
    return;
  }

  if (CONFIG.PATIENT_IMAGE_URL && patientDetail) {
    patientDetail.profile_image = null;

    HandleGetProfileImage(controller, { hn: patientDetail?.hn });
  }

  if (patientDetail) {
    const patientOldName = {
      pre_name_th_old: patientDetail?.pre_name_th,
      first_name_th_old: patientDetail?.first_name_th,
      middle_name_th_old: patientDetail?.middle_name_th,
      last_name_th_old: patientDetail?.last_name_th,

      pre_name_en_old: patientDetail?.pre_name_en,
      first_name_en_old: patientDetail?.first_name_en,
      middle_name_en_old: patientDetail?.middle_name_en,
      last_name_en_old: patientDetail?.last_name_en,

      citizenType: patientDetail?.citizen_type,
      citizenNo: patientDetail?.citizen_no,
      passportNo: patientDetail?.passport_no,
      alienNo: patientDetail?.alien_no,
    };

    const patient = {
      ...patientDetail,
      home_address: patientDetail.home_address ? patientDetail.home_address : {},
      present_address: patientDetail.present_address ? patientDetail.present_address : {},
      relative_address: patientDetail.relative_address ? patientDetail.relative_address : {},
      profile_status: patientDetail?.profile_status,
    };

    controller.setState(
      {
        SetPatientSequence: {
          ...state.SetPatientSequence,
          sequenceIndex: "EditPatientInfo",
          patient: patient,
          patientOldName: patientOldName,
          searchScanData: null,
        },
        selectedPatient: patientDetail,
        chatDetail: chatDetail[0],
        selectedRecordViewIndex: params.noClear ? undefined : 0,
        goToMenu: params.goToMenu,
      },
      () => {
        controller.handleEvent({
          message: "HandleGetPurchaseOrderList",
          params: {},
        } as any);
      }
    );

    if (CONFIG.SHOW_PATIENT_INFO_FROM_BDMS) {
      HandleGetProfileInfo(controller as any, {
        hn: patientDetail?.hn,
        patient_id: patientDetail?.patient_id,
      });
    }
  }
};

const HandleRegisterEmergency: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  const list = params.patient || [];
  const masterData = controller.data?.masterData;

  for (const [i, item] of (params.patient || []).entries()) {
    let gender: string = "";

    if (item.gender === "หญิง") {
      gender = "F";
    } else if (item.gender === "ชาย") {
      gender = "M";
    }

    const data = {
      first_name_th: item.first_name_th || "",
      gender: gender,
      last_name_th: item.last_name_th || "",
      pre_name: masterData?.prenameTh?.filter((acc: any) => acc.name === item.pre_name)?.[0]?.id,
      nationality: masterData?.nationality?.filter((acc: any) => acc.name === "ไทย")?.[0]?.id,
    };

    const patient = await CreatePatientQuick(controller, { data });

    if (patient[1]) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
    }

    list[i] = {
      ...patient[0],
      pre_name: list[i].pre_name,
      gender: list[i].gender,
    };
  }

  controller.setState({
    SetPatientSequence: {
      ...state.SetPatientSequence,
      patientEmergencyList: list,
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "SUCCESS",
    },
  });

  HandleSearch(controller, params);
};

const HandlePatientEmergency: Handler = async (controller, params) => {
  const state = controller.getState();

  const patientEmergency = state.SetPatientSequence?.patientEmergency;
  const masterData = controller.data?.masterData;

  const data = {
    first_name_th: patientEmergency?.first_name || "",
    gender: patientEmergency?.gender || "",
    last_name_th: patientEmergency?.last_name || "",
    nationality: masterData?.nationality?.filter((item: any) => item.name === "ไทย")?.[0]?.id,
  };

  const [r, e] = await CreatePatientQuick(controller, { data });

  if (e) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: e },
      },
    });
  } else {
    controller.setState({
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patient: r,
      },
      successMessage: { ...state.successMessage, [params.card]: r },
    });

    SearchOrNew(controller, {
      action: "select",
      patient: r,
      goToMenu: "เปิด Encounter ใหม่",
    });

    params.forward?.();
  }
};

const HandleClearEmergency: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    SetPatientSequence: {
      ...state.SetPatientSequence,
      patientEmergency: { first_name: "", last_name: "", gender: "" },
    },
  });
};

const HandleSearchScan: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  let searchScanApi = {
    patient: PatientDetailView.retrieve,
    encounterID: EncounterDetail.retrieve,
    encounterOPD: EncounterDetail.retrieve,
    receipt: CostCenterIncomePerReceipt.get,
    invoice: InvoiceDetail.retrieve,
  };

  let searchScanType = ["patient", "encounterID", "encounterOPD", "receipt", "invoice"];

  let searchScanData;
  if (state.SetPatientSequence?.searchType === "auto") {
    let promiseArr = searchScanType?.map((type: any) => {
      return searchScanApi[type]({
        pk: state.SetPatientSequence?.searchScan,
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
        ...(type === "receipt" && {
          params: {
            code: state.SetPatientSequence?.searchScan,
          },
        }),
      });
    });

    const response = await Promise.all(promiseArr);

    let patientSearch = response?.filter((item: any) => item?.[0] !== null);

    searchScanData = patientSearch?.[0]?.[0];
  } else if (state.SetPatientSequence?.searchType) {
    const patientSearch = await searchScanApi[state.SetPatientSequence?.searchType]({
      pk: state.SetPatientSequence?.searchScan,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
      ...(state.SetPatientSequence?.searchType === "receipt" && {
        params: {
          code: state.SetPatientSequence?.searchScan,
        },
      }),
    });

    searchScanData = patientSearch?.[0];
  }

  if (searchScanData) {
    let patientHN = "";
    if (searchScanData?.hn) {
      patientHN = searchScanData?.hn;
    } else if (searchScanData?.patient_id) {
      const patientDetail = await PatientDetailView.retrieve({
        pk: searchScanData?.patient_id,
        apiToken: controller.apiToken,
      });

      patientHN = patientDetail?.[0]?.hn;
    }

    const patientDetail = await PatientList.list({
      params: { hn: patientHN, org_div: controller?.data?.division || undefined },
      apiToken: controller.apiToken,
      ...(controller.data?.division != null && {
        extra: { division: controller?.data?.division },
      }),
    });

    if (patientDetail?.[0]) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        SetPatientSequence: {
          ...state.SetPatientSequence,
          searchScanData: patientDetail?.[0]?.items?.[0],
          searchType: "auto",
          searchScan: "",
        },
      });
    }
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.buttonLoadKey]: "ERROR",
      },
    });
  }
};

/* ------------------------------------------------------ */

/*                    EditPatientInfo;                    */

/* ------------------------------------------------------ */
const HandleBackwithKeptSearchText: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    SetPatientSequence: {
      ...state.SetPatientSequence,
      searchText: state.SetPatientSequence?.searchText,
      patientList: state.SetPatientSequence?.patientList,
      sequenceIndex: "SearchOrNew",
    },
  });
};

const HandleCreatePatient: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });
  // New Patient
  const idKey = (citizen_type: string) => {
    const key: string =
      {
        T: "citizen_no",
        F: "passport_no",
        A: "alien_no",
        "": "",
      }?.[citizen_type as string] || "";
    return key;
  };

  const patient = state.SetPatientSequence?.patient;
  const masterData = controller?.data?.masterData;

  const patientDetail = await PatientList.create({
    data: {
      birthdate: patient?.birthdate,
      birthdate_year_only: false,
      citizen_type: patient?.citizen_type,
      citizen_no: patient?.[idKey(patient?.citizen_type === "T" ? patient?.citizen_type : "")],
      citizen_alien: patient?.[idKey(patient?.citizen_type === "A" ? patient?.citizen_type : "")],
      citizen_passport:
        patient?.[idKey(patient?.citizen_type === "F" ? patient?.citizen_type : "")],
      pre_name_th: patient?.pre_name_th || "",
      pre_name_en: patient?.pre_name_en || null,
      first_name_th: patient?.first_name_th || "",
      last_name_th: patient?.last_name_th || "",
      nickname: patient?.nickname || "",
      nickname_active: patient?.nickname_active || false,
      nickname_permit_no: patient?.nickname_permit_no || "",
      nickname_reason: patient?.nickname_reason || "",
      nickname_warrantor: patient?.nickname_warrantor || "",
      nickname_first_name:
        masterData?.nickname?.filter((acc: any) => acc.id === patient?.nickname)?.[0]?.first_name ||
        "",
      nickname_last_name:
        masterData?.nickname?.filter((acc: any) => acc.id === patient?.nickname)?.[0]?.last_name ||
        "",
      name:
        masterData?.nickname?.filter((acc: any) => acc.id === patient?.nickname)?.[0]?.full_name ||
        "",
      profile_image: patient.profile_image || null,
      confirmSave: params.confirmSave || false,
      gender: patient?.gender,
      info_channel: patient?.info_channel || "",
      // is_secret: patient?.is_secret,
      id_number: patient?.[idKey(patient?.citizen_type === "T" ? patient?.citizen_type : "")],
      alien_no: patient?.[idKey(patient?.citizen_type === "A" ? patient?.citizen_type : "")],
      passport_no: patient?.[idKey(patient?.citizen_type === "F" ? patient?.citizen_type : "")],
      reimburse_foreign_code: patient?.reimburse_foreign_code || "",
      non_resident: patient?.non_resident || false,
      middle_name_th: patient?.middle_name_th || "",
      first_name_en: patient?.first_name_en || "",
      last_name_en: patient?.last_name_en || "",
      middle_name_en: patient?.middle_name_en || "",
      blood_type: patient?.blood_type || "",
      allergy: patient?.allergy || "",
      religion_code: patient?.religion_code || "",
      birth_country: patient?.birth_country || "",
      birth_province: patient?.birth_province || "",
      nationality: patient?.nationality || "",
      race: patient?.race || "",
      belief: patient?.belief || "",
      career: patient?.career || "",
      position: patient?.position || "",
      education: patient?.education || "",
      marriage: patient?.marriage || "",
      couple: patient?.couple || "",
      father: patient?.father || "",
      mother: patient?.mother || "",
      relative_address: {
        owner_name: patient?.relative_address?.owner_name || "",
        tel_mobile: patient?.relative_address?.tel_mobile || "",
        relative: patient?.relative_address?.relative || "",
      },
    },
    apiToken: controller.apiToken,
  });

  if (patientDetail[0]) {
    // #await SaveSecretPatient(controller, {
    //   active: state?.SetPatientSequence?.patient?.is_secret === "S",
    //   id: patientDetail[0]?.id,
    // });

    controller.setState({
      selectedPatient: patientDetail[0],
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patient: {
          ...patientDetail[0],
          home_address: {},
          present_address: {},
        },
      },
      successMessage: {
        ...state.successMessage,
        [params?.sequence]: patientDetail[0],
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
    });

    SearchOrNew(controller, {
      action: "select",
      patient: state.SetPatientSequence?.patient,
    });
  } else {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.sequence]: patientDetail[1],
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
    });
  }
};

const HandleUpdatePatient: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  const patient = state.SetPatientSequence?.patient || {};

  // Existing patient
  const { id, created, edited, ...patientDetail } = patient;

  const home_address = Object.fromEntries(
    Object.entries(patient?.home_address || {}).filter(
      (item: any[]) => !["created", "edited"].includes(item[0])
    )
  );
  const present_address = Object.fromEntries(
    Object.entries(patient.present_address || {}).filter(
      (item: any[]) => !["created", "edited"].includes(item[0])
    )
  );
  const relative_address = Object.fromEntries(
    Object.entries(patient.relative_address || {}).filter(
      (item: any[]) => !["created", "edited"].includes(item[0])
    )
  );

  const patientDetailUpdate = { ...patient };

  delete patientDetailUpdate["profile_status"];
  delete patientDetailUpdate["created"];
  delete patientDetailUpdate["edited"];

  console.log("patientDetailUpdate: ", patientDetailUpdate);

  // let csplit = patientDetailUpdate?.created?.split(" ")

  let disable_nickname_reason =
    state.SetPatientSequence?.nicknameActiveOption?.find(
      (item: any) => item?.name === state.SetPatientSequence?.checkCancelNickname
    )?.id || "";

  const [patientRes] = await PatientDetailView.update({
    pk: id,
    data: {
      ...patientDetailUpdate,
      // created: moment(
      //   patientDetailUpdate?.created,
      //   "DD/MM/YYYY [hh:mm]"
      // ).format("DD/MM/YYYY-hh:mm"),
      // edited: moment(patientDetailUpdate?.edited, "DD/MM/YYYY [HH:mm]").format(
      //   "DD/MM/YYYY-hh:mm"
      // ),
      id: id,
      confirmSave: true,
      home_address: home_address,
      present_address: present_address,
      relative_address: relative_address,
      document_address: {},
      foreign_address: {},
      change_name_reason: "เปลี่ยนชื่อ",
      change_name_ref_no: state.SetPatientSequence?.patient.change_name_ref_no,
      sequence: 0, // what for ?,
      ...(patientDetail?.profile_image?.image?.includes("http") ? { profile_image: null } : {}),
      ...(!state.SetPatientSequence?.patient?.nickname_active && {
        nickname: null,
        nickname_active: false,
        nickname_permit_no: "",
        nickname_reason: null,
        nickname_warrantor: null,
        nickname_first_name: null,
        nickname_last_name: null,
        name: null,
        disable_nickname_reason: String(disable_nickname_reason),
      }),
    },
    apiToken: controller.apiToken,
  });

  if (patientRes) {
    // #await SaveSecretPatient(controller, {
    //   active: patientDetail?.is_secret === "S",
    //   id: state.SetPatientSequence.patient?.id,
    // });

    controller.setState({
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patient: {
          ...patientRes,
          profile_status: patientRes?.profile_status,
        },
      },
      successMessage: {
        ...state.successMessage,
        [params?.sequence]: patientRes,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
    });

    SearchOrNew(controller, {
      action: "search",
      searchText: state.SetPatientSequence?.searchText,
      page: state.SetPatientSequence?.page,
      limit: state.SetPatientSequence?.limit,
      card: "CardRegSearch",
    });

    SearchOrNew(controller, {
      action: "select",
      patient: patient,
      noClear: true,
    });
  } else {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params?.sequence]: patient[1] },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
    });
  }
};

const HandleClear: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    successMessage: { ...state.successMessage, [params?.sequence]: null },
    errorMessage: { ...state.errorMessage, [params?.sequence]: null },
  });
};

const HandleSecret: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  const [r, e, n] = await SaveSecretPatient(controller, {
    active: params.active,
    id: state.SetPatientSequence?.patient?.id,
  });

  if (e) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: e },
      },
    });
  } else {
    // #await SearchOrNew(controller, {
    //   action: "select",
    //   patient: state?.selectedPatient?.patient_id,
    // });

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
    });
  }
};

const HandlePatientEmergencyEditPatient: Handler = async (controller, params) => {
  const state = controller.getState();
  const patientEmergency = state.SetPatientSequence?.patientEmergency;
  const masterData = controller.data?.masterData;

  const data = {
    first_name_th: patientEmergency?.first_name || "",
    gender: patientEmergency?.gender || "",
    last_name_th: patientEmergency?.last_name || "",
    nationality: masterData?.nationality?.filter((item: any) => item.name === "ไทย")?.[0]?.id,
  };

  const [r, e] = await CreatePatientQuick(controller, { data });

  if (e) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: e },
      },
    });
  } else {
    controller.setState({
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patient: r,
      },
      successMessage: { ...state.successMessage, [params?.card]: r },
    });

    HandleCreateEncounter(controller, { ...params, patient: r.id });
  }
};

const HandleCreateEncounter: Handler = async (controller, params) => {
  const state = controller.getState();

  const encounter = await EncounterList.create({
    data: {
      action: "OPD_NEW",
      chief_complaints: "",
      division: controller.data?.masterData?.division?.filter(
        (item: any) => item.code === "CNER"
      )?.[0]?.id,
      doctor: controller.data?.masterData?.doctor?.filter((item: any) => item.code === "OR")?.[0]
        ?.id,
      location: "-",
      note: "",
      patient: params.patient,
      patient_case: "",
      remark: "",
      type: "OPD",
      underlying_disease: "",
      zone: "",
    },
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });

  controller.setState({
    CreateUpdateEncounterSequence: {
      ...state.CreateUpdateEncounterSequence,
      encounterList: [encounter?.[0]],
    },
  });
};

const HandleVerified: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  const [r, e, n] = await VerifyProfile.put({
    patient_id: state.SetPatientSequence?.patient?.id,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });

  if (e) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "ERROR",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
      SetPatientSequence: {
        ...state.SetPatientSequence,
        patient: {
          ...state.SetPatientSequence?.patient,
          profile_status: "VERIFIED",
        },
      },
    });

    SearchOrNew(controller, {
      action: "search",
      searchText: state.SetPatientSequence?.searchText,
      page: state.SetPatientSequence?.page,
      limit: state.SetPatientSequence?.limit,
      card: "CardRegSearch",
    });
  }
};

export const HandleGetProfileImage: Handler = (controller, params) => {
  // เมื่อมี hn เท่านั้น
  if (!params.hn) {
    return;
  }

  // get hospital จาก 01-17-051095 โดยเอาจาก เลข 2 ตัวหน้า
  const hospital = params.hn.search(/^\d+-\d+-\d+$/g) >= 0 ? params.hn.split("-")?.[0] : "";

  axios
    .post(
      `${CONFIG.PATIENT_IMAGE_URL}/${hospital ? `${hospital}/` : ""}${params.hn}`,
      {
        // Hard code
        programid: "VD004",
        restuser: "glsict",
        restpass: "Glsp@ti3nt",
        hn: params.hn,
      },
      {
        // Hard code
        headers: {
          accesstoken: CONFIG.PATIENT_IMAGE_URL_TOKEN,
          programid: "VD004",
          "Content-Type": "application/json",
        },
        // มีเคส 504 จึง settimeout ไว้ เพื่อไม่ให้รอ response นานเกินไป
        timeout: 3 * 1000,
      }
    )
    .then((response) => {
      const state = controller.getState();
      const params = JSON.parse(response.config.data || "{}");

      // เนื่องจากมีการ delay ตอน getImage จึง check hn เพื่อให้ set ข้อมูลได้ถูกต้อง
      if (state.selectedPatient?.hn === params.hn) {
        const image = response.data.length
          ? {
              image: `data:image/png;base64,${response.data[0].Image}`,
              image_type: "P",
            }
          : null;

        controller.setState({
          selectedPatient: { ...state.selectedPatient, profile_image: image },
        });

        const hn = state.SetPatientSequence?.patient?.hn;

        if (state.SetPatientSequence && hn === params.hn) {
          controller.setState({
            SetPatientSequence: {
              ...state.SetPatientSequence,
              patient: {
                ...state.SetPatientSequence.patient,
                profile_image: image,
              },
            },
          });
        }
      }
    })
    .catch((err) => {
      console.log("getImage: error", err);
    });
};

export const HandleGetProfileInfo: Handler = async (controller, params) => {
  const [res] = await StaffDirectPatientInfoView.get({
    apiToken: controller.apiToken,
    params: { patient: params.patient_id },
  });

  const state = controller.getState();
  if (res[0]) {
    if (state.selectedPatient?.hn === params.hn) {
      // setState patientLanguage เพิ่ม field ใน selectedPatient
      // setState present_address ทับ field เดิม selectedPatient
      controller.setState({
        selectedPatient: {
          ...state.selectedPatient,
          patientLanguage: res[0]?.PrefLanguage || "",
          present_address: {
            ...state.selectedPatient?.present_address,
            tel_mobile: res[0]?.MobileNumber || "",
          },
        },
      });

      const hn = state.SetPatientSequence?.patient?.hn;

      if (state.SetPatientSequence && hn === params.hn) {
        // setState patientLanguage เพิ่ม field ใน SetPatientSequence.patient
        // setState present_address ทับ field เดิม SetPatientSequence.patient
        controller.setState({
          SetPatientSequence: {
            ...state.SetPatientSequence,
            patient: {
              ...state.SetPatientSequence.patient,
              patientLanguage: res[0]?.PrefLanguage || "",
              present_address: {
                ...state.SetPatientSequence?.patient?.present_address,
                tel_mobile: res[0]?.MobileNumber || "",
              },
            },
          },
        });
      }
    }
  }
};

/* ------------------------------------------------------ */

/*                          APIS                          */

/* ------------------------------------------------------ */
const CreatePatientQuick: Handler = async (controller, params) => {
  const data = {
    citizen_passport: "",
    citizen_type: "N",
    first_name_en: "",
    hn: "",
    last_name_en: "",
    middle_name_en: "",
    middle_name_th: "",
    nickname_active: false,
    home_address: HOME_ADDRESS,
    present_address: PRESENT_ADDRESS,
    relative_address: RELATIVE_ADDRESS,
    ...params.data,
  };

  const patient = await PatientQuickList.create({
    data: data,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });

  return patient;
};

const SaveSecretPatient: Handler = async (controller, params) => {
  return SecretPatientList.post({
    data: {
      active: params.active,
      patient: params.id,
    },
    apiToken: controller.apiToken,
  });
};

const GetPatientList: Handler = async (controller, params) => {
  return PatientList.list({
    params: { ...params, org_div: controller?.data?.division || undefined },

    apiToken: controller.apiToken,
    ...(controller.data?.division != null && {
      extra: { division: controller?.data?.division },
    }),
  });
};
