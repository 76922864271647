import { CSSProperties, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";

import { Button, Checkbox, Icon, Label, Modal, Segment, SemanticCOLORS } from "semantic-ui-react";

import moment from "moment";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModAuthen from "react-lib/apps/common/ModAuthen";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ModInfo from "react-lib/apps/common/ModInfo";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// UX
import ButtonLockExpense from "./ButtonLockExpense";
import CardBillingDocumentUX from "./CardBillingDocumentUX";
import CardFaxClaimUX from "./CardFaxClaimUX";
import CardPaying from "./CardPaying";
import CardRefundUX from "./CardRefundUX";
import ModLinkPackage from "./ModLinkPackage";
import ModCancelExpense from "../DFC/ModCancelExpense";

// Utils
import { formatDate, formatDatetime } from "react-lib/utils/dateUtils";

// config
import CONFIG from "config/config";
import { useIntl } from "react-intl";

type CardMedicalFeeProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;
  // seq
  BillPaymentSequence?: Record<string, any> | null;
  // data
  billingPatientLanguage?: any;
  billPendingDetail?: Record<string, any>;
  billReportPrint?: Record<string, any>;
  bilReceiptCodeDetail?: Record<string, any>;
  faxClaimItemList?: Record<string, any>;
  hidebp?: boolean;
  hidebpInvoice?: boolean;
  historyInvoiceData?: any;
  invoiceHistoryItems?: Record<string, any>[];
  invoiceId?: number;
  invoiceItemByItems?: any[];
  invoiceItemByModes?: Record<string, any>[];
  invoiceItemByOrders?: Record<string, any>[];
  invoiceItemPackageList?: Record<string, any>[];
  medicalFeeFilter?: Record<string, any>;
  openPaying?: boolean;
  orderBy?: string;
  patientEncounterLockerStatus?: Record<string, any>[];
  patientInfo?: Record<string, any>;
  patientOrders?: Record<string, any>[];
  receiptId?: number;
  refundOrders?: Record<string, any>;
  showAuthorize?: boolean;
  showFilter?: boolean;
  simpleReceipt?: boolean;
  taskTrackingList?: Record<string, any>;
  viewMode?: boolean;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  selectedEncounter?: Record<string, any>;
  masterOptions?: Record<string, any[]>;
  // callback
  onSuccess?: () => any;
};

type Styles = Record<"alignRight" | "margin" | "message", CSSProperties>;

// Const
export const styles: Styles = {
  alignRight: { textAlign: "right" },
  margin: { margin: "0 10px 5px" },
  message: {
    backgroundColor: "#E4A210",
    borderRadius: "5px",
    color: "#FFFFFF",
    fontSize: "22px",
    fontWeight: "bold",
    padding: "10px",
  },
};

export const CARD_MEDICAL_FEE = "CardMedicalFee";

const CardMedicalFee = (props: CardMedicalFeeProps) => {
  const intl = useIntl();
  const [orderBy, setOrderBy] = useState<string>("Item");
  const [isPendingSomeOrder, setIsPendingSomeOrder] = useState<boolean>(false);
  const [invoiceItems, setInvoiceItems] = useState<any[]>([]);
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [priceRefund, setPriceRefund] = useState<any>(0);
  const [receiptRefund, setReceiptRefund] = useState<string>("");
  const [tracking, setTracking] = useState<boolean>(false);

  const [modCancelInvoiceItem, setModCancelInvoiceItem] = useState<number | null>(null);

  const [price, setPrice] = useState<any>({
    absorb: 0,
    discount: 0,
    non_claimable: 0,
    patient_claim: 0,
    payable: 0,
    price: 0,
    send_claim: 0,
  });

  // mod
  const [openInvoice, setOpenInvoice] = useState<boolean>(false);
  const [openRefund, setOpenRefund] = useState<boolean>(false);
  const [openTransfer, setOpenTransfer] = useState<boolean>(false);
  const [openModAuthen, setOpenModAuthen] = useState<boolean>(false);
  const [openModFaxClaim, setOpenModFaxClaim] = useState<boolean>(false);
  const [modLinkPackage, setModLinkPackage] = useState<any>(null);

  // Effect Callback
  const getInvoiceItem = useCallback((params: any) => {
    props.onEvent({ message: "GetInvoiceItemByMode", params });

    props.onEvent({ message: "GetInvoiceItemByOrder", params });

    props.onEvent({ message: "GetInvoiceItemByItem", params });
  }, []);

  useEffect(() => {
    if (props.viewMode || props.openPaying || !props.patientInfo?.id) {
      return;
    }

    props.onEvent({
      message: "HandleInitMedicalFeeFilter",
      params: { showFilter: props.showFilter },
    });

    getInvoiceItem({ card: CARD_MEDICAL_FEE });

    props.onEvent({
      message: "GetPatientDoctorOrder",
      params: {
        card: CARD_MEDICAL_FEE,
        patientId: props.patientInfo.id,
      },
    });

    props.onEvent({
      message: "GetCreditNoteItem",
      params: {
        action: "getCreditNoteItem",
        card: CARD_MEDICAL_FEE,
        patientId: props.patientInfo.id,
      },
    });
  }, [props.patientInfo?.id]);

  useEffect(() => {
    // หากส่ง receiptId จะ get by receipt id
    if (!props.receiptId) {
      return;
    }

    getInvoiceItem({
      card: CARD_MEDICAL_FEE,
      receiptId: props.receiptId,
    });
  }, [props.receiptId]);

  useEffect(() => {
    if (props.patientInfo?.id) {
      props.onEvent({ message: "GetBillPendingDetail", params: {} });

      props.onEvent({ message: "GetPatientEncounterLockerStatus", params: {} });
    }

    return () => {
      props.setProp("billPendingDetail", null);
    };
  }, [props.patientInfo?.id]);

  useEffect(() => {
    if (
      props.refundOrders?.tracking?.task_id !== undefined &&
      props.refundOrders.tracking?.status !== "CANCELED"
    ) {
      setTracking(true);
    }
  }, [props.refundOrders?.tracking]);

  useEffect(() => {
    let invoices: any[] = [];

    if (orderBy === "Mode") {
      invoices = props.invoiceItemByModes || [];
    } else if (orderBy === "Order") {
      invoices = props.invoiceItemByOrders || [];
      setCheckedIds(invoices.flatMap((item) => item.id_list));
    } else if (orderBy === "Item") {
      const invoiceItem = props.invoiceItemByItems?.map((item) => {
        const createdYear = Number.parseInt(moment(item.created).format("YYYY")) + 543;

        return {
          ...item,
          createdDate: `${moment(item.created).format("DD/MM")}/${createdYear} ${moment(
            item.created
          ).format("HH:mm")}`,
        };
      });

      invoices = invoiceItem || [];

      setCheckedIds(invoices.map((item) => item.id));
    }

    setInvoiceItems(invoices);

    if ((props.patientOrders?.length || 0) > 0) {
      const isPending = (props.patientOrders || []).some((item) => {
        const name: string = item.name || "";

        return (
          item.is_pending &&
          (name.toLowerCase().includes("supply") || name.toLowerCase().includes("drug"))
        );
      });

      setIsPendingSomeOrder(isPending);
    } else if (props.billPendingDetail?.hasDoctorOrder) {
      setIsPendingSomeOrder(true);
    } else {
      setIsPendingSomeOrder(false);
    }
  }, [
    orderBy,
    props.billPendingDetail?.hasDoctorOrder,
    props.invoiceItemByItems,
    props.invoiceItemByModes,
    props.invoiceItemByOrders,
    props.patientOrders,
  ]);

  // open faxClaim
  useEffect(() => {
    if (props.faxClaimItemList?.items.length > 0) {
      setOpenModFaxClaim(true);
    }
  }, [props.faxClaimItemList]);

  const invoiceData = useMemo(() => {
    let invoices = invoiceItems;

    if (["Item", "Order"].includes(orderBy)) {
      const invoicesData = invoices.filter((item: any) =>
        props.viewMode ? true : checkedIds.includes(item.id)
      );

      invoices =
        orderBy === "Order"
          ? invoicesData.map((item: any) => ({
              ...item,
              chk: true,
            }))
          : invoicesData;
    }

    const idList = invoices.flatMap((item) => item.id_list || [item.id]);

    return {
      ids: idList,
      items: invoices,
      orderBy,
    };
  }, [checkedIds, invoiceItems, orderBy]);

  useEffect(() => {
    if (props.invoiceHistoryItems) {
      props.onEvent({
        message: "RunSequence",
        params: {
          sequence: "BillPayment",
          card: CARD_MEDICAL_FEE,
          invoiceData: {
            ids: props.invoiceHistoryItems.flatMap((item: any) => item.id_list || [item.id]),
            items: props.invoiceHistoryItems,
            orderBy: props.orderBy,
          },
          invoiceItemByItems: props.invoiceItemByItems,
        },
      });
    }
  }, [props.historyInvoiceData]);

  useEffect(() => {
    let price = 0;
    let discountAmount = 0;
    let payableAmount = 0;
    let sendClaimAmount = 0;
    let absorbAmount = 0;
    let patientClaimAmount = 0;
    let nonClaimableAmount = 0;
    let unpaidAmount = 0;

    const items: any[] = invoiceData.items;

    for (const item of items) {
      if (
        orderBy === "Order" &&
        item.status__name === "BILLED" &&
        !(item.payment_ready && item.chk)
      ) {
        const getParseFloat = (key: string) => Number.parseFloat(item?.[`${key}__sum`]) || 0;

        price += getParseFloat("price");
        discountAmount += getParseFloat("discount");
        payableAmount += getParseFloat("payable");
        sendClaimAmount += getParseFloat("send_claim");
        absorbAmount += getParseFloat("absorb");
        patientClaimAmount += getParseFloat("patient_claim");
        nonClaimableAmount += getParseFloat("non_claimable");
      } else if (orderBy === "Item" && item.status__name === "BILLED" && !item.payment_ready) {
        // Potential empty block statement here
      } else {
        const getParseFloat = (key: string) =>
          Number.parseFloat(item?.[orderBy === "Item" ? key : `${key}__sum`]) || 0;

        const payable = props.viewMode ? getParseFloat("paid") : getParseFloat("pay");

        price += getParseFloat("price");
        discountAmount += getParseFloat("discount");
        payableAmount += payable;
        sendClaimAmount += getParseFloat("send_claim");
        absorbAmount += getParseFloat("absorb");
        patientClaimAmount += getParseFloat("patient_claim");
        nonClaimableAmount += getParseFloat("non_claimable");

        let unpaidIncrement = 0;

        if (item.is_editing) {
          unpaidIncrement = getParseFloat("payable") - getParseFloat("paid") - getParseFloat("pay");
        } else if (item.status_name === "PARTIAL_PAID") {
          unpaidIncrement = getParseFloat("payable") - getParseFloat("paid");
        }

        unpaidAmount += unpaidIncrement;
      }
    }

    setPrice({
      absorb: absorbAmount,
      discount: discountAmount,
      non_claimable: nonClaimableAmount,
      patient_claim: patientClaimAmount,
      payable: payableAmount,
      price,
      send_claim: sendClaimAmount,
      unpaid: unpaidAmount,
    });
  }, [invoiceData, invoiceItems, orderBy]);

  useEffect(() => {
    let price = 0;

    const data: any[] = props.refundOrders?.data || [];

    for (const item of data) {
      price += Number.parseFloat(item?.price);
    }

    setPriceRefund(formatComma(price));
  }, [props.refundOrders?.data]);

  useEffect(() => {
    if (props.patientInfo?.id) {
      props.onEvent({
        message: "HandleRefreshTaskTracking",
        params: {},
      });
    }
  }, [props.patientInfo?.id]);

  const formatComma = (value: number | string = "") =>
    Number.isNaN(Number.parseFloat(value.toString()))
      ? value
      : Number(value).toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          style: "decimal",
        });

  const handleChecked = (checked: boolean | undefined, item: any) => {
    const ids: number[] = item.id_list || [item.id];

    if (checkedIds.some((id) => ids.includes(id))) {
      setCheckedIds(checkedIds.filter((id) => !ids.includes(id)));
    } else {
      setCheckedIds([...checkedIds, ...ids]);
    }
  };

  const handleChangeEdit = useCallback(
    (data: { index: number; item: any; key: string; value: string }) => {
      const { item, key } = data;

      let { value } = data;

      if (Number(value) > Number(item.payable)) {
        value = data.item.payable;
      }

      if (Number(value)) {
        const list = invoiceItems.map((item, index) =>
          index === data.index
            ? { ...item, is_editing: true, [key]: Number(value).toFixed(2) }
            : item
        );

        setInvoiceItems([...list]);
      } else {
        alert("กรุณากรอกตัวเลข");
      }
    },
    [invoiceItems]
  );

  const handleLinkPackage = useCallback(
    (data: any) => (e: SyntheticEvent) => {
      e.stopPropagation();

      setModLinkPackage(data);
    },
    []
  );

  const handleRefresh = useCallback(() => {
    props.onEvent({
      message: `GetInvoiceItemBy${orderBy}`,
      params: {
        card: `${CARD_MEDICAL_FEE}_Refresh`,
        receiptId: props.receiptId,
      },
    });
  }, [orderBy, props.receiptId]);

  const handleSearch = useCallback(() => {
    props.onEvent({
      message: "HandleSearchInvoiceItems",
      params: { action: "Search", card: CARD_MEDICAL_FEE, orderBy },
    });
  }, [orderBy]);

  const handleCancelInvoiceItem = useCallback(
    (item: any) => () => {
      setModCancelInvoiceItem(item.id);
    },
    []
  );

  const memoInvoiceItems = useMemo(
    () =>
      invoiceItems.map((item, index) => {
        const disabledEditPay = props.viewMode || item.p_type_code === "PACKAGE";
        const cancelableBillModes: string[] = JSON.parse(
          props.bilReceiptCodeDetail?.BIL_CANCELABLE_BILL_MODES || "[]"
        );

        let unpaidValue: number | string = 0;
        let color: SemanticCOLORS | undefined;

        if (item.is_editing) {
          unpaidValue = item.payable - item.paid - item.pay;
        } else if (item.status_name === "PARTIAL_PAID") {
          unpaidValue = item.payable - item.paid;
        }

        if (item.package_status === "AVAILABLE") {
          color = undefined;
        } else if (item.package_status === "APPLIED") {
          color = "green";
        }

        return {
          ...item,
          absorb: <div style={styles.alignRight}>{formatComma(item.absorb)}</div>,
          absorb__sum: <div style={styles.alignRight}>{formatComma(item.absorb__sum)}</div>,
          chk: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Checkbox
                id={`CardMedicalFee-Checkbox-Selected-${index}`}
                checked={checkedIds.includes(item.id)}
                onChange={(e, v) => {
                  handleChecked(v.checked, item);
                }}
              />
            </div>
          ),
          created: formatDatetime(item.created),
          discount: <div style={styles.alignRight}>{formatComma(item.discount)}</div>,
          discount__sum: <div style={styles.alignRight}>{formatComma(item.discount__sum)}</div>,
          drg_discount_amount: (
            <div style={styles.alignRight}>{formatComma(item.drg_discount_amount)}</div>
          ),
          non_claimable: <div style={styles.alignRight}>{formatComma(item.non_claimable)}</div>,
          non_claimable__sum: (
            <div style={styles.alignRight}>{formatComma(item.non_claimable__sum)}</div>
          ),
          patient_claim: <div style={styles.alignRight}>{formatComma(item.patient_claim)}</div>,
          patient_claim__sum: (
            <div style={styles.alignRight}>{formatComma(item.patient_claim__sum)}</div>
          ),
          pay: CONFIG.HIDE_BIL_MEDICAL_FEE_PAY ? (
            <div style={styles.alignRight}>{formatComma(item.pay)}</div>
          ) : (
            <EditorColumn
              // style
              backgroundColor={disabledEditPay ? "" : "rgb(255, 255, 204)"}
              disabled={disabledEditPay}
              value={props.viewMode ? item.paid : item.pay}
              selectOnFocus
              valueOnFocus
              onDataChanged={(value: string) => {
                handleChangeEdit({ index, item, key: "pay", value });
              }}
            />
          ),
          payable: <div style={styles.alignRight}>{formatComma(item.payable)}</div>,
          payable__sum: <div style={styles.alignRight}>{formatComma(item.payable__sum)}</div>,
          price: <div style={styles.alignRight}>{formatComma(item.price)}</div>,
          price__sum: <div style={styles.alignRight}>{formatComma(item.price__sum)}</div>,
          product: (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: item.package_status === "NONE" ? "auto" : "1fr 40px",
                width: "100%",
              }}
            >
              <div>{item.product}</div>
              <div>
                {item.package_status !== "NONE" && (
                  <Label
                    color={color}
                    onClick={handleLinkPackage(item)}
                    style={{
                      cursor: "pointer",
                      fontSize: "0.6rem",
                      marginTop: "-2px",
                    }}
                  >
                    Package
                  </Label>
                )}
              </div>
            </div>
          ),
          encounter__number: (
            <div style={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
              {!props.viewMode && (
                <ButtonLockExpense
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  encounterId={item.encounter}
                  status={item.locker_general_status}
                  patientName={props.patientInfo?.full_name}
                  hn={props.patientInfo?.hn}
                  number={item.encounter__number || item.encounter_number}
                  buttonLoadCheck={props.buttonLoadCheck}
                  errorMessage={props.errorMessage}
                  onSuccess={handleSearch}
                />
              )}
              <div style={{ textAlign: "center" }}>
                {item.encounter__number || item.encounter_number}
              </div>
            </div>
          ),
          // By Item
          quantity: <div style={styles.alignRight}>{formatComma(item.quantity)}</div>,
          send_claim: <div style={styles.alignRight}>{formatComma(item.send_claim)}</div>,
          send_claim__sum: <div style={styles.alignRight}>{formatComma(item.send_claim__sum)}</div>,
          unitprice: <div style={styles.alignRight}>{formatComma(item.unitprice)}</div>,
          unpaid: <div style={styles.alignRight}>{formatComma(unpaidValue)}</div>,
          delete: item.status_name === "BILLED" && cancelableBillModes.includes(item.mode_code) && (
            <Button size="mini" color="red" onClick={handleCancelInvoiceItem(item)}>
              <Icon name="trash alternate" />
              ยกเลิก
            </Button>
          ),
        };
      }),
    [
      checkedIds,
      handleRefresh,
      handleSearch,
      invoiceItems,
      orderBy,
      props.bilReceiptCodeDetail,
      props.buttonLoadCheck,
      props.errorMessage,
      props.patientInfo,
      props.viewMode,
    ]
  );

  const memoRefundOrders = useMemo(() => {
    const data: any[] = props.refundOrders?.data || [];

    return data.map((item: any) => ({
      ...item,
      payable: <div style={styles.alignRight}>{formatComma(item.payable)}</div>,
      price: <div style={styles.alignRight}>{formatComma(item.price)}</div>,
      pricing_quantity: <div style={styles.alignRight}>{formatComma(item.pricing_quantity)}</div>,
      quantity: <div style={styles.alignRight}>{formatComma(item.quantity)}</div>,
      unitprice: <div style={styles.alignRight}>{formatComma(item.unitprice)}</div>,
    }));
  }, [props.refundOrders?.data]);

  const citizenDetail = useMemo(() => {
    const patient = props.patientInfo;

    let text = "";
    let patientId = "";

    let reimburseForeignCode = patient?.reimburse_foreign_code
      ? ` [เลขกรมบัญชีกลาง ${patient?.reimburse_foreign_code}]`
      : "";

    if (patient?.citizen_type === "T") {
      text = `หมายเลขบัตรประชาชน ${patient.citizen_no}`;
      patientId = patient.citizen_no;
    } else if (patient?.citizen_type === "F") {
      text = `หมายเลขพาสปอร์ต ${patient.passport_no}`;
      text += reimburseForeignCode;
      patientId = patient.reimburse_foreign_code;
    } else if (patient?.citizen_type === "A") {
      text = `ใบต่างด้าว ${patient.alien_no}`;
      text += reimburseForeignCode;
      patientId = patient.reimburse_foreign_code;
    }

    return {
      patientId,
      text,
    };
  }, [props.patientInfo]);

  const hasFeeItems = useMemo(
    () =>
      [
        props.billPendingDetail?.has_opd_fee && "มีค่าใช้จ่าย OPD",
        props.billPendingDetail?.has_ipd_fee && "มีค่าใช้จ่าย IPD",
        props.billPendingDetail?.has_refund && "มีรายการรอคืนเงิน",
      ].filter(Boolean),
    [props.billPendingDetail]
  );

  const displaySelect = useMemo(() => {
    const style: CSSProperties =
      !props.viewMode && ["Item", "Order"].includes(orderBy)
        ? { display: "flex", justifyContent: "center" }
        : { display: "none" };

    return style;
  }, [!props.viewMode, orderBy]);

  const faxClaimTotalPayable = useMemo(() => {
    const payableString: string = props.faxClaimItemList?.total?.payable || "";

    return Number(payableString.replaceAll(",", ""));
  }, [props.faxClaimItemList?.total?.payable]);

  const trackingStatusConfig = useMemo(() => {
    const trackingStatus = props.refundOrders?.tracking?.status;

    const statusConfig = {
      CANCELED: { color: "red", title: "ยกเลิก" },
      COMPLETED: { color: "green", title: "เตรียมเอกสารเสร็จสิ้น" },
    } as any;

    return (
      statusConfig[trackingStatus] || {
        color: "#00B5AD",
        title: "กำลังเตรียมเอกสาร",
      }
    );
  }, []);

  const divisionOptions = useMemo(() => {
    const options = props.masterOptions?.division || [];

    return [{ key: 0, text: "ทุกแผนก", value: "ALL" }, ...options];
  }, [props.masterOptions]);

  const encounterNumberOptions = useMemo(() => {
    const options = props.medicalFeeFilter?.encounterOptions || [];

    return [{ key: 0, text: "ทั้งหมด", value: "ALL" }, ...options];
  }, [props.medicalFeeFilter?.encounterOptions]);

  const lockingStatusMessages = useMemo(
    () =>
      (props.patientEncounterLockerStatus || []).map(
        (lockInfo) =>
          `${lockInfo.locked_by_name} กำลังปิดตรวจสอบค่าใช้จ่าย En. ${
            lockInfo.encounter_number
          } (${formatDatetime(lockInfo.locked_at)})`
      ),
    [props.patientEncounterLockerStatus]
  );

  const handleChangeOrderBy = (event: any, data: any) => {
    props.onEvent({
      message: `GetInvoiceItemBy${data.name}`,
      params: {
        card: CARD_MEDICAL_FEE,
        receiptId: props.receiptId,
      },
    });

    setOrderBy(data.name);
  };

  const onSelectALL = () => {
    const id: any[] = [];

    for (const item of invoiceItems) {
      id.push(item.id);
    }

    setCheckedIds(id);
  };

  const onSelectNone = () => {
    setCheckedIds([]);
  };

  const handleGetReceipt = () => {
    const nameReceipt: any[] = [];
    const data: any[] = props.refundOrders?.data || [];

    let receipt = "";

    for (const item of data) {
      if (!nameReceipt.includes(item.receipts)) {
        nameReceipt.push(item.receipts);
      }
    }

    for (const item of nameReceipt) {
      receipt = `${receipt}, ${item}`;
    }

    setReceiptRefund(receipt);
  };

  const handleCloseModalTracking = () => {
    if (
      props.refundOrders?.tracking?.status === "COMPLETED" ||
      props.refundOrders?.tracking?.status === "CANCELED"
    ) {
      props.setProp("refundOrders.tracking", null);
      setTracking(false);
    } else {
      // #props.onEvent({
      //   message: "GetCreditNoteItem",
      //   params: { action: "cancelCreditNote" },
      // });
    }
  };

  const handlePrintReceipt = () => {
    // หากส่ง receiptId จะ get by receipt id
    props.onEvent({
      message: "HandlePrintMedicalFeeDetail",
      params: { receipt: props.receiptId },
    });
  };

  const handleCancelReportPrint = () => {
    props.onEvent({
      message: "HandleReportPrintTrackingResult",
      params: {
        action: "CANCEL",
        taskId: props.billReportPrint?.task_id,
      },
    });
  };

  const handleCloseModCancel = () => {
    props.setProp("billReportPrint", {});
  };

  const handlePaying = () => {
    props.setProp(`errorMessage.${CARD_MEDICAL_FEE}_KTB`, null);
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "BillPayment",
        allowKTB: true,
        card: CARD_MEDICAL_FEE,
        invoiceData,
        invoiceItemByItems: props.invoiceItemByItems,
      },
    });
  };

  const editOrderFaxClaim = (index: number, status: string) => {
    const updatedData: any[] = [];
    const faxClaimData: any[] = props.faxClaimItemList?.items || [];

    if (status === "up" && faxClaimData.length > 1) {
      const cloneData = faxClaimData[index];
      const cloneDataUp = faxClaimData[index - 1];

      for (const [number, item] of faxClaimData.entries()) {
        if (index === number) {
          updatedData.push({ ...cloneDataUp, priority: number + 1 });
        } else if (index - 1 === number) {
          updatedData.push({ ...cloneData, priority: number + 1 });
        } else {
          updatedData.push(item);
        }
      }

      props.setProp("faxClaimItemList.items", updatedData);

      return;
    }

    if (!(status === "down" && faxClaimData.length > 1)) {
      return;
    }

    const cloneData = faxClaimData[index];
    const cloneDataDown = faxClaimData[index + 1];

    for (const [number, item] of faxClaimData.entries()) {
      if (index === number) {
        updatedData.push({ ...cloneDataDown, priority: number + 1 });
      } else if (index + 1 === number) {
        updatedData.push({ ...cloneData, priority: number + 1 });
      } else {
        updatedData.push(item);
      }
    }

    props.setProp("faxClaimItemList.items", updatedData);
  };

  const handleChangeFaxClaim = useCallback(
    (data: { index: number; item: any; key: string; value: string }) => {
      const { item, key } = data;

      let { value } = data;

      if (Number(value) > Number(item.payable)) {
        value = item.payable;
      }

      const items: any[] = props.faxClaimItemList?.items || [];

      const list = items.map((item, index) =>
        index === data.index ? { ...item, [key]: Number(value).toFixed(2) } : item
      );

      // calculator
      let totalPrice = 0;
      let nonClaimable = 0;

      for (const [index, item] of items.entries()) {
        totalPrice =
          index === data.index
            ? totalPrice + Number.parseFloat(value)
            : totalPrice + Number.parseFloat(item?.total_send_claim_price);
      }

      const total:
        | Record<"absorbable" | "discount" | "patient_claim" | "price", string>
        | undefined = props.faxClaimItemList?.total;

      // by_mode faxClaimItemList?.total is string
      if (total) {
        const price = Number.parseFloat(total.price.replaceAll(",", ""));
        const absorbable = Number.parseFloat(total.absorbable.replaceAll(",", ""));
        const patientClaim = Number.parseFloat(total.patient_claim.replaceAll(",", ""));
        const discount = Number.parseFloat(total.discount.replaceAll(",", ""));

        nonClaimable = price - absorbable - patientClaim - discount - totalPrice;
      } else {
        nonClaimable = totalPrice;
      }

      props.setProp("faxClaimItemList", {
        items: [...list],
        total: {
          ...props.faxClaimItemList?.total,
          non_claimable: nonClaimable.toFixed(2),
          payable: nonClaimable.toFixed(2),
          send_claim: totalPrice.toFixed(2),
        },
      });
    },
    [props.faxClaimItemList]
  );

  const faxClaimItems = useMemo(() => {
    const items: any[] = props.faxClaimItemList?.items || [];

    // return props.faxClaimItemList?.items.filter((item: any) => item.send_claim === true).map((item: any, index: number) => ({
    return items.map((item: any, index: number) => ({
      ...item,
      mode_name: <div>{`[${item?.bill_mode_code}] ${item?.bill_mode_name}`}</div>,
      order: (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <div>
            {index !== 0 && (
              <div style={{ margin: "-0.5rem 0 -0.25rem" }}>
                <Icon
                  color="grey"
                  name="caret up"
                  size="big"
                  onClick={() => {
                    editOrderFaxClaim(index, "up");
                  }}
                />
              </div>
            )}
            {(index !== props.faxClaimItemList?.items.length - 1 || index === 0) && (
              <div style={{ margin: "-0.5rem 0 -0.25rem" }}>
                <Icon
                  color="grey"
                  name="caret down"
                  size="big"
                  onClick={() => {
                    editOrderFaxClaim(index, "down");
                  }}
                />
              </div>
            )}
          </div>
        </div>
      ),
      total_send_claim_price: (
        <EditorColumn
          // style
          backgroundColor={"rgb(255, 255, 204)"}
          value={item.total_send_claim_price}
          selectOnFocus
          valueOnFocus
          onDataChanged={(value: string) => {
            // set total_send_claim_price
            handleChangeFaxClaim({
              index,
              item,
              key: "total_send_claim_price",
              value,
            });
          }}
        />
      ),
    }));
  }, [props.faxClaimItemList?.items]);

  const renderStatusMessage = useMemo(() => {
    const status = props.refundOrders?.tracking?.status;

    if (status === "COMPLETED") {
      return <div>{intl.formatMessage({ id: "เตรียมเอกสารเสร็จสิ้น" })}</div>;
    } else if (status === "CANCELED") {
      return <div>{props.refundOrders?.tracking?.current_progress_description}</div>;
    }

    return (
      <div>
        <div>{intl.formatMessage({ id: "กรุณารอสักครู่" })}</div>
        <div style={{ padding: "10px 0px" }}>
          For IT: task_id = {props.refundOrders?.tracking?.task_id}
        </div>
      </div>
    );
  }, [props.refundOrders]);

  const handleGetFaxClaim = (state: any, rowInfo: any, column: any) => ({
    onClick: async () => {
      if (props.viewMode) {
        return;
      }

      // check onClick Table
      if (
        column.Header !== "..." &&
        (!CONFIG.HIDE_BIL_MEDICAL_FEE_PAY ? column.Header !== "ผู้ป่วยชำระ" : true)
      ) {
        if (orderBy === "Item") {
          props.onEvent({
            message: "GetFexClaimDetail",
            params: { dataType: "by_item", invoiceItem: rowInfo?.original?.id },
          });
        } else if (orderBy === "Mode") {
          props.onEvent({
            message: "GetFexClaimDetail",
            params: {
              billMode: rowInfo?.original?.bill_mode__code,
              dataType: "by_mode",
              hn: props.patientInfo?.hn || props.selectedEncounter?.hn,
            },
          });
        }
      }
    },
  });

  const handleCheckedTypeSave = () => {
    if (orderBy === "Item") {
      props.onEvent({
        message: "HandleUpdateFexClaim",
        params: {
          card: "FaxClaim_SAVE",
          dataType: "by_item",
          invoiceItem: props.faxClaimItemList?.total?.id,
        },
      });
    } else if (orderBy === "Mode") {
      props.onEvent({
        message: "HandleUpdateFexClaim",
        params: {
          card: "FaxClaim_SAVE",
          dataType: "by_mode",
          invoiceItem: props.faxClaimItemList?.total?.id,
        },
      });
    }
  };

  const handleSaveModFaxClaim = () => {
    if (props.showAuthorize) {
      setOpenModAuthen(true);
    } else {
      handleCheckedTypeSave();
    }
  };

  const handleCloseModFaxClaim = () => {
    props.setProp("faxClaimItemList", { items: [], total: {} });
    setOpenModFaxClaim(false);
  };

  const handleCloseModLinkPackage = () => {
    setModLinkPackage(null);
  };

  const handleBillPayment = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "BillPayment",
        card: CARD_MEDICAL_FEE,
        invoiceData,
        invoiceItemByItems: props.invoiceItemByItems,
      },
    });
  };

  const handleGetCreditNoteList = () => {
    props.onEvent({
      message: "GetCreditNoteItem",
      params: {
        action: "getCreditNoteList",
        card: CARD_MEDICAL_FEE,
      },
    });
    setOpenRefund(false);
  };

  const handleTransfer = () => {
    props.onEvent({
      message: "HandleTransfer",
      params: {
        card: `${CARD_MEDICAL_FEE}_transfer`,
        invoiceData,
      },
    });
    setOpenTransfer(false);
  };

  const handlePaymentInvoice = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "BillPayment",
        action: "invoice",
        card: CARD_MEDICAL_FEE,
        goToMenu: "เอกสารทั้งหมด",
        idList: invoiceData.ids,
      },
    });
    setOpenInvoice(false);
  };

  const handleByOrderRowProps = (state: any, rowInfo: any) => ({
    style: {
      backgroundColor: rowInfo?.original?.highlight && "rgb(255,200,200)",
    },
  });

  const handleChangeFilter = (
    e: SyntheticEvent | null,
    data: { checked?: boolean; name: string; value: boolean | string }
  ) => {
    const value = data.checked === undefined ? data.value : data.checked;

    props.setProp(`medicalFeeFilter.${data.name}`, value);
  };

  const handleDeleteInvoiceItem = (data: any) => {
    props.onEvent({
      message: "HandleDeleteInvoiceItem",
      params: {
        id: modCancelInvoiceItem,
        ...data,
        onSuccess: () => {
          setModCancelInvoiceItem(null);

          handleSearch();
        },
      },
    });
  };

  return (
    <div
      style={{
        overflowY: "auto",
        width: props.viewMode ? "" : "calc(100vw - 28rem)",
        ...(props.openPaying ? {} : { height: "100%", paddingBottom: "3rem", paddingTop: "5px" }),
      }}
    >
      {props.openPaying ? (
        <>
          <CardPaying
            onEvent={props.onEvent}
            setProp={props.setProp}
            // seq
            BillPaymentSequence={props.BillPaymentSequence}
            citizenDetail={citizenDetail}
            masterOptions={props.masterOptions}
            // config
            invoiceId={props.invoiceId}
            invoiceItemByItems={props.invoiceItemByItems}
            // historyInvoiceData={props.historyInvoiceData}
            openPaying={props.openPaying}
            patientInfo={props.patientInfo}
            patientOptionData={props.billingPatientLanguage}
            simpleReceipt={props.simpleReceipt}
            // CommonInterface
            errorMessage={props.errorMessage}
            // callback
            onSuccess={props.onSuccess}
            defaultAmount={{
              cashonly: 0,
              discount: 0,
              payable: 0,
              price: 0,
              reimbursable: 0,
            }}
            invoiceData={{
              ids: (props.invoiceHistoryItems || []).flatMap((item: any) =>
                item.id_list?.length ? item.id_list : [item?.id]
              ),
              items: props.invoiceHistoryItems || [],
              orderBy: props.orderBy,
            }}
            languageUX={props.languageUX}
          />

          {!!props.errorMessage?.[CARD_MEDICAL_FEE] && (
            <ModInfo
              titleColor={"red"}
              open
              onApprove={() => props.setProp(`errorMessage.${CARD_MEDICAL_FEE}`, null)}
            >
              <ErrorMessage error={props.errorMessage[CARD_MEDICAL_FEE]} />
            </ModInfo>
          )}

          <ModInfo
            open={!!props.errorMessage?.[`${CARD_MEDICAL_FEE}_KTB`]}
            titleColor={"yellow"}
            onApprove={handlePaying}
            onClose={handlePaying}
          >
            <div
              style={{
                lineHeight: 1.75,
                padding: "0.5rem 0",
                textAlign: "left",
              }}
            >
              {props.errorMessage?.[`${CARD_MEDICAL_FEE}_KTB`]}
            </div>
          </ModInfo>
        </>
      ) : (
        <>
          <SnackMessage
            onEvent={props.onEvent}
            error={props.BillPaymentSequence?.payErrorMessage}
            success={null}
            onClose={() => {
              props.setProp("BillPaymentSequence.payErrorMessage", null);
            }}
            languageUX={props.languageUX}
          />

          <ModInfo
            type={props.BillPaymentSequence?.deleteInvoiceItem?.status}
            open={props.BillPaymentSequence?.deleteInvoiceItem}
            titleName={"แจ้งเตือน"}
            alertText={props.BillPaymentSequence?.deleteInvoiceItem?.alertText}
            onClose={() => {
              props.setProp("BillPaymentSequence.deleteInvoiceItem", null);
            }}
            onApprove={() => {
              props.setProp("BillPaymentSequence.deleteInvoiceItem", null);
            }}
          />

          {!!props.taskTrackingList?.length && (
            // @ts-ignore
            <Segment color="red" style={{ borderRadius: "5px", ...styles.margin }} inverted>
              ระบบกำลังคำนวณค่าใช้จ่าย กรุณางดทำรายการดังต่อไปนี้
              <div style={{ marginTop: "-1.5rem", paddingLeft: "0.5rem" }}>
                <br /> 1. รับชำระเงิน / แจ้งชำระเงิน
                <br /> 2. โอนค่าใช้จ่าย
                <br /> 3. เพิ่ม / แก้ไข สิทธิและส่วนลด
                <br /> 4. เพิ่ม / แก้ไข วงเงิน
                <br /> 5. คืนสินค้าหรือบริการ
                <br /> 6. ระงับสิทธิ / ยกเลิกระงับสิทธิ
              </div>
            </Segment>
          )}

          {isPendingSomeOrder && (
            <div style={styles.margin}>
              <div style={styles.message}>
                {intl.formatMessage({ id: "มีคำสั่งแพทย์ที่ยังไม่พร้อมรับชำระ" })}
              </div>
            </div>
          )}

          {props.billPendingDetail?.exists && (
            <div style={styles.margin}>
              <div style={styles.message}>
                {intl.formatMessage({ id: "มีใบแจ้งหนี้ที่อยู่ระหว่างการรอรับชำระ" })}
              </div>
            </div>
          )}

          {hasFeeItems.length > 0 ? (
            <div style={styles.margin}>
              <div style={styles.message}>{hasFeeItems.map((value) => `${value}`).join(" , ")}</div>
            </div>
          ) : (
            <>
              {!!props.billPendingDetail && (
                <div style={styles.margin}>
                  <div
                    style={{
                      ...styles.message,
                      backgroundColor: "#21ba45",
                    }}
                  >
                    ไม่มีค่าใช้จ่าย
                  </div>
                </div>
              )}
            </>
          )}

          {Number(props.billPendingDetail?.total_billed_price) > 0 && (
            <div style={styles.margin}>
              <div style={{ ...styles.message, backgroundColor: "#db2828" }}>
                ผู้ป่วยมีรายการยังไม่ชำระ {formatComma(props.billPendingDetail?.total_billed_price)}{" "}
                บาท
              </div>
            </div>
          )}

          {Number(props.billPendingDetail?.total_outstanding_balance_price) > 0 && (
            <div style={styles.margin}>
              <div style={{ ...styles.message, backgroundColor: "#db2828" }}>
                ผู้ป่วยมีรายการค้างชำระบางส่วน{" "}
                {formatComma(props.billPendingDetail?.total_outstanding_balance_price)} บาท
              </div>
            </div>
          )}

          {/* Medical Fee */}
          <CardBillingDocumentUX
            // data
            absorbTotal={formatComma(price.absorb)}
            billPayment={handleBillPayment}
            byOrderRowProps={handleByOrderRowProps}
            discountTotal={formatComma(price.discount)}
            displaySelect={displaySelect}
            // Hide แจ้งชำระเงิน และ รับชำระเงิน button
            hidebp={props.hidebp}
            hidebpInvoice={props.hidebpInvoice}
            invoiceItems={memoInvoiceItems}
            medicalFeeFilter={props.medicalFeeFilter}
            nonClaimableTotal={formatComma(price.non_claimable)}
            orderBy={orderBy}
            patientClaimTotal={formatComma(price.patient_claim)}
            patientValue={props.billingPatientLanguage?.value}
            payableTotal={formatComma(price.payable)}
            price_total={formatComma(price.price)}
            sendClaimTotal={formatComma(price.send_claim)}
            showFilter={props.showFilter}
            title="MEDICAL FEE"
            titleDescription={
              <div style={{ paddingTop: "0.25rem" }}>
                {intl.formatMessage({ id: "ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ" })}
              </div>
            }
            lockingStatusMessage={
              lockingStatusMessages.length > 0 && (
                <Label
                  style={{
                    backgroundColor: "rgba(255, 232, 185, 1)",
                    color: "unset",
                    lineHeight: 1.35,
                  }}
                >
                  {lockingStatusMessages.map((text, index) => (
                    <div key={`${text}-${index}`}>{text}</div>
                  ))}
                </Label>
              )
            }
            unpaidTotal={formatComma(price.unpaid)}
            // แสดงตารางและ button by
            viewMode={props.viewMode}
            // options
            divisionOptions={divisionOptions}
            encounterNumberOptions={encounterNumberOptions}
            patientOptions={props.billingPatientLanguage?.options}
            onChangeFilter={handleChangeFilter}
            // callback
            onChangeOrderBy={handleChangeOrderBy}
            onChangePatient={(e: any, v: any) => {
              props.setProp("billingPatientLanguage.value", v.value);
            }}
            onClickAll={onSelectALL}
            onClickNone={onSelectNone}
            onPrintReceipt={handlePrintReceipt}
            onSelectedFaxClaim={handleGetFaxClaim}
            billPaymentInvoice={() => {
              setOpenInvoice(true);
            }}
            billPaymentTransfer={() => {
              setOpenTransfer(true);
            }}
            buttonRefresh={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                // config
                icon="redo"
                // data
                paramKey={`${CARD_MEDICAL_FEE}_Refresh`}
                size="mini"
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_MEDICAL_FEE}_Refresh`]}
                onClick={handleRefresh}
              />
            }
            buttonSearch={
              <ButtonLoadCheck
                setProp={props.setProp}
                color="blue"
                paramKey={`${CARD_MEDICAL_FEE}_Search`}
                size="small"
                title={intl.formatMessage({ id: "ค้นหา" })}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_MEDICAL_FEE}_Search`]}
                // callback
                onClick={handleSearch}
              />
            }
            receiptCode={
              <>
                เลขที่ใบเสร็จ :{" "}
                <strong style={{ color: "rgba(0,0,0,0.87)" }}>
                  {props.bilReceiptCodeDetail?.code === undefined
                    ? "-"
                    : props.bilReceiptCodeDetail.code || "ยังไม่ได้ตั้งเลขที่ใบเสร็จ"}
                </strong>
              </>
            }
            languageUX={props.languageUX}
          />

          {/* Refund */}
          {!!props.refundOrders?.data?.length && (
            <div style={{ marginTop: "50px" }}>
              <CardRefundUX
                priceRefund={priceRefund}
                refundOrders={memoRefundOrders}
                onRefund={() => {
                  handleGetReceipt();
                  setOpenRefund(true);
                }}
                languageUX={props.languageUX}
              />
            </div>
          )}

          <ModConfirm
            openModal={openRefund}
            size={"mini"}
            titleColor="yellow"
            titleName={<span>-</span>}
            onApprove={handleGetCreditNoteList}
            onDeny={() => {
              setOpenRefund(false);
            }}
            content={
              <label>
                {`จะต้องรับใบเสร็จเลขที่ ${receiptRefund} จากผู้ป่วย ยืนยันการคืนเงินผู้ป่วยเป็นจำนวน ${priceRefund} หรือไม่`}
              </label>
            }
            // Element
            receiptCode={
              <>
                เลขที่ใบเสร็จ :{" "}
                <strong style={{ color: "rgba(0,0,0,0.87)" }}>
                  {props.bilReceiptCodeDetail?.code === undefined
                    ? "-"
                    : props.bilReceiptCodeDetail.code || "ยังไม่ได้ตั้งเลขที่ใบเสร็จ"}
                </strong>
              </>
            }
          />

          <ModConfirm
            content={<span>{intl.formatMessage({ id: "ต้องการโอนค่าใช้จ่ายใช่หรือไม่" })}</span>}
            openModal={openTransfer}
            size={"mini"}
            titleColor="blue"
            titleName={<span>{intl.formatMessage({ id: "กรุณายืนยัน" })}</span>}
            onApprove={handleTransfer}
            onDeny={() => {
              setOpenTransfer(false);
            }}
          />

          {/* tracking */}
          <Modal open={tracking} size="mini">
            <div>
              <div
                style={{
                  backgroundColor: trackingStatusConfig.color,
                  color: "white",
                  fontWeight: "bold",
                  padding: "15px",
                }}
              >
                {trackingStatusConfig.title}
              </div>
              <div style={{ padding: "15px" }}>
                {renderStatusMessage}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    color={props.refundOrders?.tracking?.status === "COMPLETED" ? "green" : "red"}
                    size="tiny"
                    basic
                    onClick={() => {
                      handleCloseModalTracking();
                    }}
                  >
                    {props.refundOrders?.tracking?.status === "COMPLETED" ||
                    props.refundOrders?.tracking?.status === "CANCELED"
                      ? "ตกลง"
                      : "ยกเลิก"}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>

          <ModConfirm
            openModal={openInvoice}
            size={"mini"}
            titleColor="blue"
            titleName={<span>{intl.formatMessage({ id: "กรุณายืนยัน" })}</span>}
            onApprove={handlePaymentInvoice}
            onDeny={() => {
              setOpenInvoice(false);
            }}
            content={
              <span>
                {intl.formatMessage({
                  id: "ต้องการรวบค่าใช้จ่ายเพื่อออกเอกสารใบแจ้งการชำระค่ารักษาพยาบาลหรือไม่",
                })}
              </span>
            }
          />

          {!!props.errorMessage?.[`${CARD_MEDICAL_FEE}_transfer`] && (
            <ModInfo
              titleColor={"red"}
              open
              onApprove={() => props.setProp(`errorMessage.${CARD_MEDICAL_FEE}_transfer`, null)}
            >
              <ErrorMessage error={props.errorMessage[`${CARD_MEDICAL_FEE}_transfer`]} />
            </ModInfo>
          )}

          <Modal
            open={!!props.BillPaymentSequence?.openCardPaying}
            style={{ maxHeight: "80%", overflow: "auto", width: "80%" }}
            closeOnDimmerClick
            onClose={() => {
              props.onEvent({
                message: "RunSequence",
                params: { sequence: "BillPayment", action: "close" },
              });
            }}
          >
            <CardPaying
              onEvent={props.onEvent}
              setProp={props.setProp}
              // seq
              BillPaymentSequence={props.BillPaymentSequence}
              // data
              citizenDetail={citizenDetail}
              invoiceData={invoiceData}
              invoiceItemByItems={props.invoiceItemByItems}
              patientInfo={props.patientInfo}
              patientOptionData={props.billingPatientLanguage}
              showFilter={props.showFilter}
              masterOptions={props.masterOptions}
              languageUX={props.languageUX}
            />
          </Modal>

          {!!props.errorMessage?.[CARD_MEDICAL_FEE] && (
            <ModInfo
              titleColor={"red"}
              open
              onApprove={() => props.setProp(`errorMessage.${CARD_MEDICAL_FEE}`, null)}
            >
              <ErrorMessage error={props.errorMessage[CARD_MEDICAL_FEE]} />
            </ModInfo>
          )}

          <ModInfo
            btnText={intl.formatMessage({ id: "ยกเลิก" })}
            buttonColor="red"
            open={!!props.billReportPrint?.open}
            style={{ width: "27.5%" }}
            titleColor={"black"}
            titleName={intl.formatMessage({ id: "กำลังเตรียมเอกสาร" })}
            onApprove={handleCancelReportPrint}
            // onClose={handleCloseModInfo}
          >
            <div
              style={{
                lineHeight: 1.75,
                padding: "0.5rem 0",
                textAlign: "left",
              }}
            >
              <div>
                กรุณารอสักครู่
                <br />
                {!!props.billReportPrint?.status && (
                  <>
                    Status: {props.billReportPrint.status} {props.billReportPrint.progress}%
                    <br />
                  </>
                )}
                For IT: task_id = {props.billReportPrint?.task_id}
              </div>
            </div>
          </ModInfo>

          <ModInfo
            open={!!props.billReportPrint?.error}
            titleColor={"red"}
            onApprove={handleCloseModCancel}
            onClose={handleCloseModCancel}
          >
            <div
              style={{
                lineHeight: 1.75,
                padding: "0.5rem 0",
                textAlign: "left",
              }}
            >
              {props.billReportPrint?.error}
            </div>
          </ModInfo>

          <ModInfo
            open={!!props.errorMessage?.[`${CARD_MEDICAL_FEE}_KTB`]}
            titleColor={"yellow"}
            onApprove={handlePaying}
            onClose={handlePaying}
          >
            <div
              style={{
                lineHeight: 1.75,
                padding: "0.5rem 0",
                textAlign: "left",
              }}
            >
              {props.errorMessage?.[`${CARD_MEDICAL_FEE}_KTB`]}
            </div>
          </ModInfo>

          {/* flxclaim */}
          <Modal
            open={openModFaxClaim}
            size={"large"}
            style={{ margin: "20px" }}
            onClose={handleCloseModFaxClaim}
          >
            <CardFaxClaimUX
              onEvent={props.onEvent}
              setProp={props.setProp}
              faxClaimItems={faxClaimItems}
              invoiceItem={props.faxClaimItemList?.total}
              orderBy={orderBy}
              onCancelFaxCliaim={handleCloseModFaxClaim}
              onSaveFaxCliaim={handleSaveModFaxClaim}
              buttonSave={
                <ButtonLoadCheck
                  // function
                  setProp={props.setProp}
                  color="green"
                  // data
                  disabled={faxClaimTotalPayable < 0}
                  // config
                  name="SAVE"
                  paramKey={"FaxClaim_SAVE"}
                  title={intl.formatMessage({ id: "บันทึก" })}
                  buttonLoadCheck={props.buttonLoadCheck?.FaxClaim_SAVE}
                  onClick={handleSaveModFaxClaim}
                />
              }
              // data
              textAlert={
                <div
                  style={{
                    ...(faxClaimTotalPayable > 0 && {
                      display: "none",
                    }),
                  }}
                >
                  <ErrorMessage
                    error={intl.formatMessage({ id: "ยอดส่งเบิกเกินราคารวม กรุณาระบุใหม่" })}
                  />
                </div>
              }
              languageUX={props.languageUX}
            />
          </Modal>

          {/* comfirm faxclaim */}
          <ModAuthen
            open={openModAuthen}
            titleColor={"blue"}
            titleName={intl.formatMessage({ id: "ยืนยันการแก้ไขข้อมูล" })}
            // error={props.authenError}
            onApprove={() => {
              // save
              handleCheckedTypeSave();
              setOpenModAuthen(false);
            }}
            onDeny={() => {
              setOpenModAuthen(false);
            }}
          />

          <ModLinkPackage
            onEvent={props.onEvent}
            setProp={props.setProp}
            data={modLinkPackage || {}}
            items={props.invoiceItemPackageList}
            // data
            open={!!modLinkPackage}
            buttonLoadCheck={props.buttonLoadCheck}
            // CommonInterface
            errorMessage={props.errorMessage}
            // callback
            onClose={handleCloseModLinkPackage}
            languageUX={props.languageUX}
          />

          <ModCancelExpense
            open={!!modCancelInvoiceItem}
            buttonLoadCheck={props.buttonLoadCheck}
            divisionOptions={props.masterOptions?.division}
            options={props.masterOptions?.invoiceCancelNote}
            onApprove={handleDeleteInvoiceItem}
            onClose={() => {
              setModCancelInvoiceItem(null);
            }}
            isRequire={true}
          />
        </>
      )}
    </div>
  );
};

export default CardMedicalFee;
