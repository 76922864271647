import React, { useState, forwardRef, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Modal, Grid, Segment, Button, Input } from "semantic-ui-react";

import axios from "axios";

// Common
import CardLayout from "react-lib/apps/common/CardLayout";
import ModConfirm from "react-lib/apps/common/ModConfirm";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ComboBox from "react-lib/apps/common/ComboBox";
import ModInfo from "react-lib/apps/common/ModInfo";
import CardEncounterList from "react-lib/apps/common/CardEncounterList";
import PatientIdentification from "react-lib/apps/common/PatientIdentification";
import FrameChrome from "react-lib/apps/common/FrameChrome";
import SearchBox from "react-lib/apps/common/SearchBox";
import PureReactTable from "./PureReactTable";
import getPdfMake from "react-lib/appcon/common/pdfMake";

// Util
import { beToAd, beStringToAdString } from "react-lib/utils/dateUtils";
import { vcMessenger } from "react-lib/compat/vc-websocket";
import moment from "moment";
import _ from "react-lib/compat/lodashplus";
import { printURL } from "react-lib/utils/printURL";
import { setPDFMedCertificateData } from "react-lib/apps/HISV3/DPO/sequence/DoctorCertificate";
import { PDFDocument } from "pdf-lib";

// Config
import CONFIG from "config/config";

// PDF
import { AddHeaderCudentPrintListTH } from "./pdfPrintList/AddHeaderCudentPrintList";
import {
  AddHeaderSapiensPrintListTH,
  AddHeaderSapiensPrintListEN,
  AddHeaderSapiensInfoTH,
} from "./pdfPrintList/AddHeaderSapiensPrintList";
import { default as FormLivingWill } from "./pdfPrintList/FormLivingWill";
import { default as FormConsentPersonalHealth } from "./pdfPrintList/FormConsentPersonalHealth";
import { default as FormConsentDrugScreenTH } from "./pdfPrintList/FormConsentDrugScreenTH";
import { default as FormConsentDrugScreenEN } from "./pdfPrintList/FormConsentDrugScreenEN";
import { default as FormConsentNarcoticUseTH } from "./pdfPrintList/FormConsentNarcoticUseTH";
import { default as FormConsentNarcoticUseEN } from "./pdfPrintList/FormConsentNarcoticUseEN";
import { default as FormConsentHIVTestTH } from "./pdfPrintList/FormConsentHIVTestTH";
import { default as FormConsentHIVTestEN } from "./pdfPrintList/FormConsentHIVTestEN";
import { default as FormConsentBloodTransfusionTH } from "./pdfPrintList/FormConsentBloodTransfusionTH";
import { default as FormConsentBloodTransfusionEN } from "./pdfPrintList/FormConsentBloodTransfusionEN";
import { default as FormConsentPreSedationTH } from "./pdfPrintList/FormConsentPreSedationTH";
import { default as FormConsentPreSedationEN } from "./pdfPrintList/FormConsentPreSedationEN";
import { default as FormConsentOperatingTH } from "./pdfPrintList/FormConsentOperatingTH";
import { default as FormConsentTreatmentProcedure } from "./pdfPrintList/FormConsentTreatmentProcedure";
import { default as FormNewPatientRegistration } from "./pdfPrintList/FormNewPatientRegistration";
import { default as FormConsentAMATH } from "./pdfPrintList/FormConsentAMATH";
import { default as FormConsentAMAEN } from "./pdfPrintList/FormConsentAMAEN";
import { default as FormDNRTH } from "./pdfPrintList/FormDNRTH";
import { default as FormDNREN } from "./pdfPrintList/FormDNREN";
import { default as FormLivingWillTH } from "./pdfPrintList/FormLivingWillTH";
import { default as FormLivingWillEN } from "./pdfPrintList/FormLivingWillEN";
import FormPatientStickerSingle from "./pdfPrintList/FormPatientStickerSingle";
import FormPriceEvaluate from "./pdfPrintList/FormPriceEvaluate";
import FormPriceEvaluateTH from "./pdfPrintList/FormPriceEvaluateTH";
import FormPriceEvaluateEN from "./pdfPrintList/FormPriceEvaluateEN";
import FormConsentOperatingEN from "./pdfPrintList/FormConsentOperatingEN";
import FormPriceEstimateTH from "./pdfPrintList/FormPriceEstimateTH";
import FormClaimForInsurance from "./pdfPrintList/FormClaimForInsurance";
import FormConsentSpinalTH from "./pdfPrintList/FormConsentSpinalTH";
import FormConsentRadioNeuralTH from "./pdfPrintList/FormConsentRadioNeuralTH";
import FormConsentCerviJointTH from "./pdfPrintList/FormConsentCerviJointTH";
import FormConsentPeripheNervTH from "./pdfPrintList/FormConsentPeripheNervTH";
import FormConsentEpiduralSteroidInjectTH from "./pdfPrintList/FormConsentEpiduralSteroidInjectTH";
import FormPainManagementFlowsheet from "./pdfPrintList/FormPainManagementFlowsheet";
import FormIntravenousCareFlowsheet from "./pdfPrintList/FormIntravenousCareFlowsheet";
import FormPatientStickerHnIpd from "./pdfPrintList/FormPatientStickerHnIpd";
import FormCudentMedCertPrintList from "./pdfPrintList/FormCudentMedCertPrintList";
import FormSummaryExpenseInvoiceIpd from "./pdfPrintList/FormSummaryExpenseInvoiceIpd";
import FormListExpenseInvoiceIpd from "./pdfPrintList/FormListExpenseInvoiceIpd";
import { useIntl } from "react-intl";

type PreparedDataType = Partial<{
  prepCreatedDateTh: any;
  prepCreatedDateEn: any;
  prepSignedDateTh: any;
  prepSignedDateEn: any;
  prepPatientName: FormattedNameType;
  prepPatientAge: any;
  prepPatientBirthDateEn: string;
  prepPatientAddress: any;
  prepDoctorName: FormattedNameType;
  haveHeader: boolean;
}>;

type FormDataType = {
  data: {
    items: any
  };
  formName: string;
  language?: string;
} | null;

type HeaderDataType = {
  createdFormDate: string;
  formName: string;
  patientInfo: any;
  footer?: string | null;
};

type FormattedNameType = {
  withPrefixTh: any;
  withPrefixEn: any;
  withoutPrefixTh: string;
  withoutPrefixEn: string;
};

// Const c.
const HOST = `${CONFIG.API_HOST}`;
const isEnableHeader = CONFIG.ENABLE_HEADER_PRINTLIST;
const COMPANY = CONFIG.COMPANY;

const LANGUAGE_USAGE = {
  TH: "TH",
  EN: "EN",
};

// อ้างจาก CardPatientInfo
const MARRIAGE_STATUS = [
  { id: "S", text: "โสด" },
  { id: "M", text: "สมรส" },
  { id: "E", text: "หมั้น" },
  { id: "D", text: "หย่า" },
  { id: "W", text: "หม้าย" },
  { id: "A", text: "แยกกันอยู่" },
];

// อ้างจาก core Patient
export const GENDER_LIST = {
  M: { name_th: "ชาย", name_en: "Male" },
  F: { name_th: "หญิง", name_en: "Female" },
  U: { name_th: "ไม่แน่ชัด", name_en: "Undetermined" },
  PM: { name_th: "Male (Not Approved)", name_en: "Male (Not Approved)" },
  PF: { name_th: "Female (Not Approved)", name_en: "Female (Not Approved)" },
  MM: { name_th: "Men who have sex with men", name_en: "Men who have sex with men" },
  TG: { name_th: "Transgender", name_en: "Transgender" },
} as any;

// Form กระดาษเปล่าสำหรับทดสอบ Header หรือใด ๆ
const FormForTest = (props: any) => {
  return {
    content: [],
    pageSize: `A4`,
    pageOrientation: `portrait`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
  }
};

const FALLBACK_PDF_PATHS: Record<string, string> = {
  price_evaluate: "/static/pdf/printList/price_evaluate.pdf",
  price_evaluate_en: "/static/pdf/printList/price_evaluate_en.pdf",
  price_evaluate_th: "/static/pdf/printList/price_evaluate_th.pdf",
};

/*=============================================
 *                   APIs
 *=============================================*/
const getOperatingOrderDetailByEmr = async (controller: any, { emrId }: any = {}) => {
  if (!emrId) {
    return;
  }
  const [data, error] = await controller.getOperatingOrderDetailByEmr({
    emrId: emrId,
  });
  // console.log("Print List Operating Order Data: ", data);
  return [data, error];
};

const getDoctorDetail = async (controller: any, { doctorId }: any = {}) => {
  if (!doctorId) {
    return;
  }
  const [data, error] = await controller.getDoctorDetail({
    doctorId: doctorId,
  });
  // console.log("Print List Doctor Detail Data: ", data);
  return [data, error];
};

const getEncounter = async (controller: any, encounterId: any) => {
  const [data, error] = await controller.getEncounter(encounterId);
  if (data) {
    return data;
  }
};

const getDoctorInfoFromEncounter = async (controller: any, encounterId: any) => {
  let encounterData = await getEncounter(controller, encounterId);

  if (encounterData) {
    let doctorInfo = await getDoctorDetail(controller, {
      doctorId: encounterData?.doctor,
    });

    if (doctorInfo) {
      return doctorInfo[0];
    }
  }
  return null;
};

const getPatientDetailView = async (controller: any, { patientId }: any = {}) => {
  if (!patientId) {
    return;
  }
  const [data, error] = await controller.getPatientDetailView({
    patientId: patientId,
  });
  // console.log("Print List Patient Detail Data: ", data);
  return [data, error];
};

const getDiagnosisMedicalRecordDetail = async (controller: any, { emrId }: any = {}) => {
  if (!emrId) {
    return;
  }
  const [data, error] = await controller.getDiagnosisMedicalRecordDetail({
    emrId: emrId,
  });
  // console.log("Print List Diagnosis Detail Data: ", data);
  return [data, error];
};

const getTreatmentDetail = async (controller: any, { code }: any = {}) => {
  if (!code) {
    return;
  }
  const [data, error] = await controller.getTreatmentDetail({
    code: code,
  });
  return [data, error];
};

const getHospitalNameForPrintList = async (controller: any, { language }: any = {}) => {
  const [data, error] = await controller.getHospitalNameForPrintList({
    language: language,
  });
  return [data, error];
};

const getPatientAllergyForPrintList = async (controller: any, { patientId }: any = {}) => {
  const [data, error] = await controller.getPatientAllergyForPrintList({
    patientId: patientId,
  });
  return [data, error];
};

const getPreNameForPrintList = async (controller: any, { language }: any = {}) => {
  const [data, error] = await controller.getPreNameForPrintList({ language: language });
  return [data, error];
};

const getAdmitOrderForPrintList = async (controller: any, { encounterId }: any = {}) => {
  const [data, error] = await controller.getAdmitOrderForPrintList({
    encounterId: encounterId,
  });
  return [data, error];
};

const getCudentMedCertForPrintList = async (controller: any, { encounterNumber, patientId, formCode }: any = {}) => {
  const [data, error] = await controller.getCudentMedCertForPrintList({
    encounterNumber: encounterNumber,
    patientId: patientId,
    formCode: formCode,
  });
  return [data, error];
};

const getBilEncounterSummaryForPrintList = async (controller: any, { patientId }: any = {}) => {
  const [data, error] = await controller.getBilEncounterSummaryForPrintList({
    patientId
  });
  return [data, error];
};

const getIpdMedicalBilForPrintList = async (controller: any, { encounterId }: any = {}) => {
  const [data, error] = await controller.getIpdMedicalBilForPrintList({
    encounterId
  });
  return [data, error];
};

/*=============================================
 *                   UTILS
 *=============================================*/
const mergePdfs = async (pdfBuffers: any[]) => {
  const pdfMerged = await PDFDocument.create();

  for (const pdfBuffer of pdfBuffers) {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const pages = await pdfMerged.copyPages(pdfDoc, pdfDoc.getPageIndices());
    pages.forEach((page) => pdfMerged.addPage(page));
  }

  const mergedPdfBytes = await pdfMerged.save();
  const getAsBase64 = await pdfMerged.saveAsBase64();
  const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
  const modifiedPdfUrl = URL.createObjectURL(blob);
  return { uint8Array: mergedPdfBytes, blobUrl: modifiedPdfUrl, blobRaw: blob, base64String: getAsBase64 };
};

/**
 * **Date format:** `"dd/mm/yyyy, hh:mm"`
 */
const getSliceDate = (date: string, locale?: string) => {
  const defaultLocale = locale || "th";
  let getDay,
    getMonth,
    getMonthName,
    getYear,
    getTime = null;

  switch (date.length) {
    case 10:
      getDay = date.slice(0, 2);
      getMonth = date.slice(3, 5);
      getMonthName = moment(getMonth, "MM").locale(defaultLocale).format("MMMM");
      getYear = date.slice(6, 10);
      break;
    case 16:
      getDay = date.slice(0, 2);
      getMonth = date.slice(3, 5);
      getMonthName = moment(getMonth, "MM").locale(defaultLocale).format("MMMM");
      getYear = date.slice(6, 10);
      getTime = date.slice(12, 16);
      break;
    case 17:
      getDay = date.slice(0, 2);
      getMonth = date.slice(3, 5);
      getMonthName = moment(getMonth, "MM").locale(defaultLocale).format("MMMM");
      getYear = date.slice(6, 10);
      getTime = date.slice(12, 17);
      break;
  }
  // [0] = Day, [1] = Month, [2] = MonthName, [3] = Year, [4] = Time
  return [getDay, getMonth, getMonthName, getYear, getTime];
};

/**
 * Age Calculate from Current data - birthdate
 *
 * **Note:** birth date and current date must be formatted by using **formatDateBack()** before
 */
const getAge = (birthDate: string, currentDate: string) => {
  const diffDate = moment(currentDate).diff(birthDate);
  var yearDuration = moment.duration(diffDate).years();
  var monthDuration = moment.duration(diffDate).months();
  var dayDuration = moment.duration(diffDate).days();
  // [0] = Year, [1] = Month, [2] = Day
  return [yearDuration, monthDuration, dayDuration];
};

/**
 * Object exists checking
 */
const emptyObjectCheck = (obj: any) => {
  return obj !== null && typeof obj === "object" && Object.keys(obj).length > 0;
};

/**
 * Get Prename from id
 */
const getPrenameFromId = (list: any, id: number) => {
  if (list) {
    let foundItem = list.items?.find((item: any) => item.id === id);
    return foundItem ? foundItem.name : "";
  }
  return "";
};

/**
 * Get Formatted Address (Present Address)
 */
const getFormattedAddress = (props: any) => {
  return {
    haveAddress: emptyObjectCheck(props.patientInfo?.present_address),
    no:
      props.patientInfo?.present_address?.no !== null
        ? props.patientInfo?.present_address?.no
        : " ",
    town:
      props.patientInfo?.present_address?.town !== null
        ? props.patientInfo?.present_address?.town
        : " ",
    name:
      props.patientInfo?.present_address?.name !== null
        ? props.patientInfo?.present_address?.name
        : " ",
    street:
      props.patientInfo?.present_address?.street !== null
        ? props.patientInfo?.present_address?.street
        : " ",
    road:
      props.patientInfo?.present_address?.road !== null
        ? props.patientInfo?.present_address?.road
        : " ",
    city:
      props.patientInfo?.present_address?.city_label !== null
        ? props.patientInfo?.present_address?.city_label
        : " ",
    district:
      props.patientInfo?.present_address?.district_label !== null
        ? props.patientInfo?.present_address?.district_label
        : " ",
    province:
      props.patientInfo?.present_address?.province_label !== null
        ? props.patientInfo?.present_address?.province_label
        : " ",
    zipcode:
      props.patientInfo?.present_address?.zipcode !== null
        ? props.patientInfo?.present_address?.zipcode
        : " ",
  };
};

/**
 * Visit Date for CU Dent Header: `"dd/mm/yyyy [hh:mm]" (default as BE)`
 */
const getFormatVisitDate = (admitDate: string | null, encounterDate: string | null, ad: boolean = false, noTime: boolean = false) => {
  if (!admitDate && !encounterDate) {
    return null;
  }

  const parseDate = (dateStr: string, isAdmit: boolean, ad: boolean, noTime: boolean): string => {
    const [datePart, timePart] = dateStr.split(" [");
    const [day, month, year] = datePart.split("/");
    const [hrs, min] = timePart.slice(0, -1).split(":");

    let formattedYear = year;

    if (ad) {
      formattedYear = isAdmit ? year : (parseInt(year) - 543).toString();
    } else {
      formattedYear = isAdmit ? (parseInt(year) + 543).toString() : year;
    }

    if (noTime) {
      return `${day}/${month}/${formattedYear}`;
    } else {
      return `${day}/${month}/${formattedYear} [${hrs}:${min}]`;
    }
  }

  // Note admit_date default เป็น ค.ศ. ส่วน encounter_created เป็น พ.ศ.
  if (admitDate) {
    return parseDate(admitDate, true, ad, noTime);
  }
  if (encounterDate) {
    return parseDate(encounterDate, false, ad, noTime);
  }

  return null;
};

const getFormatBirthDate = (birthDate: string | null, ad: boolean = false) => {
  if (!birthDate) {
    return null;
  }

  const [day, month, year] = birthDate.split("/");

  if (ad) {
    return `${day}/${month}/${parseInt(year) - 543}`;
  }

  return birthDate;
};

/*=============================================
 *                COMMON DATA
 *=============================================*/
const getRegDataList = async (props: any) => {
  const regDataList = {
    prenameTh: props.masterOptions?.prenameTh || [], // คำนำชื่อไทย
    prenameEn: props.masterOptions?.prenameEn || [], // คำนำชื่อ Eng
    bloodType: props.masterOptions?.bloodType || [], // หมู่เลือด
    nationality: props.masterOptions?.nationality || [], // สัญชาติ
    race: props.masterOptions?.race || [], // เชื้อชาติ
    religion: props.masterOptions?.religion || [], // ศาสนา
    belief: props.masterOptions?.belief || [], // ความเชื่อ
    marriage: MARRIAGE_STATUS, // สถานภาพสมรส
    education: props.masterOptions?.education || [], // ระดับการศึกษา
    birth_province: props.masterOptions?.province || [], // จังหวัดที่เกิด
    birth_country: props.masterOptions?.country || [], // ประเทศที่เกิด
  };
  return regDataList;
};

const getPatientNameInForm = async (props: any) => {
  let patientNameWithoutPrefix = `${props.patientInfo?.first_name} ${props.patientInfo?.last_name}`;
  let patientNameWithoutPrefixEN = `${props.patientInfo?.first_name_en} ${props.patientInfo?.last_name_en}`;
  let patientNameWithoutPrefixTH = `${props.patientInfo?.first_name_th} ${props.patientInfo?.last_name_th}`;
  let patientNameInFormEN =
    patientNameWithoutPrefixEN !== " "
      ? patientNameWithoutPrefixEN
      : patientNameWithoutPrefixTH !== " "
      ? patientNameWithoutPrefixTH
      : patientNameWithoutPrefix;
  let patientNameInFormTH =
    patientNameWithoutPrefixTH !== " " ? patientNameWithoutPrefixTH : patientNameWithoutPrefix;

  return {
    withPrefixTh: props.patientInfo?.full_name_th,
    withPrefixEn: props.patientInfo?.full_name_en,
    withoutPrefixTh: patientNameInFormTH,
    withoutPrefixEn: patientNameInFormEN,
  };
};

const getDoctorNameInForm = async (props: any, encounterId: any) => {
  let doctor = await getDoctorInfoFromEncounter(props.controller, encounterId);
  let prenameListEn = await getPreNameForPrintList(props.controller, { language: "EN" });
  let getPrename = getPrenameFromId(prenameListEn[0], doctor?.pre_name_en);
  let doctorNameWithPrefixTh = doctor ? `${doctor?.full_name}` : ` `;
  let doctorNameWithPrefixEn =
    doctor && getPrename
      ? `${getPrename}${doctor?.first_name_en ? `${doctor?.first_name_en} ` : ""}${doctor?.last_name_en}`
      : `${doctor?.first_name_en ? `${doctor?.first_name_en} ` : ""}${doctor?.last_name_en}`;
  let doctorNameWithoutPrefixEn = doctor ? `${doctor?.first_name_en} ${doctor?.last_name_en}` : ` `;
  let doctorNameWithoutPrefixTh = doctor ? `${doctor?.first_name} ${doctor?.last_name}` : ` `;
  let doctorNameWithoutPrefixInFormEn =
    doctorNameWithoutPrefixEn.trim().length > 0
      ? doctorNameWithoutPrefixEn
      : doctorNameWithoutPrefixTh.trim().length > 0
      ? doctorNameWithoutPrefixTh
      : doctorNameWithPrefixTh;
  let doctorNameWithoutPrefixInFormTh =
    doctorNameWithoutPrefixTh.trim().length > 0 ? doctorNameWithoutPrefixTh : doctor?.withPrefixTh;

  const doctorNameForm = {
    withPrefixTh: doctorNameWithPrefixTh,
    withPrefixEn: doctorNameWithPrefixEn,
    withoutPrefixTh: doctorNameWithoutPrefixInFormTh,
    withoutPrefixEn: doctorNameWithoutPrefixInFormEn,
  };

  return doctorNameForm;
};

const GetHospitalNameInForm = async (props: any, language: "th" | "en") => {
  const languageMapping = {
    th: "core_HOSPITAL_NAME",
    en: "core_HOSPITAL_NAME_EN"
  };

  const getHositalName = await getHospitalNameForPrintList(props.controller, {
    language: language === "th" ? LANGUAGE_USAGE.TH : LANGUAGE_USAGE.EN,
  });

  if(!getHositalName) {
    return null;
  }

  return getHositalName[0]?.result?.[languageMapping[language]];
};

const GetAnesthesiologistNameInForm = async (props: any, anesId: any, language: "th" | "en") => {
  if (!anesId) {
    console.warn("Print List: GetAnesthesiologist Anes Id Not Found! ", anesId);
    return;
  }

  const getAnesthesiologistName = await getDoctorDetail(props.controller, {
    doctorId: anesId,
  });

  if (!getAnesthesiologistName || !getAnesthesiologistName[0]) {
    return null;
  }

  const anesData = getAnesthesiologistName[0];

  // Format Name
  const anesWithoutPrefixEN = `${anesData?.first_name_en} ${anesData?.last_name_en}`;
  const anesWithoutPrefixTH = `${anesData?.first_name} ${anesData?.last_name}`;
  const anesWithPrefix = `${anesData?.full_name}`;

  if (language === "en") {
    return anesWithoutPrefixEN.trim() !== ""
      ? anesWithoutPrefixEN
      : anesWithoutPrefixTH.trim() !== ""
      ? anesWithoutPrefixTH
      : anesWithPrefix
  }

  return anesWithPrefix;
};

const GetOperatingOrderInForm = async (props: any, emrId: any, language: "th" | "en") => {
  if(!emrId) {
    console.warn("Print List: GetOperatingOrder EMR Not Found! ", emrId);
    return null;
  }

  const resultOperatingOrder = await getOperatingOrderDetailByEmr(props.controller, {
    emrId: emrId,
  });

  if (!resultOperatingOrder || !resultOperatingOrder[0]) {
    console.warn("Print List: GetOperatingOrder is Null! ")
    return null;
  };

  const operatingData = resultOperatingOrder[0];
  const anesTeams = operatingData.anesthesia_teams || [];

  // Get Anesthesiologist
  const anesId = anesTeams[0]?.anesthesiologist || null;
  const anesName = await GetAnesthesiologistNameInForm(props, anesId, language) || " ";

  // Get Operating and ICD
  const operatingSummary = operatingData?.pre_operation_summary || " ";
  const diagnosisSummary = operatingData?.preoperative_diagnosis_summary || " ";
  const operationRegExp = operatingSummary?.match(/1\. ([^\n]+)/) || " ";
  const icd9RegExp = diagnosisSummary?.match(/2\. ([^\n]+)/) || " ";
  const icd10RegExp = diagnosisSummary?.match(/1\. ([^\n]+)/) || " ";
  const operationOneLine = `${operationRegExp[1] ?? ""} ${icd9RegExp[1] ?? ""} ${icd10RegExp[1] ?? ""}` || " ";
  const primaryDoctor = operatingData?.primary_doctor_name || "";
  const operatingRoom =  operatingData?.operating_room_no || " ";

  const operatingDetailData = {
    operation_summary: operatingData.pre_operation_summary,
    diagnosis_summary: operatingData.preoperative_diagnosis_summary,
    operation_regexp: operationRegExp,
    icd9_regexp: icd9RegExp,
    icd10_regexp: icd10RegExp,
    operation_one_line: operationOneLine,
    anes_name: anesName,
    primary_doctor_name: primaryDoctor,
    operating_room_no: operatingRoom,
    formattedOperation: null,
  };

  // Get Treatment Data
  const treatmentCodeReExp = operatingSummary?.match(/\[([^\]]+)\]/);
  const treatmentCode = treatmentCodeReExp ? treatmentCodeReExp[1] : null;

  if (treatmentCode) {
    const getTreatmentData = await getTreatmentDetail(props.controller, {
      code: treatmentCode,
    });

    const treatmentItem = getTreatmentData?.[0]?.items?.[0] || {};

    const treatmentName = language === "en" 
      ? treatmentItem?.name_en.trim() || treatmentItem?.name.trim()
      : treatmentItem?.name.trim();

    const formattedTreatment = treatmentName
      ? `[${treatmentCode}] ${treatmentName}`
      : treatmentItem?.name_code;

    operatingDetailData.formattedOperation = formattedTreatment;
  } else {
    operatingDetailData.formattedOperation = operatingDetailData.operation_regexp[1] || " "
  }

  // console.log("Yeti OperatingDetailData: ", operatingDetailData);

  return operatingDetailData;
}

const GetDiagnosisTreatmentInForm = async (props: any, emrId: any) => {
  if(!emrId) {
    console.warn("Print List: GetDiagnosisTreatment EMR Not Found! ", emrId);
    return null;
  }

  const resultDiagnosisTreatment = await getDiagnosisMedicalRecordDetail(
    props.controller,
    { emrId: emrId }
  );

  if (!resultDiagnosisTreatment) {
    console.warn("Print List: GetDiagnosisTreatment is Null! ")
    return null;
  }

  const diagnosisTreatmentData = resultDiagnosisTreatment[0];
  const principalDiagnosis = diagnosisTreatmentData?.principal_diagnosis?.[0] || {};
  const icd10Code = principalDiagnosis?.icd_code || " ";
  const icd10Term = principalDiagnosis?.icd_term || " ";
  const icd10MedTerm = principalDiagnosis?.icd10_med_term || " ";

  const diagnosisDetailData = {
    principal_diagnosis: principalDiagnosis,
    icd10_code: icd10Code,
    icd10_term: icd10Term,
    icd10_med_term: icd10MedTerm,
  };

  return diagnosisDetailData;
};

const GetAllergyInForm = async (props: any) => {
  if (!props.patientInfo) {
    console.warn("Print List: GetAllergy Patient not Found! ");
    return null;
  }

  const resultPatientAllergy = await getPatientAllergyForPrintList(props.controller, {
    patientId: props.patientInfo?.id,
  });

  if (!resultPatientAllergy) {
    console.warn("Print List: GetAllergy is Null!");
    return null;
  }

  const patientAllergyData = resultPatientAllergy[0];

  // Format Allergy
  const formattedPatientAllergy = patientAllergyData?.items?.filter((item: any) => item.status !== "NO_REACTION" && item.name.length > 0)
  .map((item: any) => {
    if (item.type_name_name === "DRUG") {
      item.name = `${item.name} (${item.adversary_type?.toLowerCase()})`;
    }
    return item.name;
  }).join(", ") || " ";

  // console.log("Yeti Allergy Data: ", {ori: patientAllergyData, format: formattedPatientAllergy});

  return {
    allergyData: patientAllergyData,
    formattedAllergyData: formattedPatientAllergy,
  };
};

const GetDoctorCertNumberInForm = async (props: any) => {
  if (!props.patientInfo) {
    console.warn("Print List: GetDoctorCertNumber Doctor not Found!");
    return null;
  }

  const getDoctorId = props.masterOptions?.doctor.filter((item: any) => {
    return new RegExp(props.patientInfo?.doctor_name).test(item.text);
  }).map((item: any) => item.value);

  const resultDoctorCertNumber = await getDoctorDetail(props.controller, {
    doctorId: getDoctorId[0],
  });

  if (!resultDoctorCertNumber) {
    console.warn("Print List: GetDoctorCertNumber is Null");
    return null;
  }

  // console.log("Yeti Doctor Cert Number: ", resultDoctorCertNumber[0]?.certificate_no);
  return resultDoctorCertNumber[0]?.certificate_no || " ";
};

const GetNationalityInForm = (props: any) => {
  if (!props.patientInfo) {
    console.warn("Print List: GetNationality Patient not Found!");
    return null;
  }
  
  const nationalityText = props.masterOptions?.nationality.filter((item: any) => {
    return item.value === props.patientInfo?.nationality;
  }).map((item: any) => item.text);

  if (!nationalityText) {
    console.warn("Print List: GetNationality is Null!");
    return null;
  }

  const getNationality = nationalityText[0].split(" ");

  return {
    nationalityTh: getNationality[getNationality.length - 1] || " ",
    nationalityEn: getNationality[0] || " ",
  }
};

const GetAdmitOrderInForm = async (props: any, encounterId: any) => {
  if (!encounterId) {
    console.warn("Print List: GetAdmitOrder Encounter not Found!");
    return null;
  }

  const resultAdmitOrder = await getAdmitOrderForPrintList(props.controller, {
    encounterId: encounterId,
  });

  if (!resultAdmitOrder) {
    console.warn("Print List: GetAdmitOrder is Null!");
    return null;
  }

  return resultAdmitOrder[0]?.items[0] || {};
};

const GetCudentMedCertInForm = async (props: any, encounterNumber: any, patientId: any) => {
  if (!encounterNumber && !patientId) {
    console.warn("Print List: GetCudentMedCert Encounter or Patient not Found!");
    return null;
  }

  // Func
  const sortByCreated = (items: any[]) => items?.sort((a, b) => new Date(a?.created_utc).getTime() - new Date(b?.created_utc).getTime());
  const getLastItem = (items: any[]) => items.length > 0 ? items[items.length - 1] : null;

  const getMedCert = async (formCode: string) => {
    const resp = await getCudentMedCertForPrintList(props.controller, {
      encounterNumber,
      patientId,
      formCode,
    });

    if (resp[1]) {
      console.error(`Print List: Error to get ${formCode} Med Cert: `, resp[1]);
      return [];
    }

    return resp[0]?.items || [];
  };

  const getLatestMedCerts = async () => {
    const [cudentLatest, dentistLatest] = await Promise.all([
      getCudentMedCertForPrintList(props.controller, {
        patientId,
        formCode: "MedicalCertificateCUDent",
      }),
      getCudentMedCertForPrintList(props.controller, {
        patientId,
        formCode: "CardDentistCertificate",
      }),
    ]);

    const cudentItems = cudentLatest[1] ? [] : cudentLatest[0]?.items || [];
    const dentistItems = dentistLatest[1] ? [] : dentistLatest[0]?.items || [];

    return [...cudentItems, ...dentistItems];
  };

  const [cudentMedCert, dentistMedCert] = await Promise.all([
    getMedCert("MedicalCertificateCUDent"),
    getMedCert("CardDentistCertificate"),
  ]);

  const combinedMedCerts = sortByCreated([...cudentMedCert, ...dentistMedCert]);

  if (!getLastItem(combinedMedCerts)) {
    console.warn("Print List: Med Cert not found from this encounter number! ", encounterNumber);

    // Get Latest Med Cert
    const latestMedCerts = await getLatestMedCerts();
    const sortedLatestMedCerts = sortByCreated(latestMedCerts);

    if (!getLastItem(sortedLatestMedCerts)) {
      console.warn("Print List: Med Cert not found!");
      return null;
    }

    return {
      code: getLastItem(sortedLatestMedCerts)?.id || "-",
      data: getLastItem(sortedLatestMedCerts) || {},
    }
  }

  return {
    code: getLastItem(combinedMedCerts)?.id || "-",
    data: getLastItem(combinedMedCerts) || {},
  }
};

const GetBilEncounterSummaryInForm = async (props: any, patientId: any, encounterNumber: any) => {
  if (!patientId && !encounterNumber) {
    console.warn("Print List: GetBilEncounterSummary Patient or Encounter Number not Found!");
    return null;
  }

  const [resp, error] = await getBilEncounterSummaryForPrintList(props.controller, {
    patientId
  });

  if (resp) {
    const respList = resp.items;
    return respList?.filter((item: any) => item.number === encounterNumber)[0] || {};
  }

  return null;
};

const GetIpdMedicalBilInForm = async (props: any, encounterId: any) => {
  if (!encounterId) {
    console.warn("Print List: GetIpdMedicalBil Encounter not Found!");
    return null;
  }

  const [resp, error] = await getIpdMedicalBilForPrintList(props.controller, {
    encounterId,
  });

  if (resp) {
    return resp;
  }

  return null;
};

/*=============================================
 *               GET FORM DATA
 *=============================================*/
const GetFormLivingWillData = (props: any, preparedData: PreparedDataType) => {

  const formName = "หนังสือแสดงเจตนาไม่ประสงค์จะรับบริการสาธารณสุขที่เป็นเพียง\nเพื่อยืดการตายในวาระสุดท้ายของชีวิต หรือเพื่อยุติการทรมานจากการเจ็บป่วย";

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      printDay: preparedData.prepCreatedDateTh[0],
      printMonth: preparedData.prepCreatedDateTh[2],
      printYear: preparedData.prepCreatedDateTh[3],
      formAddress: preparedData.prepPatientAddress,
      formatPatient: preparedData.prepPatientName?.withoutPrefixTh,
      formatDoctor: preparedData.prepDoctorName?.withoutPrefixTh,
      doctorFullName: preparedData.prepDoctorName?.withPrefixTh,
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      haveHeader: preparedData.haveHeader,
      printer: props.printer || "-",
    },
  };

  return {
    data: data,
    formName: formName,
    language: "th"
  };
};

const GetFormConsentPersonalHealthData = (props: any, preparedData: PreparedDataType) => {

  const formName = "หนังสือแสดงความยินยอมให้เปิดเผยข้อมูลด้านสุขภาพ\nของบุคคลทางอิเล็กทรอนิกส์";

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      printDay: preparedData.prepCreatedDateTh[0],
      printMonth: preparedData.prepCreatedDateTh[2],
      printYear: preparedData.prepCreatedDateTh[3],
      printTime: preparedData.prepCreatedDateTh[4],
      formAddress: preparedData.prepPatientAddress,
      formatPatient: preparedData.prepPatientName?.withoutPrefixTh,
      formatDoctor: preparedData.prepDoctorName?.withoutPrefixTh,
      doctorFullName: preparedData.prepDoctorName?.withPrefixTh,
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      haveHeader: preparedData.haveHeader,
      printer: props.printer || "-",
    },
  };

  return {
    data: data,
    formName: formName,
    language: "th"
  };
};

const GetFormConsentDrugScreenData = (props: any, preparedData: PreparedDataType, language: "th" | "en") => {

  const formName = language === "th" ? "หนังสือแสดงการยินยอมตรวจหาสารเสพติด" : "Consent Drug Screen Test";

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      patientBirthdateEN: language === "en" ? preparedData.prepPatientBirthDateEn : null,
      formatPatient: language === "th" ? preparedData.prepPatientName?.withoutPrefixTh : preparedData.prepPatientName?.withoutPrefixEn,
      formatDoctor: language === "th" ? preparedData.prepDoctorName?.withoutPrefixTh : preparedData.prepDoctorName?.withoutPrefixEn,
      doctorFullName: language === "th" ? preparedData.prepDoctorName?.withPrefixTh : preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
      signedDate: language === "th" ? preparedData.prepSignedDateTh[0] : preparedData.prepSignedDateEn[0],
      signedTime: language === "th" ? `${preparedData.prepSignedDateTh[1]} น.` : preparedData.prepSignedDateEn[1],
      haveHeader: preparedData.haveHeader,
      printer: props.printer || "-",
    }
  };

  return {
    data: data,
    formName: formName,
    language: language
  };
}

const GetFormConsentNarcoticUseData = (props: any, preparedData: PreparedDataType, language: "th" | "en") => {

  const formName = language === "th" ? "หนังสือแสดงความยินยอมรับการรักษาด้วยยารักษาอาการปวด\nชนิดเสพติดในอาการปวดเรื้อรังที่ไม่ใช่มะเร็ง" : "Consent for Narcotic Use In Chronic Non-Cancer Pain";

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      patientBirthdateEN: language === "en" ? preparedData.prepPatientBirthDateEn : null,
      formatPatient: language === "th" ? preparedData.prepPatientName?.withoutPrefixTh : preparedData.prepPatientName?.withoutPrefixEn,
      formatDoctor: language === "th" ? preparedData.prepDoctorName?.withoutPrefixTh : preparedData.prepDoctorName?.withoutPrefixEn,
      doctorFullName: language === "th" ? preparedData.prepDoctorName?.withPrefixTh : preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
      signedDate: language === "th" ? preparedData.prepSignedDateTh[0] : preparedData.prepSignedDateEn[0],
      signedTime: language === "th" ? `${preparedData.prepSignedDateTh[1]} น.` : preparedData.prepSignedDateEn[1],
      haveHeader: preparedData.haveHeader,
      printer: props.printer || "-",
    }
  };

  return {
    data: data,
    formName: formName,
    language: language
  };
};

const GetFormConsentHIVTestData = (props: any, preparedData: PreparedDataType, language: "th" | "en") => {

  const formName = language === "th" ? "หนังสือแสดงความยินยอมการตรวจวินิจฉัยเพื่อหาเชื้อเอชไอวี" : "Informed Consent to HIV Blood Test";

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      patientBirthdateEN: language === "en" ? preparedData.prepPatientBirthDateEn : null,
      formatPatient: language === "th" ? preparedData.prepPatientName?.withoutPrefixTh : preparedData.prepPatientName?.withoutPrefixEn,
      formatDoctor: language === "th" ? preparedData.prepDoctorName?.withoutPrefixTh : preparedData.prepDoctorName?.withoutPrefixEn,
      doctorFullName: language === "th" ? preparedData.prepDoctorName?.withPrefixTh : preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
      signedDate: language === "th" ? preparedData.prepSignedDateTh[0] : preparedData.prepSignedDateEn[0],
      signedTime: language === "th" ? `${preparedData.prepSignedDateTh[1]} น.` : preparedData.prepSignedDateEn[1],
      haveHeader: preparedData.haveHeader,
      printer: props.printer || "-",
    }
  };

  return {
    data: data,
    formName: formName,
    language: language
  };
};

const GetFormConsentBloodTransfusionData = (props: any, preparedData: PreparedDataType, language: "th" | "en") => {

  const formName = language === "th" ? "หนังสือแสดงความยินยอมการรับโลหิต และ/หรือ ส่วนประกอบของโลหิต" : "Consent for Blood/Blood Product Transfusion";

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      patientBirthdateEN: language === "en" ? preparedData.prepPatientBirthDateEn : null,
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      formatPatient: language === "th" ? preparedData.prepPatientName?.withoutPrefixTh : preparedData.prepPatientName?.withoutPrefixEn,
      formatDoctor: language === "th" ? preparedData.prepDoctorName?.withoutPrefixTh : preparedData.prepDoctorName?.withoutPrefixEn,
      doctorFullName: language === "th" ? preparedData.prepDoctorName?.withPrefixTh : preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
      signedDate: language === "th" ? preparedData.prepSignedDateTh[0] : preparedData.prepSignedDateEn[0],
      signedTime: language === "th" ? `${preparedData.prepSignedDateTh[1]} น.` : preparedData.prepSignedDateEn[1],
      haveHeader: preparedData.haveHeader,
      printer: props.printer || "-",
    }
  };

  return {
    data: data,
    formName: formName,
    language: language
  };
};

const GetFormConsentPreSedationData = async (props: any, emrId: any, preparedData: PreparedDataType, language: "th" | "en") => {

  const formName = language === "th" ? "หนังสือยินยอมก่อนการให้ยาระงับปวด\nหรือยาระงับความรู้สึกตัวการทำหัตถการ/การผ่าตัด" : "Pre Procedural Sedation and Pre Anesthesia\nInformed Consent";

  // Get Hospital
  const getHospitalName = await GetHospitalNameInForm(props, language);

  // Get Operating Order Data
  const getOperatingOrderData = await GetOperatingOrderInForm(props, emrId, language);

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      patientBirthdateEN: language === "en" ? preparedData.prepPatientBirthDateEn : null,
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      formatPatient: language === "th" ? preparedData.prepPatientName?.withoutPrefixTh : preparedData.prepPatientName?.withoutPrefixEn,
      formatDoctor: language === "th" ? preparedData.prepDoctorName?.withoutPrefixTh : preparedData.prepDoctorName?.withoutPrefixEn,
      doctorFullName: language === "th" ? preparedData.prepDoctorName?.withPrefixTh : preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
      signedDate: language === "th" ? preparedData.prepSignedDateTh[0] : preparedData.prepSignedDateEn[0],
      signedTime: language === "th" ? `${preparedData.prepSignedDateTh[1]} น.` : preparedData.prepSignedDateEn[1],
      haveHeader: preparedData.haveHeader,
      hospital: getHospitalName,
      operatingData: getOperatingOrderData,
      formatOperation: language === "en" ? getOperatingOrderData?.formattedOperation : getOperatingOrderData?.operation_regexp[1] || " ",
      formatIcd9: getOperatingOrderData?.icd9_regexp[1] || " ",
      formatIcd10: getOperatingOrderData?.diagnosis_summary?.slice(3) || " ",
      formatAnes: getOperatingOrderData?.anes_name || " ",
      printer: props.printer || "-",
    }
  };

  return {
    data: data,
    formName: formName,
    language: language
  };
};

const GetFormConsentOperatingData = async (props: any, emrId: any, preparedData: PreparedDataType, language: "th" | "en") => {

  const formName = language === "th" ? "หนังสือแสดงความยินยอมการผ่าตัดหรือการทำหัตถการพิเศษ" : ""; //*

  // Get Operating Order Data
  const getOperatingOrderData = await GetOperatingOrderInForm(props, emrId, language);
  const operationOneLine = getOperatingOrderData?.operation_one_line?.trim() || "";

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      patientBirthdateEN: language === "en" ? preparedData.prepPatientBirthDateEn : null,
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      formatPatient: language === "th" ? preparedData.prepPatientName?.withoutPrefixTh : preparedData.prepPatientName?.withoutPrefixEn,
      formatDoctor: language === "th" ? preparedData.prepDoctorName?.withoutPrefixTh : preparedData.prepDoctorName?.withoutPrefixEn,
      doctorFullName: language === "th" ? preparedData.prepDoctorName?.withPrefixTh : preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
      signedDate: language === "th" ? preparedData.prepSignedDateTh[0] : preparedData.prepSignedDateEn[0],
      signedTime: language === "th" ? `${preparedData.prepSignedDateTh[1]} น.` : preparedData.prepSignedDateEn[1],
      haveHeader: preparedData.haveHeader,
      operatingData: getOperatingOrderData,
      enableDecorationOperation: operationOneLine.length > 0,
      formatOperation: operationOneLine.length > 0 
        ? operationOneLine
        : "...................................................................................................................................................................................................................\n...................................................................................................................................................................................................................",
      printer: props.printer || "-",
    }
  };

  return {
    data: data,
    formName: formName,
    language: language
  };
};

const GetFormConsentTreatmentProcedureData = async (props: any, emrId: any, preparedData: PreparedDataType) => {
  const formName = "หนังสือรับทราบ และยินยอมรับการรักษา ฟื้นฟู หรือทำหัตถการ";

  // Get Diagnosis Treatment Data
  const getDiagnosisTreatmentData = await GetDiagnosisTreatmentInForm(props, emrId);

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      formatPatient: preparedData.prepPatientName?.withoutPrefixTh,
      formatDoctor: preparedData.prepDoctorName?.withoutPrefixTh,
      doctorFullName: preparedData.prepDoctorName?.withPrefixTh,
      printDay: preparedData.prepCreatedDateTh[0],
      printMonth: preparedData.prepCreatedDateTh[2],
      printYear: preparedData.prepCreatedDateTh[3],
      printTime: preparedData.prepCreatedDateTh[4],
      diagnosisData: getDiagnosisTreatmentData,
      formatIcd10Code: getDiagnosisTreatmentData?.icd10_code,
      formatIcd10Term: getDiagnosisTreatmentData?.icd10_term,
      haveHeader: preparedData.haveHeader,
      printer: props.printer || "-",
    }
  };

  return {
    data: data,
    formName: formName,
    language: "th"
  };
};

const GetFormNewPatientRegistrationData = async (props: any, preparedData: PreparedDataType) => {

  // Get Hospital
  const getHospitalName = await GetHospitalNameInForm(props, "th");

  // Get Allergy
  const getAllergy = await GetAllergyInForm(props);

  // Get Reg Data
  const regData = await getRegDataList(props);

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      formatPatient: preparedData.prepPatientName?.withoutPrefixTh,
      formatDoctor: preparedData.prepDoctorName?.withoutPrefixTh,
      doctorFullName: preparedData.prepDoctorName?.withPrefixTh,
      printDay: preparedData.prepCreatedDateTh[0],
      printMonth: preparedData.prepCreatedDateTh[2],
      printYear: preparedData.prepCreatedDateTh[3],
      printTime: preparedData.prepCreatedDateTh[4],
      hospital: getHospitalName,
      allergy: getAllergy?.formattedAllergyData,
      regData: regData,
      citizen_split:
        props.patientInfo?.citizen_no.length > 0 &&
        props.patientInfo?.citizen_no !== "Non citizen identity"
          ? props.patientInfo?.citizen_no?.split("")
          : [],
      zipcode_split:
        props.patientInfo?.present_address !== null &&
        Object.keys(props.patientInfo?.present_address).length > 0
          ? props.patientInfo?.present_address?.zipcode.split("")
          : [],
      owner_split:
        props.patientInfo?.relative_address !== null &&
        Object.keys(props.patientInfo?.relative_address).length > 0
          ? props.patientInfo?.relative_address?.owner_name.split(" ")
          : [],
      relative_zipcode_split:
        props.patientInfo?.relative_address !== null &&
        Object.keys(props.patientInfo?.relative_address).length > 0
          ? props.patientInfo?.relative_address?.zipcode.split("")
          : [],
      haveHeader: preparedData.haveHeader,
      printer: props.printer || "-",
    }
  };

  return {
    data: data,
    formName: "",
    language: "th"
  };
};

const GetFormConsentAMAData = (props: any, preparedData: PreparedDataType, language: "th" | "en") => {

  const formName = language === "th" ? "หนังสือการปฏิเสธการรักษา" : "Do Not Resuscitate Request (TH)";

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      patientBirthdateEN: language === "en" ? preparedData.prepPatientBirthDateEn : null,
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      formatPatient: language === "th" ? preparedData.prepPatientName?.withoutPrefixTh : preparedData.prepPatientName?.withoutPrefixEn,
      formatDoctor: language === "th" ? preparedData.prepDoctorName?.withoutPrefixTh : preparedData.prepDoctorName?.withoutPrefixEn,
      doctorFullName: language === "th" ? preparedData.prepDoctorName?.withPrefixTh : preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
      signedDate: language === "th" ? preparedData.prepSignedDateTh[0] : preparedData.prepSignedDateEn[0],
      signedTime: language === "th" ? `${preparedData.prepSignedDateTh[1]} น.` : preparedData.prepSignedDateEn[1],
      printDay: language === "th" ? preparedData.prepCreatedDateTh[0] : preparedData.prepCreatedDateEn[0],
      printMonth: language === "th" ? preparedData.prepCreatedDateTh[2] : preparedData.prepCreatedDateEn[2],
      printYear: language === "th" ? preparedData.prepCreatedDateTh[3] : preparedData.prepCreatedDateEn[3],
      printTime: language === "th" ? preparedData.prepCreatedDateTh[4] : preparedData.prepCreatedDateEn[4],
      haveHeader: preparedData.haveHeader,
      printer: props.printer || "-",
    },
  };

  return {
    data: data,
    formName: formName,
    language: language
  };
};

const GetFormDNRData = (props: any, preparedData: PreparedDataType, language: "th" | "en") => {
  const formName = language === "th" ? "Do Not Resuscitate Request (TH)" : "Do Not Resuscitate Request (EN)";

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      patientBirthdateEN: language === "en" ? preparedData.prepPatientBirthDateEn : null,
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      formatPatient: language === "th" ? preparedData.prepPatientName?.withoutPrefixTh : preparedData.prepPatientName?.withoutPrefixEn,
      formatDoctor: language === "th" ? preparedData.prepDoctorName?.withoutPrefixTh : preparedData.prepDoctorName?.withoutPrefixEn,
      doctorFullName: language === "th" ? preparedData.prepDoctorName?.withPrefixTh : preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
      signedDate: language === "th" ? preparedData.prepSignedDateTh[0] : preparedData.prepSignedDateEn[0],
      signedTime: language === "th" ? `${preparedData.prepSignedDateTh[1]} น.` : preparedData.prepSignedDateEn[1],
      printDay: language === "th" ? preparedData.prepCreatedDateTh[0] : preparedData.prepCreatedDateEn[0],
      printMonth: language === "th" ? preparedData.prepCreatedDateTh[2] : preparedData.prepCreatedDateEn[2],
      printYear: language === "th" ? preparedData.prepCreatedDateTh[3] : preparedData.prepCreatedDateEn[3],
      printTime: language === "th" ? preparedData.prepCreatedDateTh[4] : preparedData.prepCreatedDateEn[4],
      haveHeader: preparedData.haveHeader,
      printer: props.printer || "-",
    }
  };

  return {
    data: data,
    formName: formName,
    language: language
  };
};

const GetFormLivingWillDataNewData = async (props: any, preparedData: PreparedDataType, language: "th" | "en") => {

  const formName = language === "th" ? "Living Will (TH)" : "Living Will (EN)";

  // Get Doctor Cert Number
  const getDoctorCertNumber = await GetDoctorCertNumberInForm(props);

  // Get Patient Nationality
  const getPatientNationality = GetNationalityInForm(props);

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      patientBirthdateEN: language === "en" ? preparedData.prepPatientBirthDateEn : null,
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      formatPatient: language === "th" ? preparedData.prepPatientName?.withoutPrefixTh : preparedData.prepPatientName?.withoutPrefixEn,
      formatDoctor: language === "th" ? preparedData.prepDoctorName?.withoutPrefixTh : preparedData.prepDoctorName?.withoutPrefixEn,
      doctorFullName: language === "th" ? preparedData.prepDoctorName?.withPrefixTh : preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
      signedDate: language === "th" ? preparedData.prepSignedDateTh[0] : preparedData.prepSignedDateEn[0],
      signedTime: language === "th" ? `${preparedData.prepSignedDateTh[1]} น.` : preparedData.prepSignedDateEn[1],
      printDay: language === "th" ? preparedData.prepCreatedDateTh[0] : preparedData.prepCreatedDateEn[0],
      printMonth: language === "th" ? preparedData.prepCreatedDateTh[2] : preparedData.prepCreatedDateEn[2],
      printYear: language === "th" ? preparedData.prepCreatedDateTh[3] : preparedData.prepCreatedDateEn[3],
      printTime: language === "th" ? preparedData.prepCreatedDateTh[4] : preparedData.prepCreatedDateEn[4],
      patientNationality: language === "th" ? getPatientNationality?.nationalityTh : getPatientNationality?.nationalityEn,
      doctorNumber: getDoctorCertNumber,
      haveHeader: preparedData.haveHeader,
      printer: props.printer || "-",
    }
  };

  return {
    data: data,
    formName: formName,
    language: language
  };
};

const GetFormPriceEvaluateDetailData = async (props: any, preparedData: PreparedDataType) => {

  // Get Doctor Cert Number
  const getDoctorCertNumber = await GetDoctorCertNumberInForm(props);

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      doctorNumber: getDoctorCertNumber,
      formatPatient: preparedData.prepPatientName?.withoutPrefixEn,
      formatDoctor: preparedData.prepDoctorName?.withoutPrefixEn,
      doctorFullName: preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
      printer: props.printer || "-",
    }
  };

  return data;
};

const GetFormPriceEvaluateData = (props: any, preparedData: PreparedDataType, language: "th" | "en") => {

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      patientBirthdateEN: language === "en" ? preparedData.prepPatientBirthDateEn : null,
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      formatPatient: language === "th" ? preparedData.prepPatientName?.withoutPrefixTh : preparedData.prepPatientName?.withoutPrefixEn,
      formatDoctor: language === "th" ? preparedData.prepDoctorName?.withoutPrefixTh : preparedData.prepDoctorName?.withoutPrefixEn,
      doctorFullName: language === "th" ? preparedData.prepDoctorName?.withPrefixTh : preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
      signedDate: language === "th" ? preparedData.prepSignedDateTh[0] : preparedData.prepSignedDateEn[0],
      printDay: language === "th" ? preparedData.prepCreatedDateTh[0] : preparedData.prepCreatedDateEn[0],
      printMonth: language === "th" ? preparedData.prepCreatedDateTh[2] : preparedData.prepCreatedDateEn[2],
      printYear: language === "th" ? preparedData.prepCreatedDateTh[3] : preparedData.prepCreatedDateEn[3],
      printTime: language === "th" ? preparedData.prepCreatedDateTh[4] : preparedData.prepCreatedDateEn[4],
      formatGender: language === "th" ? GENDER_LIST[props.patientInfo?.gender].name_th : GENDER_LIST[props.patientInfo?.gender].name_en || props.patientInfo?.gender,
      printer: props.printer || "-",
    }
  };

  return data;
};

const GetFormConsentOperatingStaticData = (props: any, preparedData: PreparedDataType) => {

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      patientBirthdateEN: preparedData.prepPatientBirthDateEn,
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      formatPatient: preparedData.prepPatientName?.withoutPrefixEn,
      formatDoctor: preparedData.prepDoctorName?.withoutPrefixEn,
      doctorFullName: preparedData.prepDoctorName?.withPrefixEn,
      signedDate: preparedData.prepSignedDateEn[0],
      printDay: preparedData.prepCreatedDateEn[0],
      printMonth: preparedData.prepCreatedDateEn[2],
      printYear: preparedData.prepCreatedDateEn[3],
      printTime: preparedData.prepCreatedDateEn[4],
      formatGender: GENDER_LIST[props.patientInfo?.gender].name_en || props.patientInfo?.gender,
      printer: props.printer || "-",
    }
  };

  return data;
};

const GetFormPriceEstimateData = async (props: any, emrId: any, preparedData: PreparedDataType) => {

  // Get Patient Allergy
  const getAllergy = await GetAllergyInForm(props);

  // Get Operating Data
  const getOperatingOrderData = await GetOperatingOrderInForm(props, emrId, "th");

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      formatPatient: preparedData.prepPatientName?.withoutPrefixTh,
      formatDoctor: preparedData.prepDoctorName?.withoutPrefixTh,
      doctorFullName: preparedData.prepDoctorName?.withPrefixTh,
      signedDate: preparedData.prepSignedDateTh[0],
      printDay: preparedData.prepCreatedDateTh[0],
      printMonth: preparedData.prepCreatedDateTh[2],
      printYear: preparedData.prepCreatedDateTh[3],
      printTime: preparedData.prepCreatedDateTh[4],
      formatGender: GENDER_LIST[props.patientInfo?.gender].name_th || props.patientInfo?.gender,
      patientAllergy: getAllergy?.formattedAllergyData,
      attendingDoctor: getOperatingOrderData?.primary_doctor_name || preparedData.prepDoctorName?.withoutPrefixTh,
      operatingRoom: getOperatingOrderData?.operating_room_no,
      printer: props.printer || "-",
    }
  };

  return data;
};

const GetFormClaimForInsurance = async (props: any, encounterId: any, preparedData: PreparedDataType) => {

  // Get Encounter Data
  const getEncounterData = await getEncounter(props.controller, encounterId);

  // Get Admit Order (IPD Case)
  const getAdmitOrderData = await GetAdmitOrderInForm(props, encounterId);
  
  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      signedDate: preparedData.prepSignedDateTh[0],
      printDay: preparedData.prepCreatedDateTh[0],
      printMonth: preparedData.prepCreatedDateTh[2],
      printYear: preparedData.prepCreatedDateTh[3],
      printTime: preparedData.prepCreatedDateTh[4],
      formatPatient: preparedData.prepPatientName?.withoutPrefixTh,
      patientFullName: preparedData.prepPatientName?.withPrefixTh,
      formatDoctor: preparedData.prepDoctorName?.withoutPrefixTh,
      doctorFullName: preparedData.prepDoctorName?.withPrefixTh,
      formatPatientEn: preparedData.prepPatientName?.withoutPrefixEn,
      patientFullNameEn: preparedData.prepPatientName?.withPrefixEn,
      formatDoctorEn: preparedData.prepDoctorName?.withoutPrefixEn,
      doctorFullNameEn: preparedData.prepDoctorName?.withPrefixEn,
      formatGender: GENDER_LIST[props.patientInfo?.gender].name_th || props.patientInfo?.gender,
      admitOrderData: getAdmitOrderData,
      underlyingDisease: getEncounterData ? getEncounterData?.underlying_disease : null,
      opdDischarge: getEncounterData ? getEncounterData?.ended : null,
      printer: props.printer || "-",
    }
  };

  return data;
};

const GetFormCudentHeaderFormData = (props: any, preparedData: PreparedDataType) => {

  const formName = "";

  const data = {
    items: {
      ...props.patientInfo,
      patientEncounter: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      formatVisitDate: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created) || "-",
      printDay: preparedData.prepCreatedDateTh[0],
      printMonth: preparedData.prepCreatedDateTh[2],
      printYear: preparedData.prepCreatedDateTh[3],
      formAddress: preparedData.prepPatientAddress,
      formatPatient: preparedData.prepPatientName?.withoutPrefixTh,
      formatDoctor: preparedData.prepDoctorName?.withoutPrefixTh,
      doctorFullName: preparedData.prepDoctorName?.withPrefixTh,
      ageYear: preparedData.prepPatientAge[0],
      ageMonth: preparedData.prepPatientAge[1],
      ageDay: preparedData.prepPatientAge[2],
      haveHeader: true, //* For Get Cudent Header
      printer: props.printer || "-",
    },
  };

  return {
    data: data,
    formName: formName,
    language: "th"
  };
};

const GetPrintListLandscapeData = async (props: any, encounterId: any, preparedData: PreparedDataType) => {

  // Get Encounter Data
  const getEncounterData = await getEncounter(props.controller, encounterId);

  // Get Admit Order (IPD Case)
  const getAdmitOrderData = await GetAdmitOrderInForm(props, encounterId);

  // Get Allergy
  const getAllergy = await GetAllergyInForm(props);

  const data = {
    detail: {
      patientName: props.patientInfo?.full_name_en || props.patientInfo?.full_name_th || props.patientInfo?.full_name,
      hn: props.patientInfo?.hn,
      patient_birthdate: getFormatBirthDate(props.patientInfo?.birthdate, true),
      patient_age: preparedData.prepPatientAge[0],
      patient_gender_name: props.patientInfo?.gender_name || "-",
      number: props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      visit_date: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created, true, true) || "-",
      doctorName: preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
      ipd_room: getAdmitOrderData ? getAdmitOrderData?.room_no : "-",
      allergies: getAllergy?.formattedAllergyData,
      divisionName: getEncounterData ? getEncounterData?.division_name : "-",
      telMobile: props.patientInfo?.present_address?.tel_mobile || "-",
    }
  }

  return data;
};

const GetFormCudentMedCertData = async (props: any, encounterId: any) => {

  // Print Datetime for Cudent Med Cert
  const momentDate = moment();
  const date = momentDate.format("YYYY-MM-DD");
  const time = momentDate.format("HH:mm");

  // Get Med Cert Data
  const getMedCertData = await GetCudentMedCertInForm(props, props.encounterNumber, props.patientId);

  // Get Encounter Data
  let getEncounterData: any = {};
  let data: any = {};

  const defaultData = {
    code: "-",
    print_datetime: `${date} [${time}]`,
  };

  if (!getMedCertData) {
    getEncounterData = await getEncounter(props.controller, encounterId);
    data = setPDFMedCertificateData(getEncounterData, defaultData);
  } else {
    getEncounterData = await getEncounter(props.controller, getMedCertData?.data?.encounter);

    console.log("Print List: Med Cert from Enounter number: ", getEncounterData?.number); //* เช็ค encounter number ที่ med cert ดึงมาแสดง

    data = setPDFMedCertificateData(getEncounterData, {
      ...getMedCertData?.data?.data,
      code: getMedCertData?.code,
    });
  }

  return data;
};

const GetFormExpenseInvoiceIpdData = async (props: any, encounterId: any, emrId: any, preparedData: PreparedDataType) => {

  // Addition Summary Function
  const addSummaryPrice = (data: any) => {
    if (!data) {
      console.warn("Print List: IPD Medical Invoice Report Have No Data!");
      return null;
    }

    let totalSummaryPrice = 0;
    let totalSumNonClaimable = 0;
    let totalSumClaimable = 0;

    data.items = data.items.map((bill: any) => {
      const summaryPrice = bill.items.reduce((total: number, item: any) => total + item.sum_price, 0);
      const summaryClaimable = bill.items.reduce((total: number, item: any) => total + item.sum_claimable, 0);
      const sumNonClaimable = bill.items.reduce((total: number, item: any) => total + item.sum_non_claimable, 0);

      totalSummaryPrice += summaryPrice;
      totalSumClaimable += summaryClaimable;
      totalSumNonClaimable += sumNonClaimable;

      return { ...bill, summary_price: summaryPrice };
    });

    data.summary = {
      total_summary_price: totalSummaryPrice,
      total_sum_claimable: totalSumClaimable,
      total_sum_non_claimable: totalSumNonClaimable,
    };

    return data;
  };
  
  // Get Encounter Data
  const getEncounterData = await getEncounter(props.controller, encounterId);

  // Get Admit Order (IPD Case)
  const getAdmitOrderData = await GetAdmitOrderInForm(props, encounterId);

  // Get Bil Encounter Summary Data
  const bilEncounterSummaryData = await GetBilEncounterSummaryInForm(props, props.patientId, props.encounterNumber);

  // Get IPD Medical Bil Data
  const ipdMedicalBilData = await GetIpdMedicalBilInForm(props, encounterId);

  // Get Diagnosis Treatment Data
  const getDiagnosisTreatmentData = await GetDiagnosisTreatmentInForm(props, emrId);

  const nameAppear = (isCitizenType: string) => {
    if (isCitizenType === "T") {
      return props.patientInfo?.full_name_th || props.patientInfo?.full_name;
    }

    return props.patientInfo?.full_name_en || props.patientInfo?.full_name;
  };

  const data = {
    detail: {
      patientName: nameAppear(props.patientInfo?.citizen_type),
      hn: props.patientInfo?.hn,
      patient_birthdate: getFormatBirthDate(props.patientInfo?.birthdate, true),
      patient_age: preparedData.prepPatientAge[0],
      patient_gender_name: props.patientInfo?.gender_name || "-",
      number: getEncounterData?.full_number
        ? getEncounterData?.full_number
        : props.encounterNumber
        ? props.encounterNumber
        : props.patientInfo?.encounter_no
        ? props.patientInfo?.encounter_no
        : "-",
      visit_date: getFormatVisitDate(props.patientInfo?.admit_date, props.patientInfo?.encounter_created, true, true) || "-",
      doctorName: preparedData.prepDoctorName?.withPrefixEn || preparedData.prepDoctorName?.withPrefixTh || "-",
      ipd_room: getAdmitOrderData ? getAdmitOrderData?.room_no : "-",
      divisionName: getEncounterData ? getEncounterData?.division_name : "-",
      telMobile: props.patientInfo?.present_address?.tel_mobile || "-",
      roomType: getAdmitOrderData?.ward_type_name || "-",
      coverage: getEncounterData?.coverage || "-", //* coverage อ้างอิงจาก encounter
      startAdmit: getEncounterData?.created || "-",
      startAdmit_date: getEncounterData?.created?.split(" ") ? getEncounterData?.created?.split(" ")[0] : getAdmitOrderData?.admit_date__date || "-",
      dayDuration: getAdmitOrderData?.day_of_stay,
      hourDuration: getAdmitOrderData?.hour_of_stay,
      unpaid: bilEncounterSummaryData?.unpaid,
      ipdMedicalBil: addSummaryPrice(ipdMedicalBilData),
      diagnosisData: getDiagnosisTreatmentData,
      formatIcd10Code: getDiagnosisTreatmentData?.icd10_code,
      formatIcd10Term: getDiagnosisTreatmentData?.icd10_term,
    }
  }

  return data;
};

const GeneratePdfWithHeader = async (
  formGen: Function,
  pdfData: FormDataType,
  isEnableHeader: boolean,
  company: string,
  headerData: HeaderDataType,
) => {
  // console.log("Yeti Generate PDF Header: ", { formGen: formGen, isEnableHeader: isEnableHeader, company: company });
  let docPdfDef = await formGen({ ...pdfData?.data });

  if (isEnableHeader && formGen === FormNewPatientRegistration && company === "SAPIENS") {
    docPdfDef = await AddHeaderSapiensInfoTH(docPdfDef, {
      createdDate: headerData.createdFormDate,
    });
  } else if (isEnableHeader && pdfData?.language === "th" && company === "SAPIENS") {
    docPdfDef = await AddHeaderSapiensPrintListTH(docPdfDef, {
      createdDate: headerData.createdFormDate,
      formName: pdfData?.formName,
      patientInfo: pdfData?.data.items,
      footer: headerData?.footer,
    });
  } else if (isEnableHeader && pdfData?.language === "en" && company === "SAPIENS") {
    docPdfDef = await AddHeaderSapiensPrintListEN(docPdfDef, {
      createdDate: headerData.createdFormDate,
      formName: pdfData?.formName,
      patientInfo: pdfData?.data.items,
      footer: headerData?.footer,
    });
  } else if (isEnableHeader && pdfData?.language === "th" && company === "CU") {
    docPdfDef = await AddHeaderCudentPrintListTH(docPdfDef, {
      createdDate: headerData.createdFormDate,
      formName: pdfData?.formName,
      patientInfo: pdfData?.data.items,
      footer: headerData?.footer,
    });
  }
  // else if (isEnableHeader && pdfData?.language === "th" && company === "CU") {
  //   docPdfDef = await AddHeaderCudentPrintListEN(docPdfDef, {
  //     createdDate: headerData.createdFormDate,
  //     formName: pdfData?.formName,
  //     patientInfo: pdfData?.data.items,
  //     footer: headerData?.footer,
  //   });
  // }

  return docPdfDef;
};

/*=============================================
 *                    MAIN
 *=============================================*/

/* Note About Inject Data in PDF Form
- FormConsentPreSedationTH : Inject Table Detail Anesthetic ท้ายสุดของ Content
- FormConsentPreSedationEN : Inject Table Detail Anesthetic ท้ายสุดของ Content
- FormConsentTreatmentProcedure : Inject Table Finger Print หลัง Paragraph "ทั้งนี้ข้าพเจ้า เข้าใจ......เป็นหลักฐาน"
- FormNewPatientRegistration : มีการ Inject Checkbox, Square Box และ Fix Position เช่น เลขบัตรประชาชน, เลขไปรษณีย์ใน Square Box
*/

const CardPrintList = forwardRef((props: any, ref) => {
  const intl = useIntl();
  const [taskTracking, setTaskTracking] = useState(null);
  const [openModEncounterList, setOpenModEncounterList] = useState(false);
  const [encounterId, setEncounterId] = useState(props.encounterId);
  const [encounterList, setEncounterList] = useState([]); //* as default options for SearchBox
  const [documentType, setDocumentType] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<string | null>(null); //* pdf
  const [message, setMessage] = useState<any>({}); //* msg from vcMessenger
  const [patientInfo, setPatientInfo] = useState(props.patientInfo); // for Search Encounter
  const [emrId, setEmrId] = useState(props.patientData?.EMR?.emr_id); // for Search Encounter
  const [encounterNumber, setEncounterNumber] = useState(props.encounterNumber); // for Search Encounter
  const [doctorName, setDoctorName] = useState(props.patientInfo?.doctor_name); // for Search Encounter
  const [isSearchDocumentLoading, setIsSearchDocumentLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  //* show error and success
  const [openModInfo, setOpenModInfo] = useState(false);
  const [modInfoTitle, setModInfoTitle] = useState("");
  const [modInfoTitleColor, setModInfoTitleColor] = useState("");
  const [modInfoText, setModInfoText] = useState("");

  //* Mod Show task progress
  const [openModInfoTask, setOpenModInfoTask] = useState(false);
  const [modInfoTaskText, setModInfoTaskText] = useState("");

  //* Don't know what this is
  const [getPrinted, setGetPrinted] = useState(false);

  //* Cant find use case yet
  const [openModConfirm, setOpenModConfirm] = useState(false);
  const [modConfirmTitle, setModConfirmTitle] = useState("แจ้งเตือน!!!");
  const [reasonChoices, setReasonChoices] = useState([]);
  const [modConfirmError, setModConfirmError] = useState(null);

  const isMounted = useRef(false);
  const sbxEncounterRef = useRef();
  const reasonRef = useRef();

  useEffect(() => {
    isMounted.current = true;
    getDocumentType();
    vcMessenger.onMessage((msg: any) => {
      try {
        setMessage(msg);
      } catch (e) {}
    });
    getReason();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (message.event === "DPI_DOCTOR_ORDER_PRINT") {
      if (message.task_id === taskTracking) {
        handleTaskTrackingStatus({ data: message });
      }
    }
  }, [message]);

  useEffect(() => {
    if (!props.encounterId) {
      setEncounterId(props.patientInfo?.encounter_id);
    }
  }, [props.encounterId]);

  useEffect(() => {
    if (props.encounterNumber) {
      handleSearchEncounter({ searchText: props.encounterNumber });
    }
  }, [props.encounterNumber]);

  useEffect(() => {
    if (taskTracking) {
      var task = setInterval(() => {
        getTaskTracking();
      }, 15000);
    }
    return () => clearInterval(task);
  }, [taskTracking]);

  useEffect(() => {
    getRegDataList(props);
  }, [props.masterOptions]);

  const clear = () => {
    setFile(null);
    setSelectedRow(null);
    getDocumentType();
    setTaskTracking(null);
    setGetPrinted(false);
    // frmError.clear()
    // default Data
    setEncounterId(props.encounterId);
    setPatientInfo(props.patientInfo);
    setEmrId(null);
    setEncounterNumber(null);
    setDoctorName(null);
  };

  const getReason = async () => {
    const [data, error] = await props.controller.getClinicalTerm({
      type: "REASON_PATIENT_CARD",
    });
    if (isMounted.current) {
      if (data) {
        setReasonChoices(data);
      }
    }
  };

  const getPdfBase64 = async (url: string): Promise<string> => {
    try {
      const response = await axios.get(url, { responseType: "arraybuffer" });

      return Buffer.from(response.data, "binary").toString("base64");
    } catch (error) {
      console.error(`Failed to fetch PDF:`, error);

      return "";
    }
  };

  const getPdfBlob = async (url: string): Promise<Blob | null> => {
    try {
      const response = await axios.get(url, { responseType: "arraybuffer" });

      return new Blob([response.data], { type: response.headers["content-type"] });
    } catch (error) {
      console.error(`Failed to fetch PDF:`, error);

      return null;
    }
  };

  const handleTaskTrackingStatus = ({ data }: any = {}) => {
    // console.log("Print List Data: ", data);
    if (data) {
      if (data.status === TASK_TRACKING_STATUS.COMPLETED && !getPrinted) {
        setTaskTracking(null);
        getPrintedDocument();
        setGetPrinted(true);
        setOpenModInfoTask(false);
      } else if (data.status === TASK_TRACKING_STATUS.FAILED) {
        setTaskTracking({ taskId: data.task_id } as any);
        setModInfoTaskText(data.task_id);
        setModInfoTitle("ผิดพลาด");
        setModInfoTitleColor("red");
      } else if (data.status === TASK_TRACKING_STATUS.CANCELED) {
        setTaskTracking(null);
        setModInfoText(data.current_progress_description);
        setModInfoTitle("ยกเลิก");
        setModInfoTitleColor("red");
        setOpenModInfoTask(false);
        setOpenModInfo(true);
      } else if (data.status === TASK_TRACKING_STATUS.IN_PROGRESS) {
        setModInfoTaskText(
          (
            <div style={{ textAlign: "left", padding: 0 }}>
              <div>{intl.formatMessage({ id: "กรุณารอสักครู่" })}</div>
              <div>
                Status:{" "}
                {`${data.status} ${data.current_progress ? data.current_progress : data.progress}%`}
              </div>
              For IT: task_id = {data.task_id}
            </div>
          ) as any
        );
      } else if (!data.hasOwnProperty("status")) {
        setTaskTracking(null);
        handleGetDocumentResult({ data, preview: true });
      }
    }
  };

  const getPrintedDocument = ({ taskId }: any = {}) => {
    (reasonRef as any).current.clear();
    getTaskTracking({ taskId } as any);
  };

  const getTaskTracking = async ({ action, taskId }: any = {}) => {
    const [data, error] = await props.controller.getTaskTrackingResult({
      taskId: taskId ? taskId : taskTracking,
      action,
    });
    if (isMounted.current) {
      handleTaskTrackingStatus({ data });
    }
  };

  const getDocumentType = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getDocumentType({
      // name: intl.formatMessage({id: "ใบลงทะเบียนผู้ป่วยใหม่ (New Patient Registration Form)"}),
      printable: true,
      division: "current",
      offset: 0,
      limit: 40,
    });
    if (isMounted.current) {
      if (data) {
        setDocumentType(data.items);
      }
    }
    setIsLoading(false);
  };

  const handleSearchEncounter = async ({ searchText }: any = {}) => {
    const [data, error] = await props.controller.getEncounterSearch({
      // patientId: props.patientId, // #65599 show all encounter
      patientHn: props.patientInfo?.hn,
      search: searchText,
    });
    if (isMounted.current) {
      if (data) {
        setEncounterList(data.items);
      }
    }
    return [data, error];
  };

  // Search Document
  const handleSearchDocumentType = async ({ target }: any) => {
    const searchText = target.value;
    setSearchInput(searchText);
    const minCharacters = 3;

    if (searchText.length === 0) {
      getDocumentType();
      return;
    }
    if (searchText.length >= minCharacters) {
      debounceSearchDocument(searchText);
    }
  };

  // Debounce Search Document
  const debounceSearchDocument = _.debounce(async (inputValue: any) => {
    setIsSearchDocumentLoading(true);
    const [data, error] = await props.controller.getDocumentType({
      name: inputValue,
      printable: true,
      division: "current",
      offset: 0,
      limit: 40,
    });
    if (isMounted.current) {
      if (data) {
        setDocumentType(data.items);
      }
    }
    setIsSearchDocumentLoading(false);
  }, 500);

  const handleSelectOption = async ({ item }: any = {}) => {
    getEncounterSearchDetail({ encounterId: item.id });
  };

  const getEncounterSearchDetail = async ({ encounterId }: any = {}) => {
    const [data, error] = await props.controller.getEncounterSearchDetail({
      encounterId,
    });
    if (isMounted.current) {
      if (data) {
        setEncounterId(data.id);
        setEncounterNumber(data.number);
        setDoctorName(data.doctor_name);
      }

      // get Encounter Detail
      let encounterData = await getEncounter(props.controller, encounterId);

      // get Patient Detail
      if (encounterData) {
        setEmrId(encounterData.medical_record);

        let patientDetailData = await getPatientDetailView(props.controller, {
          patientId: encounterData.patient,
        });

        if (patientDetailData) {
          setPatientInfo(patientDetailData[0]);
        }
      }
    }
  };

  const handleSelectDocType = (originalRow: any) => {
    setSelectedRow(originalRow);
    // console.log("Print List Original Row: ", originalRow);
    if (originalRow?.print_api !== "/pdfmake") {
      getPrintAPI({ originalRow, preview: true } as any);
    } else {
      getPrintPDFMake({ originalRow, print: false } as any);
    }
  };

  const handlePrintAPIFail = ({ error, preview, confirm }: any = {}) => {
    if (preview) {
      clear();
    }
    setFile(null);
    if (confirm) {
      if (error) {
        setModConfirmTitle(error.message);
      }
      setOpenModConfirm(true);
    } else {
      setModInfoTitle("ผิดพลาด");
      setModInfoTitleColor("red");
      if (error) {
        if (error.message) {
          setModInfoText(error.message);
        } else {
          setModInfoText(error);
        }
      }
      setOpenModInfo(true);
    }
  };

  const getPrintAPI = async ({ originalRow, preview, confirm }: any = {}) => {
    setFile(null);
    if (!originalRow) {
      return;
    }
    setIsLoading(true);
    const [data, error, network] = await props.controller.getPrintAPI({
      printAPI: HOST + originalRow.print_api,
      patientId: props.patientId ? props.patientId : props.patientInfo?.id, // #65578 and #65580 patientId is null
      orderId: props.operatingOrderId,
      encounterId: encounterId ? encounterId : props.patientInfo?.encounter_id, // #67954 encounter id on reg menu
      jasperModule: originalRow.jasper_module,
      jasperPath: originalRow.jasper_path,
      pdf: preview,
      confirm,
      reason: reasonRef.current ? (reasonRef as any).current.getValue() : "",
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        handleGetDocumentResult({ data, preview });
      } else if (error && ![500, 503].includes(network.response.status)) {
        handlePrintAPIFail({ error, preview, confirm });
      }
    }
  };

  const getPrintPDFMake = async ({ originalRow, print }: any = {}) => {
    // console.log("Print List PrintPDFMake (Func): ", originalRow);
    setFile(null);
    if (!originalRow) {
      return;
    }
    setIsLoading(true);
    let docPdfDef: any = { content: [] };
    let pdfData: FormDataType = null;
    let staticData:any = null;
    let formGen: any = null;
    let haveFooter: string | null = null;

    // Print/Create Date (Global)
    const currentDate = moment().format("YYYY-MM-DD, HH:mm"); // 2024-02-29, 14:30
    const christYear = moment(currentDate).format("YYYY");
    const buddhistYear = (parseInt(christYear) + 543).toString();
    const formattedDate = moment(currentDate).format("DD/MM/YYYY".replace("YYYY", buddhistYear));
    const printedDateFormTh = moment(currentDate)
      .locale("th")
      .format("DD/MM/YYYY, HH:mm".replace("YYYY", buddhistYear)); // 29/02/2567, 14:30
    const printedDateFormEn = moment(currentDate).format("DD/MM/YYYY, HH:mm"); // 29/02/2024, 14:30
    const printedDateUsageTh = printedDateFormTh.split(", "); // ["29/02/2567", "14:30"]
    const printedDateUsageEn = printedDateFormEn.split(", "); // ["29/02/2024", "14:30"]

    // Patient Info Format for Forms
    const formattedPatientName = await getPatientNameInForm(props);
    const patientBirthdateFormEN = beStringToAdString(props.patientInfo?.birthdate);

    // Doctor Name Format for Forms
    const formattedDoctorName = await getDoctorNameInForm(props, encounterId);

    // formattedAddress
    const formattedAddress = getFormattedAddress(props);

    // getSliceDate
    let createdFormDate = getSliceDate(printedDateFormTh);
    let createdFormDateEn = getSliceDate(printedDateFormEn, "en");

    // patientInfo Variable
    let patientEmrId = props.patientData?.EMR?.emr_id;
    let patientDoctorName = props.patientInfo?.doctor_name;
    let patientEncounterNumber = props.patientInfo?.encounter_no;

    // Hospital Name
    let hospitalName: any = null;

    // Patient Allegy
    let patientAllergy: any = null;

    // Patient Age (FormattedDate - BirthDate)
    let patientAge = getAge(beToAd(props.patientInfo?.birthdate), beToAd(formattedDate));

    // Data Selected
    if (encounterId) {
      props.encounterId = encounterId;
      props.encounterNumber = encounterNumber;
      patientEncounterNumber = encounterNumber;
      patientEmrId = emrId;
    }
    if (patientInfo) {
      props.patientInfo = patientInfo;
      patientDoctorName = doctorName;
    }

    const preparedData = {
      prepCreatedDateTh: createdFormDate,
      prepCreatedDateEn: createdFormDateEn,
      prepSignedDateTh: printedDateUsageTh,
      prepSignedDateEn: printedDateUsageEn,
      prepPatientName: formattedPatientName,
      prepPatientAge: patientAge,
      prepPatientBirthDateEn: patientBirthdateFormEN,
      prepPatientAddress: formattedAddress,
      prepDoctorName: formattedDoctorName,
      haveHeader: isEnableHeader,
    };

    // Condition Form
    switch (originalRow?.code) {
      case "living_will":
        pdfData = GetFormLivingWillData(props, preparedData);
        formGen = FormLivingWill;

        break;
      case "cons_pers_health":
        pdfData = GetFormConsentPersonalHealthData(props, preparedData);
        formGen = FormConsentPersonalHealth;

        break;
      case "cons_drug_screen_th":
        pdfData = GetFormConsentDrugScreenData(props, preparedData, "th");
        formGen = FormConsentDrugScreenTH;

        break;
      case "cons_drug_screen_en":
        pdfData = GetFormConsentDrugScreenData(props, preparedData, "en");
        formGen = FormConsentDrugScreenEN;

        break;
      case "cons_narcotic_use_th":
        pdfData = GetFormConsentNarcoticUseData(props, preparedData, "th");
        formGen = FormConsentNarcoticUseTH;

        break;
      case "cons_narcotic_use_en":
        pdfData = GetFormConsentNarcoticUseData(props, preparedData, "en");
        formGen = FormConsentNarcoticUseEN;

        break;
      case "cons_hiv_test_th":
        pdfData = GetFormConsentHIVTestData(props, preparedData, "th");
        formGen = FormConsentHIVTestTH;

        break;
      case "cons_hiv_test_en":
        pdfData = GetFormConsentHIVTestData(props, preparedData, "en");
        formGen = FormConsentHIVTestEN;

        break;
      case "cons_blood_trans_th":
        pdfData = GetFormConsentBloodTransfusionData(props, preparedData, "th");
        formGen = FormConsentBloodTransfusionTH;

        break;
      case "cons_blood_trans_en":
        pdfData = GetFormConsentBloodTransfusionData(props, preparedData, "en");
        formGen = FormConsentBloodTransfusionEN;

        break;
      case "cons_pre_sedat_th":
        pdfData = await GetFormConsentPreSedationData(props, patientEmrId, preparedData, "th");
        formGen = FormConsentPreSedationTH;

        break;
      case "cons_pre_sedat_en":
        pdfData = await GetFormConsentPreSedationData(props, patientEmrId, preparedData, "en");
        formGen = FormConsentPreSedationEN;

        break;
      case "cons_operating_th":
        pdfData = await GetFormConsentOperatingData(props, patientEmrId, preparedData, "th");
        formGen = FormConsentOperatingTH;

        break;
      case "cons_treat_proc":
        pdfData = await GetFormConsentTreatmentProcedureData(props, patientEmrId, preparedData);
        formGen = FormConsentTreatmentProcedure;

        break;
      case "new_patient_register":
        pdfData = await GetFormNewPatientRegistrationData(props, preparedData);
        formGen = FormNewPatientRegistration;

        break;
      case "patient_sticker":
        staticData = {
          items: { ...props.patientInfo }
        };
        docPdfDef = FormPatientStickerSingle(staticData);

        break;
      case "patient_sticker_ipd":
        staticData = {
          items: { ...props.patientInfo },
        };

        docPdfDef = FormPatientStickerHnIpd(staticData);
        break;
      case "consent_ama_th":
        pdfData = GetFormConsentAMAData(props, preparedData, "th");
        formGen = FormConsentAMATH;

        break;
      case "consent_ama_en":
        pdfData = GetFormConsentAMAData(props, preparedData, "en");
        formGen = FormConsentAMAEN;

        break;
      case "dnr_th":
        pdfData = GetFormDNRData(props, preparedData, "th");
        formGen = FormDNRTH;
        haveFooter = "แบบฟอร์มนี้ใช้สำหรับ admission ต่อครั้งเท่านั้น";

        break;
      case "dnr_en":
        pdfData = GetFormDNRData(props, preparedData, "en");
        formGen = FormDNREN;
        haveFooter = `This form should be applied "Only One Time of Admission"`;

        break;
      case "living_will_th":
        pdfData = await GetFormLivingWillDataNewData(props, preparedData, "th");
        formGen = FormLivingWillTH;

        break;
      case "living_will_en":
        pdfData = await GetFormLivingWillDataNewData(props, preparedData, "en");
        formGen = FormLivingWillEN;

        break;
      case "price_evaluate":
        staticData = await GetFormPriceEvaluateDetailData(props, preparedData);
        docPdfDef = await FormPriceEvaluate(staticData);

        break;
      case "price_evaluate_th":
        staticData = GetFormPriceEvaluateData(props, preparedData, "th");
        docPdfDef = await FormPriceEvaluateTH(staticData);

        break;
      case "price_evaluate_en":
        staticData = GetFormPriceEvaluateData(props, preparedData, "en");
        docPdfDef = await FormPriceEvaluateEN(staticData);

        break;
      case "cons_operating_en":
        staticData = GetFormConsentOperatingStaticData(props, preparedData);
        docPdfDef = await FormConsentOperatingEN(staticData);

        break;
      case "price_estimate_th":
        staticData = await GetFormPriceEstimateData(props, patientEmrId, preparedData);
        docPdfDef = await FormPriceEstimateTH(staticData);

        break;
      case "claim_form":
        staticData = await GetFormClaimForInsurance(props, encounterId, preparedData);
        docPdfDef = await FormClaimForInsurance(staticData);

        break;
      case "cudent_header_form":
        pdfData = GetFormCudentHeaderFormData(props, preparedData);
        formGen = FormForTest;

        break;
      case "pain_management_fs":
        staticData = await GetPrintListLandscapeData(props, encounterId, preparedData);
        docPdfDef = await FormPainManagementFlowsheet(staticData);

        break;
      case "intravenous_care_fs":
        staticData = await GetPrintListLandscapeData(props, encounterId, preparedData);
        docPdfDef = await FormIntravenousCareFlowsheet(staticData);

        break;
      case "cudent_med_cert":
        staticData = await GetFormCudentMedCertData(props, encounterId);
        docPdfDef = await FormCudentMedCertPrintList(staticData);
        break;
      case "cons_spinal_th":
          staticData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.encounterNumber
                ? props.encounterNumber
                : props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : "-",
              printDay: createdFormDate[0],
              printMonth: createdFormDate[2],
              printYear: createdFormDate[3],
              printTime: createdFormDate[4],
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
              formatPatient: preparedData.prepPatientName.withoutPrefixTh,
              patientFullName: preparedData.prepPatientName.withPrefixTh,
              formatDoctor: preparedData.prepDoctorName.withoutPrefixTh,
              doctorFullName: preparedData.prepDoctorName.withPrefixTh,
              signedDate: printedDateUsageTh[0],
              formatGender:
                GENDER_LIST[props.patientInfo?.gender].name_th || props.patientInfo?.gender,
            },
          };

          docPdfDef = await FormConsentSpinalTH(staticData);
  
          break;
        case "cons_radiofrq_neu_th":
          staticData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.encounterNumber
                ? props.encounterNumber
                : props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : "-",
              printDay: createdFormDate[0],
              printMonth: createdFormDate[2],
              printYear: createdFormDate[3],
              printTime: createdFormDate[4],
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
              formatPatient: preparedData.prepPatientName.withoutPrefixTh,
              patientFullName: preparedData.prepPatientName.withPrefixTh,
              formatDoctor: preparedData.prepDoctorName.withoutPrefixTh,
              doctorFullName: preparedData.prepDoctorName.withPrefixTh,
              signedDate: printedDateUsageTh[0],
              formatGender:
                GENDER_LIST[props.patientInfo?.gender].name_th || props.patientInfo?.gender,
            },
          };
  
          docPdfDef = await FormConsentRadioNeuralTH(staticData);

          break;
        case "cons_cervi_joint_th":
          staticData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.encounterNumber
                ? props.encounterNumber
                : props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : "-",
              printDay: createdFormDate[0],
              printMonth: createdFormDate[2],
              printYear: createdFormDate[3],
              printTime: createdFormDate[4],
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
              formatPatient: preparedData.prepPatientName.withoutPrefixTh,
              patientFullName: preparedData.prepPatientName.withPrefixTh,
              formatDoctor: preparedData.prepDoctorName.withoutPrefixTh,
              doctorFullName: preparedData.prepDoctorName.withPrefixTh,
              signedDate: printedDateUsageTh[0],
              formatGender:
                GENDER_LIST[props.patientInfo?.gender].name_th || props.patientInfo?.gender,
            },
          };
  
          docPdfDef = await FormConsentCerviJointTH(staticData);

          break;
        case "cons_periphe_nerv_th":
          staticData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.encounterNumber
                ? props.encounterNumber
                : props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : "-",
              printDay: createdFormDate[0],
              printMonth: createdFormDate[2],
              printYear: createdFormDate[3],
              printTime: createdFormDate[4],
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
              formatPatient: preparedData.prepPatientName.withoutPrefixTh,
              patientFullName: preparedData.prepPatientName.withPrefixTh,
              formatDoctor: preparedData.prepDoctorName.withoutPrefixTh,
              doctorFullName: preparedData.prepDoctorName.withPrefixTh,
              signedDate: printedDateUsageTh[0],
              formatGender:
                GENDER_LIST[props.patientInfo?.gender].name_th || props.patientInfo?.gender,
            },
          };
  
          docPdfDef = await FormConsentPeripheNervTH(staticData);

          break;
        case "cons_esi_th":
          staticData = {
            items: {
              ...props.patientInfo,
              patientEncounter: props.encounterNumber
                ? props.encounterNumber
                : props.patientInfo?.encounter_no
                ? props.patientInfo?.encounter_no
                : "-",
              printDay: createdFormDate[0],
              printMonth: createdFormDate[2],
              printYear: createdFormDate[3],
              printTime: createdFormDate[4],
              ageYear: patientAge[0],
              ageMonth: patientAge[1],
              ageDay: patientAge[2],
              formatPatient: preparedData.prepPatientName.withoutPrefixTh,
              patientFullName: preparedData.prepPatientName.withPrefixTh,
              formatDoctor: preparedData.prepDoctorName.withoutPrefixTh,
              doctorFullName: preparedData.prepDoctorName.withPrefixTh,
              signedDate: printedDateUsageTh[0],
              formatGender:
                GENDER_LIST[props.patientInfo?.gender].name_th || props.patientInfo?.gender,
            },
          };
  
          docPdfDef = await FormConsentEpiduralSteroidInjectTH(staticData);

          break;
      case "summary_expense_ipd":
        staticData = await GetFormExpenseInvoiceIpdData(props, encounterId, patientEmrId, preparedData);

        const pdfMake = await getPdfMake(true);
        const firstPdf = await FormSummaryExpenseInvoiceIpd(staticData);
        const secondPdf = await FormListExpenseInvoiceIpd(staticData);

        const firstPdfAsUint8Array = await new Promise<Uint8Array>((resolve) => {
          pdfMake.createPdf(firstPdf).getBuffer((buffer) => resolve(new Uint8Array(buffer)));
        });

        const secondPdfAsUint8Array = await new Promise<Uint8Array>((resolve) => {
          pdfMake.createPdf(secondPdf).getBuffer((buffer) => resolve(new Uint8Array(buffer)));
        });

        if (firstPdfAsUint8Array && secondPdfAsUint8Array) {
          const mergedPdf = await mergePdfs([firstPdfAsUint8Array, secondPdfAsUint8Array]);

          docPdfDef = {
            uint8Array: mergedPdf.uint8Array,
            blobUrl: mergedPdf.blobUrl,
            blobRaw: mergedPdf.blobRaw,
            base64String: mergedPdf.base64String,
          };
        } else {
          console.error("Print List: Summary Expense Invoice IPD PDFs are missing!");
        }
        break;
      default: {
        const pathFile = originalRow.jasper_path || FALLBACK_PDF_PATHS[originalRow.code];

        if (pathFile) {
          docPdfDef = { pathFile };
        } else {
          clear();
        }
        break;
      }
    }

    if (formGen && pdfData) {
      docPdfDef = await GeneratePdfWithHeader(
        formGen,
        pdfData,
        // isEnableHeader,
        pdfData?.data?.items?.haveHeader,
        COMPANY,
        {
          createdFormDate: pdfData.language === "th" ? printedDateFormTh : printedDateFormEn,
          formName: pdfData.formName,
          patientInfo: pdfData.data.items,
          footer: haveFooter,
        },
      )
    }

    // console.log("Print List PDF Data: ", pdfData);

    if (isMounted.current) {
      setIsLoading(false);
      if (docPdfDef && print === false) {
        handleGetPdfMakeResult(docPdfDef);
      } else if (docPdfDef && docPdfDef.blobRaw) {
        const blob = docPdfDef.blobRaw;

        if (blob) {
          printURL(URL.createObjectURL(blob));
          setOpenModInfoTask(false);
          setModInfoText("พิมพ์รายการสำเร็จ");
          setModInfoTitle("สำเร็จ");
          setModInfoTitleColor("teal");
          setOpenModInfo(true);
          clear();
        }
      } else if (docPdfDef && print === true) {
        // (await getPdfMake(true)).createPdf(docPdfDef).print();
        const pdfMake = await getPdfMake(true);
        const pdf = pdfMake.createPdf(docPdfDef);
        const pdfBlobFromPdf = new Promise<Blob>((resolve) => {
          pdf.getBlob((blob: any) => {
            resolve(blob);
          });
        });

        const blob: Blob | null = await (docPdfDef.pathFile
          ? getPdfBlob(docPdfDef.pathFile)
          : pdfBlobFromPdf);

        if (blob) {
          printURL(URL.createObjectURL(blob));
          setOpenModInfoTask(false);
          setModInfoText("พิมพ์รายการสำเร็จ");
          setModInfoTitle("สำเร็จ");
          setModInfoTitleColor("teal");
          setOpenModInfo(true);
          clear();
        }
      } else {
        setFile("about:blank" as any);
        console.error("PDF Data doesn't Exist");
      }
    }
  };

  const handleGetPdfMakeResult = async (data: any) => {
    if (data) {
      setOpenModInfo(false);

      let pdfB64Data = "";

      if (data.pathFile) {
        pdfB64Data = await getPdfBase64(data.pathFile);
      } else if (data.base64String) {
        pdfB64Data = data.base64String;
      } else {
        const pdfDocGen = (await getPdfMake(true)).createPdf(data);
        pdfB64Data = await new Promise<string>((resolve, reject) => {
          pdfDocGen.getBase64((data) => resolve(data));
        });
      }
      if (pdfB64Data) {
        // console.log("Yeti Base64: ", pdfB64Data);
        setFile(`data:application/pdf;base64,${pdfB64Data}`);
      }
    } else {
      console.error("Cannot Open PDF File!!");
    }
  };

  const handleGetDocumentResult = ({ data, preview }: any = {}) => {
    if (preview && data.success && data.pdf_b64data.length > 0) {
      setOpenModInfoTask(false);
      return setFile(("data:application/pdf;base64," + data.pdf_b64data) as any);
    } else if (!preview && data.success) {
      setOpenModInfoTask(false);
      setModInfoText("พิมพ์รายการสำเร็จ");
      setModInfoTitle("สำเร็จ");
      setModInfoTitleColor("teal");
      setOpenModInfo(true);
      clear();
    } else if (data.warning) {
      setFile("about:blank" as any);
      alert(data.warning);
    } else if (data.task_id) {
      setModInfoTaskText(
        (
          <div style={{ textAlign: "left", padding: 0 }}>
            <div>{intl.formatMessage({ id: "กรุณารอสักครู่" })}</div>For IT: task_id = {data.task_id}
          </div>
        ) as any
      );
      setOpenModInfoTask(true);
      setGetPrinted(false);
      setTaskTracking(data.task_id);
    } else {
      setOpenModInfoTask(false);
      handlePrintAPIFail({ error: data.error, preview });
    }
  };

  const handleCancelTask = () => {
    getTaskTracking({ action: "CANCEL" });
  };

  const handleApproveConfirm = () => {
    setModConfirmError(null);
    if (!(reasonRef as any).current.getValue()) {
      return setModConfirmError("กรุณาระบุเหตุผล" as any);
    }
    setOpenModConfirm(false);
    getPrintAPI({ originalRow: selectedRow, preview: false, confirm: true });
  };

  // Print
  const handlePrintReport = (originalRow: any) => {
    if (originalRow?.print_api !== "/pdfmake") {
      getPrintAPI({ originalRow: selectedRow, preview: false, confirm: false });
    } else {
      getPrintPDFMake({ originalRow: selectedRow, print: true });
    }
  };

  // Print List Data
  // console.log("Print List Props: ", props);

  return (
    <CardLayout
      titleText="พิมพ์เอกสาร"
      closeable={props.closeable}
      toggleable={props.toggleable}
      onClose={props.onClose}
      loading={isLoading}
    >
      <ModConfirm
        openModal={openModConfirm}
        className={"fixNotMiddleModal"}
        onDeny={() => setOpenModConfirm(false)}
        titleName={modConfirmTitle}
        onApprove={handleApproveConfirm}
        content={
          <Form>
            <ErrorMessage error={modConfirmError} />
            <Form.Group>
              <Form.Field width={16}>
                <label>
                  ระบุเหตุผล
                  <label style={{ color: "red" }}>*</label>
                </label>
                <ComboBox ref={reasonRef as any} options={reasonChoices} />
              </Form.Field>
            </Form.Group>
          </Form>
        }
      />
      <ModInfo
        className="fixNotMiddleModal"
        open={openModInfoTask}
        alertText={modInfoTaskText}
        titleName="กำลังเตรียมเอกสาร"
        btnText="ยกเลิก"
        titleColor="teal"
        buttonColor="red"
        textAlign={"left"}
        onApprove={handleCancelTask}
      />
      <ModInfo
        className="fixNotMiddleModal"
        open={openModInfo}
        titleColor={modInfoTitleColor}
        titleName={modInfoTitle}
        alertText={
          typeof modInfoText !== "string" ? <ErrorMessage error={modInfoText} /> : modInfoText
        }
        closeOnDimmerClick={true}
        onClose={() => setOpenModInfo(false)}
        onApprove={() => setOpenModInfo(false)}
      />
      <Modal
        open={openModEncounterList}
        closeOnDimmerClick={true}
        onClose={() => setOpenModEncounterList(false)}
        content={
          <CardEncounterList
            patientId={props.patientId}
            patientHn={props.patientInfo?.hn}
            controller={props.controller}
            onSelect={(selectedRow: any) => {
              (sbxEncounterRef as any).current.setText(selectedRow.number_date);
              (sbxEncounterRef as any).current.setId(selectedRow.id);
              handleSelectOption({ item: selectedRow });
              setOpenModEncounterList(false);
            }}
          />
        }
      />
      <PatientIdentification patientInfo={patientInfo} />
      <Form>
        <Form.Group inline>
          <Form.Field>
            <label>Encounter Number :</label>
          </Form.Field>
          <Form.Field>
            <SearchBox
              ref={sbxEncounterRef}
              testid="sbxEncounter"
              linkIcon
              onIconClick={() => setOpenModEncounterList(true)}
              minCharacters={2}
              textField="number_date"
              onGetSearchOptions={handleSearchEncounter}
              onSelectOption={handleSelectOption}
              onClear={() => setEncounterId(props.encounterId)}
              defaultOptions={encounterList}
              defaultId={props.encounterId ? props.encounterId : props.patientInfo?.encounter_id}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <br />
      <Grid>
        <Grid.Column width={4}>
          <Input
            fluid
            icon="search"
            placeholder="ค้นหาชื่อเอกสาร"
            onChange={handleSearchDocumentType}
            loading={isSearchDocumentLoading}
          />
          <br />
          <PureReactTable
            style={styles.docTable}
            showPagination={false}
            testid="grdRequest"
            data={documentType}
            onSelect={handleSelectDocType}
            selectedRow={selectedRow}
            selectedClassName="blueSelectedRow"
            columns={[
              {
                Header: "ชื่อเอกสาร",
                accessor: "name",
              },
            ]}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          <Segment style={{ height: "500px" }}>
            <FrameChrome file={file} visible />
          </Segment>
        </Grid.Column>
      </Grid>
      <br />
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field width={3}>
            <Button
              color="blue"
              content="พิมพ์"
              fluid
              // onClick={() => {
              //   getPrintAPI({
              //     originalRow: selectedRow,
              //     preview: false,
              //     confirm: false,
              //   });
              // }}
              onClick={() => {
                handlePrintReport(selectedRow);
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </CardLayout>
  );
});

const TASK_TRACKING_STATUS = {
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  CANCELED: "CANCELED",
  IN_PROGRESS: "IN_PROGRESS",
};

const styles = {
  docTable: {
    height: "500px",
  },
};

CardPrintList.propTypes = {
  toggleable: PropTypes.bool,
  closeable: PropTypes.bool,
  patientInfo: PropTypes.object,
  onClose: PropTypes.func,
  patientData: PropTypes.object,
  controller: PropTypes.object,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  encounterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  encounterNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  operatingOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  printer: PropTypes.string,
};

CardPrintList.defaultProps = {
  toggleable: false,
  closeable: true,
  patientInfo: {},
  onClose: () => {},
  patientData: {},
  controller: {},
  patientId: null,
  encounterId: null,
  encounterNumber: null,
  operatingOrderId: null,
  printer: null,
};

CardPrintList.displayName = "CardPrintList";
export default React.memo(CardPrintList);
